import styled from 'styled-components';
// @ts-ignore
import loadergif from './Loading.gif';

function ChickenSpinner() {
  return (
    <StyledChickenSpinner alt="Loading" draggable={false} src={loadergif} />
  );
}

const StyledChickenSpinner = styled.img`
  width: 100px;
`;

export default ChickenSpinner;
