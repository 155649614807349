import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { DocumentContainer } from '../common';
import { StyledContentWrapper } from '../wrappers';
import { useHeightForIframe } from '@/util/document-utils';
import { useAppDispatch } from '@/hooks';
import { incrementHistoryStackCount } from '@/store/header/slice';

interface IframeViewProps {
  /** Iframe url. */
  url?: string;
  isCompliance?: boolean;
}

/** Simple view for documents that are rendered with iframes. */
const IframeView: React.FC<IframeViewProps> = ({
  url,
  isCompliance = false,
}) => {
  const height = useHeightForIframe(isCompliance);
  const [iframeLoads, setIframeLoads] = useState(0);
  const dispatch = useAppDispatch();

  const onIframeLoad = useCallback(() => {
    const updatedIframeLoads = iframeLoads + 1;
    setIframeLoads(updatedIframeLoads);
    if (updatedIframeLoads > 1) {
      dispatch(incrementHistoryStackCount());
    }
  }, [dispatch, iframeLoads]);

  return (
    <DocumentContainer $isPrinting={false} $isTridion={false} fluid>
      <StyledContentWrapper $isPrinting={false}>
        <StyledIFrame
          $calculatedHeight={height}
          allow="fullscreen"
          className="content-iframe"
          onLoad={onIframeLoad}
          src={url}
        />
      </StyledContentWrapper>
    </DocumentContainer>
  );
};

const StyledIFrame = styled.iframe<{ $calculatedHeight: string }>`
  border: 0;
  width: 100%;
  height: ${({ $calculatedHeight }) => $calculatedHeight};
`;

export default IframeView;
