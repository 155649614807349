import styled from 'styled-components';
import { Checkbox, Typography } from 'cfa-react-components';

interface CheckboxFilterSectionProps {
  labels: { [x: string]: { translationString: string; value: boolean } };
  onChange: (value: string) => void;
  title: string;
}

const CheckboxFilterSection: React.FC<CheckboxFilterSectionProps> = ({
  labels,
  onChange,
  title,
}) => {
  const handleOnChange = (label: string) => {
    onChange(label);
  };

  return (
    <div data-testid={'CheckboxFilterSection'}>
      <CheckboxSectionTitle fontWeight="bold" variant="body1">
        {title}
      </CheckboxSectionTitle>
      <CheckboxContainer>
        {Object.keys(labels).map(label => (
          // @ts-ignore
          <Checkbox
            checked={labels && labels[label] && labels?.[label]?.value}
            crossOrigin={undefined}
            key={label}
            label={
              labels && labels[label] && labels?.[label]?.translationString
            }
            onChange={() => handleOnChange(label)}
          />
        ))}
      </CheckboxContainer>
    </div>
  );
};

const CheckboxSectionTitle = styled(Typography)`
  margin-bottom: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin-left: 4px;
`;

export default CheckboxFilterSection;
