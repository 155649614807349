import { LoadingIndicator } from 'cfa-react-components';
import styled from 'styled-components';

interface LoadingOverlayProps {
  className?: '';
  isOpen: boolean;
  children?: React.ReactNode;
}

interface OverlayProps {
  $isOpen: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  className,
  isOpen = false,
  children,
}) => {
  return (
    <StyledOverlay
      $isOpen={isOpen}
      className={className}
      data-testid="LoadingOverlay"
    >
      <LoadingIndicator size="lg" variant="page" />
      {children}
    </StyledOverlay>
  );
};

const StyledOverlay = styled.div<OverlayProps>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  overflow: auto;
  background-color: ${props => props.theme.primaryPalette.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
`;

export default LoadingOverlay;
