import { useEffect, useState } from 'react';

export const useBrightcoveVideo = (contentUrl?: string) => {
  const [url, setUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (contentUrl?.includes('players.brightcove.net')) {
      const urlFromResponse = extractUrlFromResponse(contentUrl);
      setUrl(urlFromResponse);
      setIsLoading(false);
      return;
    }

    (async () => {
      if (!contentUrl) {
        return;
      }

      try {
        const response = await fetch(contentUrl, {
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error(
            `Brightcove video: Unable to fetch video detail from ${contentUrl}`,
          );
        }

        const videoDetail = await response.text();
        const urlFromResponse = extractUrlFromResponse(videoDetail);
        setUrl(urlFromResponse);
        setIsLoading(false);
      } catch (_) {
        setError(true);
      }
    })();
  }, [contentUrl]);

  return { url, isLoading, error };
};

const extractUrlFromResponse = (videoDetail: string) => {
  const urlMatch = videoDetail.match(/(https?:\/\/[^\s]+)/);
  const urlFromResponse = urlMatch ? urlMatch[0] : undefined;
  if (!urlFromResponse) {
    throw new Error(
      'Brightcove video: Unable to extract video url from response.',
    );
  }

  const url = new URL(urlFromResponse);

  return url.toString();
};
