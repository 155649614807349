import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { mapCategoryToColor } from 'util/categoryUtils';
import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { IconEdit, IconTrash, IconWorld } from '@tabler/icons-react';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import { useGetQuizQuery } from 'services/pathwayApi';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { QuizDefault } from 'icons';
import PropTypes from 'prop-types';
import {
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
} from 'store/user/selectors';
import { useSelector } from 'react-redux';
import ManageTranslationsPopup from 'containers/TrainingPlans/ManageTranslationsModal/ManageTranslations';
import RenameQuizPopup from '../BuildQuizzesItems/RenameQuizPopup';
import DeleteQuizPopup from '../BuildQuizzesItems/DeleteQuizPopup';

const QuizCard = ({
  quizDetails,
  onDeleteQuiz,
  onRenameQuiz,
  onSaveTranslations,
  setQuiz,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));
  const [showDeleteQuizPopup, setShowDeleteQuizPopup] = useState(false);
  const [showRenameQuizPopup, setShowRenameQuizPopup] = useState(false);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const locationsWithOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const locationsWithLeaderPermission = useSelector(
    selectLocationsWithLeaderPermission,
  );

  const { data: quizData } = useGetQuizQuery(quizDetails.id, {
    skip: !showRenameQuizPopup,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (quizData) {
      setQuiz(quizData);
    }
  }, [quizData, setQuiz]);

  const quizName = getNameFromLanguage(quizDetails?.name);

  const onShowRenameQuizPopup = () => {
    setShowRenameQuizPopup(true);
  };

  const onDeleteQuizAndClosePopup = () => {
    onDeleteQuiz(quizDetails.id, quizName);
    setShowDeleteQuizPopup(false);
  };

  const onRenameQuizAndClosePopup = inputValue => {
    onRenameQuiz(inputValue);
    setShowRenameQuizPopup(false);
  };

  const onShowDeleteQuizPopup = () => {
    setShowDeleteQuizPopup(true);
  };

  const onShowManageTranslationsPopup = () => {
    setShowManageTranslationsPopup(true);
  };

  const goToEditQuizPage = () => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/${quizDetails?.id}`,
    });
  };

  return (
    <>
      <StyledCard elevation={2} title={quizName}>
        <QuizCardImageWrapper $backgroundColor={mapCategoryToColor('default')}>
          <QuizDefault />
        </QuizCardImageWrapper>
        <QuizCardContent
          $isSmAndUp={isSmAndUp}
          data-testid="MyQuiz"
          onClick={goToEditQuizPage}
        >
          <StyledContentWrapper>
            <StyledQuizCardLeft>
              <StyledQuizName data-testid="QuizName">{quizName}</StyledQuizName>
            </StyledQuizCardLeft>
            <StyledQuizCardRight>
              {(locationsWithOperatorPermission.length ||
                locationsWithLeaderPermission.length) && (
                <PopoverMenuButton dataTestId="QuizItemsThreeDotMenu">
                  <PopoverMenuButtonItem
                    dataTestId="RenameQuiz"
                    icon={<IconEdit />}
                    onClick={onShowRenameQuizPopup}
                    text={t('TrainingPlans.manageThreeDotMenu.renameQuiz')}
                  />
                  <PopoverMenuButtonItem
                    dataTestId="ThreeDotMenuManageTranslationsPopup"
                    icon={<IconWorld />}
                    onClick={onShowManageTranslationsPopup}
                    text={t('Generic.editTranslations')}
                  />
                  <PopoverMenuButtonItem
                    dataTestId="ThreeDotMenuDeleteQuizPopup"
                    icon={<IconTrash data-testid="DeleteQuiz" />}
                    isDestructive={true}
                    onClick={onShowDeleteQuizPopup}
                    text={t('TrainingPlans.manageThreeDotMenu.deleteQuiz')}
                  />
                </PopoverMenuButton>
              )}
            </StyledQuizCardRight>
          </StyledContentWrapper>
        </QuizCardContent>
      </StyledCard>
      <DeleteQuizPopup
        onDeleteQuizAndClosePopup={onDeleteQuizAndClosePopup}
        quizName={quizName}
        setShowDeleteQuizPopup={setShowDeleteQuizPopup}
        showDeleteQuizPopup={showDeleteQuizPopup}
      />
      <RenameQuizPopup
        onRenameQuizAndClosePopup={onRenameQuizAndClosePopup}
        quizDetails={quizDetails}
        setShowRenameQuizPopup={setShowRenameQuizPopup}
        showRenameQuizPopup={showRenameQuizPopup}
      />
      <ManageTranslationsPopup
        isOpen={showManageTranslationsPopup}
        onClose={() => setShowManageTranslationsPopup(false)}
        onSave={updatedTranslations => {
          setShowManageTranslationsPopup(false);
          onSaveTranslations(updatedTranslations);
        }}
        translatableObject={quizDetails}
      />
    </>
  );
};

const StyledCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
`;

const QuizCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: 1.5rem 1rem;
`;

const QuizCardContent = styled(CardContent)`
  display: flex;
  flex-direction: ${({ $isSmAndUp }) => ($isSmAndUp ? 'row' : 'column')};
  align-items: ${({ $isSmAndUp }) => ($isSmAndUp ? 'center' : 'flex-start')};
  justify-content: center;
  width: 100%;
  padding: 16px !important;
  overflow: hidden;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledQuizCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
  margin: 0 15px;
  overflow-wrap: break-word;
`;

const StyledQuizCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 15px;
`;

const StyledQuizName = styled(Typography)`
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

QuizCard.propTypes = {
  quizDetails: PropTypes.object.isRequired,
  onDeleteQuiz: PropTypes.func.isRequired,
  onRenameQuiz: PropTypes.func.isRequired,
  onSaveTranslations: PropTypes.func.isRequired,
  setQuiz: PropTypes.func.isRequired,
};

export default QuizCard;
