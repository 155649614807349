import Constants from 'constants/index';
import { generateTotalTimeFromSteps } from 'util/time';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { messageReactNative } from 'util/messageReactNative';
// @ts-ignore
import { generateReport, printToNative } from 'util/generateReport';
import { convertDateForReports } from 'util/date';
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Surface,
  TextFieldType,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/managePlansFilter/selector';
import {
  useGetReportsPlansAssignableQuery,
  useGetAssignedStatusQuery,
  useGetCourseReportQuery as useGetCourseReportQueryOld,
  useGetReportsTeamMembersQuery,
  useGetOperatorsQuery as useGetOperatorsQueryOld,
} from 'services/pathwayApi';
import {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
  addManagePlansLocationFilter,
  resetManagePlans,
} from 'store/managePlansFilter/slice';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithOperatorPermission,
  isUserLicensee,
  isOperatorConsultant,
  selectUserLicenseeLocations,
  selectUserIsLicenseeStaff,
} from 'store/user/selectors';
// @ts-ignore
import uniqBy from 'lodash/uniqBy';
// @ts-ignore
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';
import {
  useGetOperatorsQuery,
  useGetCourseReportQuery,
  useGetReportsTrainingPlanStatusesQuery,
  useGetStatusesAssignedToChecklistQuery,
} from 'services/xpApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CourseReportDTO,
  OperatorDTO,
  UserChecklistResultDTO,
  UserChecklistStatusResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';
import ReportsPlanCard from '@/components/PlanCard/PlanCards/ReportsPlanCard';
import PrintReportModal from '@/sharedComponents/app/popups/PrintReportModal';
import CheckboxList from '@/sharedComponents/app/CheckboxList';
import { withRoles } from '@/sharedComponents/app/withRoles';
import LoadingOverlay from '@/sharedComponents/app/LoadingOverlay';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  setTogglePlansLicensee,
  setTogglePlansMyRestaurants,
} from '@/store/licensee/slice';
import { getNameFromLanguage } from '@/util/language';
import StickyArea from '@/components/StickyFilterCard/StickyArea';
import CheckboxFilterSection from '@/components/StickyFilterCard/CheckboxFilterSection';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import { mapCategoryToAliasTranslation } from '@/util/categoryUtils';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import { setHeader } from '@/store/header/slice';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import { getCompletionDate } from '@/util/reports';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { ComplianceCourse, LanguageObject } from '@/types';
import { useSortOptions } from '@/hooks/useSortOptions';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import ReportsCompliancePlanCard from '@/components/PlanCard/PlanCards/ReportsCompliancePlanCard';

const ReportsPlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { xpApi: xpApiFeatureFlag, licensee: licenseeFeatureFlag } = useFlags();
  const currentFilters: string[] = useSelector(selectFilters);
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const isLicenseeUser = useSelector(isUserLicensee);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const sort = useSelector(selectSort);
  const licenseeLocations = isLicenseePlans
    ? userLicenseeLocations?.map(location => location.number) ?? []
    : undefined;

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastLeaderPermission,
  );

  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const { showing, total } = useSelector(selectPagination) as any;
  const isLeaderOrOperator =
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;
  const [triggerTeamMembersReportRefetch, setTriggerTeamMembersReportRefetch] =
    useState(false);
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [planDetails, setPlanDetails] = useState({
    category: '',
    id: '',
    isCompliance: false,
    locations: [] as string[],
    name: '',
  });
  const [statusReport, setStatusReport] = useState<CourseReportDTO>({
    courseId: '',
    courseName: '',
    foodSafetyLetters: [],
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const [teamMembersReport, setTeamMembersReport] =
    useState<UserChecklistStatusResponse>({});

  const categoryLabels = useCategoryLabels(currentFilters);

  if (isLicenseeUser || isLicenseePlans || userIsLicenseeStaff) {
    delete categoryLabels?.[Constants.PLAN_CATEGORIES.COMPLIANCE];
  }

  const sortOptions = useSortOptions();

  const getPlanLocations = () => {
    if (userIsLicenseeStaff || isLicenseeUser) {
      return activeLicenseeLocation?.number
        ? [activeLicenseeLocation?.number]
        : [];
    }
    if (isLicenseePlans) {
      return licenseeLocations as string[];
    }
    return locationsWithAtLeastLeaderPermission;
  };

  const planLocations = getPlanLocations();

  const {
    data: oldUnOrderedPlans,
    isFetching: isFetchingPlansOld,
    isSuccess: isSuccessPlansOld,
  } = useGetReportsPlansAssignableQuery(
    {
      locations: planLocations,
    },
    {
      skip:
        !!xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpUnOrderedPlans,
    isFetching: isFetchingPlansXp,
    isSuccess: isSuccessPlansXp,
  } = useGetReportsPlansAssignableQuery(
    //TODO proxy does not exist yet, replace with proxy EP when available P-606
    {
      locations: planLocations,
    },
    {
      skip:
        !xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const isSuccess: boolean = xpApiFeatureFlag
    ? isSuccessPlansXp
    : isSuccessPlansOld;

  const isFetching: boolean = xpApiFeatureFlag
    ? isFetchingPlansXp
    : isFetchingPlansOld;

  const unOrderedPlans = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  const { data: oldOperators } = useGetOperatorsQueryOld(undefined, {
    skip: !!xpApiFeatureFlag,
  });

  const { data: xpOperators } = useGetOperatorsQuery(undefined, {
    skip: !xpApiFeatureFlag,
  });

  const operators: OperatorDTO[] = xpApiFeatureFlag
    ? xpOperators
    : (oldOperators as any);

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeaderPermission.includes(operatorLocation),
    ),
  );

  const {
    data: oldStatusReportData,
    isFetching: oldStatusReportIsFetching,
    isSuccess: oldIsStatusReportSuccess,
  } = useGetCourseReportQueryOld(
    {
      courseId: planDetails.id,
      location: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpApiStatusReportData,
    isFetching: xpApiStatusReportIsFetching,
    isSuccess: xpApiIsStatusReportSuccess,
  } = useGetCourseReportQuery(
    {
      courseId: planDetails.id,
      locations: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const statusReportData: CourseReportDTO = xpApiFeatureFlag
    ? xpApiStatusReportData
    : (oldStatusReportData as any);
  const statusReportIsFetching: boolean = xpApiFeatureFlag
    ? xpApiStatusReportIsFetching
    : oldStatusReportIsFetching;
  const isStatusReportSuccess: boolean = xpApiFeatureFlag
    ? xpApiIsStatusReportSuccess
    : oldIsStatusReportSuccess;

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    isSuccess: oldIsReportsTeamMembersDataSuccess,
    refetch: oldRefetchReportsTeamMembers,
  } = useGetReportsTeamMembersQuery(
    {
      locations: planLocations,
    },
    {
      skip: !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    isSuccess: xpReportsTeamMembersStatusesIsSuccess,
    refetch: refetchReportsTeamMembersStatuses,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !xpApiFeatureFlag,
    },
  );

  const reportsTeamMembersData: UserChecklistStatusResponse = xpApiFeatureFlag
    ? xpReportsTeamMemberStatuses
    : (oldReportsTeamMembersData as any);
  const reportsTeamMembersIsFetching = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsFetching
    : oldReportsTeamMembersIsFetching;
  const isReportsTeamMembersDataSuccess = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsSuccess
    : oldIsReportsTeamMembersDataSuccess;

  const {
    data: oldAssignedTeamMembersList,
    isFetching: oldAssignedTeamMembersListIsFetching,
    isSuccess: oldIsAssignedTeamMembersListSuccess,
  } = useGetAssignedStatusQuery(
    {
      checklist: planDetails.id,
      location: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !!xpApiFeatureFlag,
    },
  );

  const {
    data: xpAssignedTeamMembersList,
    isFetching: xpAssignedTeamMembersListIsFetching,
    isSuccess: xpIsAssignedTeamMembersListSuccess,
  } = useGetStatusesAssignedToChecklistQuery(
    {
      checklistId: planDetails.id,
      locations: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !xpApiFeatureFlag,
    },
  );

  const assignedTeamMembersList: UserChecklistResultDTO = xpApiFeatureFlag
    ? xpAssignedTeamMembersList
    : (oldAssignedTeamMembersList as any);
  const assignedTeamMembersListIsFetching: boolean = xpApiFeatureFlag
    ? xpAssignedTeamMembersListIsFetching
    : oldAssignedTeamMembersListIsFetching;
  const isAssignedTeamMembersListSuccess: boolean = xpApiFeatureFlag
    ? xpIsAssignedTeamMembersListSuccess
    : oldIsAssignedTeamMembersListSuccess;

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Plans
  useEffect(() => {
    if (isSuccess) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(
            plan?.checklist?.name as LanguageObject,
          ).toLowerCase(),
        };
      });
      if (isLicenseeUser || isLicenseePlans) {
        dispatch(setManagePlans({ plans: storePlansWithName ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans:
              storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
          }),
        );
      }
    }
  }, [dispatch, isLicenseePlans, isLicenseeUser, isSuccess, unOrderedPlans]);

  useEffect(() => {
    if (isStatusReportSuccess && !statusReportIsFetching) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData, statusReportIsFetching]);

  useEffect(() => {
    if (isReportsTeamMembersDataSuccess) {
      setTeamMembersReport(reportsTeamMembersData);
    }
  }, [isReportsTeamMembersDataSuccess, reportsTeamMembersData]);

  useEffect(() => {
    if (triggerTeamMembersReportRefetch) {
      !!xpApiFeatureFlag
        ? refetchReportsTeamMembersStatuses()
        : oldRefetchReportsTeamMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStatusReportSuccess,
    xpApiFeatureFlag,
    reportsTeamMembersData,
    triggerTeamMembersReportRefetch,
  ]);

  const toggleMyRestaurants = () => {
    dispatch(setTogglePlansMyRestaurants());
    dispatch(resetManagePlans());
  };

  const toggleLicensee = () => {
    dispatch(setTogglePlansLicensee());
    dispatch(resetManagePlans());
  };

  const onPrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => {
    setPlanDetails({
      category,
      id,
      isCompliance: isCompliancePlan,
      locations,
      name: planName,
    });
    if (isCompliancePlan) {
      if (
        planName.includes(Constants.FOOD_SAFETY_COURSE_NAME) ||
        locations?.length > 1
      ) {
        setShowChooseReportLocationsPopup(true);
      } else {
        // need to wait for setPlanDetails to populate the state :(
        setTriggerComplianceReportHandler(true);
      }
    } else if (locations?.length > 1) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // We don't have the data to print yet so we need to refetch first
      setTriggerTeamMembersReportRefetch(true);
    }
  };

  const getComplianceReportToPrint = useCallback(() => {
    return [...statusReport?.userData!]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.firstName!.localeCompare(teamMemberB?.firstName!);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE
            .NAME]: `${teamMember.firstName} ${teamMember.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: teamMember.birthDate
            ? teamMember.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            getCompletionDate(teamMember),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            teamMember?.certificate?.expiration &&
            teamMember?.courseStatus !==
              Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(teamMember.certificate.expiration)
              : t('Generic.na'),
        };
      });
  }, [statusReport?.userData, t]);

  const reportData = uniqBy(
    teamMembersReport?.users
      ?.map(teamMember => {
        return {
          ...teamMember.user,
          plansAssigned: teamMember.plansAssigned,
          plansCompleted: teamMember.plansCompleted,
        };
      })
      ?.filter(teamMember1 => {
        return assignedTeamMembersList
          ?.status!.filter(
            teamMember =>
              teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
          )
          .some(teamMember2 => teamMember1.adId === teamMember2.userId);
      })
      .map(user => {
        return {
          ...user,
          completionDate: assignedTeamMembersList?.status?.find(
            teamMember => teamMember?.userId === user?.adId,
          )?.completionDate,
          status: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.status,
          steps: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.steps,
        };
      }),
    'adId',
  );

  const getStoreReportToPrint = useCallback(() => {
    return uniqBy(
      reportData.filter((teamMember: any) =>
        planDetails?.locations?.length > 1 && teamMember?.locations?.length
          ? teamMember?.locations.some((location: string) =>
              selectedReportLocations.includes(location),
            )
          : teamMember,
      ),
      'adId',
    )
      .sort((teamMemberA: any, teamMemberB: any) => {
        return (teamMemberA.name ?? '').localeCompare(teamMemberB.name ?? '');
      })
      .map((teamMember: any) => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.adId,
            )?.stepsComplete || 0
          }/${assignedTeamMembersList?.checklist?.stepsTotal}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps!,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              Constants.TRAINING_PLANS_STATUSES[
                teamMember.status as keyof typeof Constants.TRAINING_PLANS_STATUSES
              ]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
          id: assignedTeamMembersList?.checklist?.id,
        };
      });
  }, [
    assignedTeamMembersList?.checklist?.id,
    assignedTeamMembersList?.checklist?.stepsTotal,
    assignedTeamMembersList?.status,
    planDetails?.locations?.length,
    reportData,
    selectedReportLocations,
    t,
  ]);

  //eslint-disable-next-line
  const tableHead = planDetails.isCompliance
    ? [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETION_DATE,
          Constants.REPORT_TABLE.EXPIRATION_DATE,
        ],
      ]
    : [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.ITEMS_COMPLETED,
          Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
          Constants.REPORT_TABLE.STATUS,
          Constants.REPORT_TABLE.COMPLETION_DATE,
        ],
      ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  const getReportToGenerate = useCallback(() => {
    return {
      category: t(mapCategoryToAliasTranslation(planDetails?.category ?? '')),
      foodSafetyImage:
        statusReport?.foodSafetyLetters?.[0]?.foodSafetyLetter?.content,
      id: planDetails?.id,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length && planDetails?.locations
          ? planDetails?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: planDetails?.name,
      reportToPrint: planDetails.isCompliance
        ? getComplianceReportToPrint().map(teamMember =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          )
        : getStoreReportToPrint().map((teamMember: any) =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          ),
      tableHead,
    };
  }, [
    getComplianceReportToPrint,
    getStoreReportToPrint,
    planDetails,
    selectedReportLocations,
    statusReport?.foodSafetyLetters,
    t,
    tableHead,
  ]);

  type Report = ReturnType<typeof getReportToGenerate>;

  const getMessageToSend = useCallback(
    (report: Report) => {
      return {
        category: report.category,
        location: report.location,
        name: report.name,
        reportToPrint: planDetails.isCompliance
          ? getComplianceReportToPrint()
          : getStoreReportToPrint(),
        tableHead,
      };
    },
    [
      getComplianceReportToPrint,
      getStoreReportToPrint,
      planDetails.isCompliance,
      tableHead,
    ],
  );

  /** If a report does not have a location screen, we need to wait until the data is
   * returned from the query.  Once we have the data from the backend, we can then
   * show the printable report.
   */
  useEffect(() => {
    if (
      triggerTeamMembersReportRefetch &&
      isReportsTeamMembersDataSuccess &&
      isAssignedTeamMembersListSuccess &&
      !assignedTeamMembersListIsFetching &&
      !reportsTeamMembersIsFetching
    ) {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      setTriggerTeamMembersReportRefetch(false);
      resetPlanState();
    }
  }, [
    assignedTeamMembersListIsFetching,
    getMessageToSend,
    getReportToGenerate,
    isAssignedTeamMembersListSuccess,
    isReportsTeamMembersDataSuccess,
    reportsTeamMembersIsFetching,
    triggerTeamMembersReportRefetch,
  ]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastLeaderPermission);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastLeaderPermission, noLocationsSelected]);

  const resetPlanState = () => {
    setPlanDetails({
      category: '',
      id: '',
      isCompliance: false,
      locations: [],
      name: '',
    });
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyLetters: [],
      locations: [''],
      timeStamp: '',
      userData: [],
    });
  };

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastLeaderPermission?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      handleCurrentFilteredLocations();
      resetPlanState();
    }
  }, [
    getMessageToSend,
    getReportToGenerate,
    handleCurrentFilteredLocations,
    locationsWithAtLeastLeaderPermission?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!planDetails.id &&
      !!statusReport.courseId &&
      !!triggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !statusReportIsFetching
    ) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    planDetails.id,
    statusReport.courseId,
    statusReportIsFetching,
    triggerComplianceReportHandler,
  ]);

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  return (
    <>
      <StyledContent>
        {!!isSmAndDown && isOperatorConsultantUser && licenseeFeatureFlag && (
          <ToggleButtonGroup
            fullWidth
            orientation="horizontal"
            size="sm"
            value={isLicenseePlans}
          >
            <ToggleButton
              aria-label="my-restaurants"
              onClick={toggleMyRestaurants}
              value={false}
            >
              <Typography
                fontWeight={isLicenseePlans ? 'regular' : 'bold'}
                variant="body1"
              >
                {t('Licensee.myRestaurants')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              aria-label="licensees"
              onClick={toggleLicensee}
              value={true}
            >
              <Typography
                fontWeight={isLicenseePlans ? 'bold' : 'regular'}
                variant="body1"
              >
                {t('Licensee.licensees')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!!userIsLicenseeStaff && !!isSmAndDown && licenseeFeatureFlag && (
          <MobileChangeLocationButton
            color="secondary"
            onClick={() => {
              dispatch(setShowLicenseeLocationPicker());
            }}
            size="lg"
            variant="outlined"
          >
            {activeLicenseeLocation?.number
              ? t('Licensee.changeLicenseeLocation', {
                  locationNumber: activeLicenseeLocation?.number,
                })
              : t('Generic.chooseLicenseeLocation')}
          </MobileChangeLocationButton>
        )}
        <SearchFilterHeader
          hideTitle={
            (isOperatorConsultantUser || userIsLicenseeStaff) &&
            licenseeFeatureFlag &&
            !!isSmAndDown
          }
          onChange={(e: React.ChangeEvent<TextFieldType>) =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchPlans')}
          searchValue={searchFilter}
          title={t('Reports.tabPlans')}
        />
        {!!isSmAndDown && !isFetching && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setManagePlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={(value: string) => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlansList>
          {!isSmAndDown && !isFetching && (
            <StickyArea>
              {!!isOperatorConsultantUser && licenseeFeatureFlag && (
                <ToggleButtonGroup
                  fullWidth
                  orientation="horizontal"
                  size="sm"
                  value={isLicenseePlans}
                >
                  <ToggleButton
                    aria-label="my-restaurants"
                    onClick={toggleMyRestaurants}
                    value={false}
                  >
                    <Typography
                      fontWeight={isLicenseePlans ? 'regular' : 'bold'}
                      variant="body1"
                    >
                      {t('Licensee.myRestaurants')}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    aria-label="licensees"
                    onClick={toggleLicensee}
                    value={true}
                  >
                    <Typography
                      fontWeight={isLicenseePlans ? 'bold' : 'regular'}
                      variant="body1"
                    >
                      {t('Licensee.licensees')}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}

              <FilterCardSurface elevation={1} radii="sm" variant="outlined">
                {isOperatorConsultantUser &&
                  isLicenseePlans &&
                  licenseeFeatureFlag && (
                    <>
                      <CheckboxFilterSection
                        labels={userLicenseeLocations.reduce(
                          (acc, location) => {
                            // eslint-disable-next-line no-param-reassign
                            acc = {
                              ...acc,
                              [location?.number]: {
                                translationString: location?.name,
                                value: !!currentFilters.includes(
                                  location?.number,
                                ),
                              },
                            };
                            return acc;
                          },
                          {},
                        )}
                        onChange={(value: string) => {
                          if (!!currentFilters.includes(value)) {
                            dispatch(
                              removeManagePlansFilter({ filter: value }),
                            );
                          } else {
                            dispatch(
                              addManagePlansLocationFilter({ filter: value }),
                            );
                          }
                        }}
                        title={t('Licensee.licenseeLocations')}
                      />
                      <FilterDivider variant="fullLength" />
                    </>
                  )}
                {!!userIsLicenseeStaff &&
                  !isSmAndDown &&
                  licenseeFeatureFlag && (
                    <>
                      <StaffChooseLocationContainer>
                        <Typography fontWeight="bold" variant="body1">
                          {t('Licensee.licenseeLocation')}
                        </Typography>
                        {activeLicenseeLocation?.name && (
                          <Typography variant="body1">
                            {`${activeLicenseeLocation.number} ${activeLicenseeLocation.name}`}
                          </Typography>
                        )}
                        <ChooseLocationButton
                          color="secondary"
                          onClick={() => {
                            dispatch(setShowLicenseeLocationPicker());
                          }}
                          size="sm"
                          variant="outlined"
                        >
                          {activeLicenseeLocation?.name
                            ? t('Generic.change')
                            : t('Generic.choose')}
                        </ChooseLocationButton>
                      </StaffChooseLocationContainer>
                      <FilterDivider variant="fullLength" />
                    </>
                  )}
                <CheckboxFilterSection
                  labels={categoryLabels}
                  onChange={(value: string) => {
                    if (!!currentFilters.includes(value)) {
                      dispatch(removeManagePlansFilter({ filter: value }));
                    } else {
                      dispatch(addManagePlansCategoryFilter({ filter: value }));
                    }
                  }}
                  title={t('Browse.categories')}
                />
              </FilterCardSurface>
            </StickyArea>
          )}
          <PlansContainer>
            <LoadingOverlay isOpen={isFetching} />
            <PlanCardsList>
              {!isFetching && (
                <>
                  <SortFilterHeader
                    label={t('TrainingPlans.filtering.sortBy')}
                    onChange={option => {
                      dispatch(setManagePlansSort({ sort: option?.value }));
                    }}
                    onClear={() => {
                      dispatch(clearManagePlansCheckboxFilters());
                    }}
                    options={sortOptions}
                    showClear={false}
                    showCompletedPlansOption={false}
                    showMyCompletedPlansOnlySwitchValue={false}
                    text={`${total ?? 0} ${t('Reports.tabPlans')}`}
                    value={sortOptions?.find(option => option.value === sort)}
                  />
                  <ReportsCardContainer>
                    {filteredAndSortedPlans?.map(plan =>
                      plan?.checklist ? (
                        <ReportsPlanCard
                          assignedUsers={plan?.assignedUsers}
                          completedUsers={plan?.completedUsers}
                          key={plan?.checklist?.id}
                          locations={
                            // intersects locations where user is a leader with the plans locations
                            arrayIntersect(
                              operatorsWhereUserIsLeader?.find(
                                operator =>
                                  operator.id === plan?.checklist?.ownerId,
                              )?.locations ?? [],
                              locationsWithAtLeastLeaderPermission,
                            )
                          }
                          onPrintReport={({
                            category,
                            id,
                            locations,
                          }: {
                            category: string;
                            id: string;
                            locations: string[];
                          }) =>
                            onPrintReport({
                              category,
                              id,
                              isCompliancePlan: false,
                              locations,
                              planName: getNameFromLanguage(
                                plan?.checklist?.name,
                              ),
                            })
                          }
                          plan={plan?.checklist}
                        />
                      ) : (
                        <ReportsCompliancePlanCard
                          course={
                            {
                              courseID: plan?.courseID,
                              courseName: plan?.courseName,
                              createdDate: plan?.createdDate,
                              enabled: plan?.enabled,
                              id: plan?.id,
                            } as ComplianceCourse
                          }
                          enrollments={plan?.enrollments}
                          key={plan.courseID}
                          locations={locationsWithAtLeastLeaderPermission}
                          onPrintReport={({
                            category,
                            id,
                            locations,
                          }: {
                            category: string;
                            id: string;
                            locations: string[];
                          }) =>
                            onPrintReport({
                              category,
                              id,
                              isCompliancePlan: true,
                              locations,
                              planName: getNameFromLanguage(plan?.name!),
                            })
                          }
                        />
                      ),
                    )}
                  </ReportsCardContainer>
                  <LoadMorePaginator
                    onClick={() => dispatch(loadMorePlans())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYPlans', {
                      showing,
                      total: total ?? 0,
                    })}
                    total={total ?? 0}
                  />
                </>
              )}
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                userIsLicenseeStaff &&
                !activeLicenseeLocation?.number && (
                  <NoMessage
                    message={
                      <Trans i18nKey={'Licensee.chooseALicenseeLocation'} />
                    }
                  />
                )}
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                !currentFilters?.length &&
                !searchFilter &&
                !!activeLicenseeLocation?.number && (
                  <NoMessage message={<Trans i18nKey={'Reports.noPlans'} />} />
                )}
              {(!!currentFilters?.length || !!searchFilter) &&
                !total &&
                !!activeLicenseeLocation?.number && (
                  <NoMessage message={t('TrainingPlans.noPlansResults')} />
                )}
            </PlanCardsList>
          </PlansContainer>
        </PlansList>
      </StyledContent>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          !planDetails?.isCompliance
            ? planDetails?.locations?.map((location, idx) => (
                <StyledCheckboxList
                  id={location}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : // Compliance plans
            locationsWithAtLeastLeaderPermission.length > 1
            ? locationsWithAtLeastLeaderPermission.map(
                (id: string, idx: number) => (
                  <StyledCheckboxList
                    id={id}
                    idx={idx}
                    key={idx}
                    selectedLocations={selectedReportLocations}
                    setSelectedLocations={setSelectedReportLocations}
                  />
                ),
              )
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={planDetails?.name?.includes(
          Constants.FOOD_SAFETY_COURSE_NAME,
        )}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const MobileChangeLocationButton = styled(Button)`
  margin-top: 16px;
`;

const StaffChooseLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChooseLocationButton = styled(Button)`
  width: fit-content;
  margin-bottom: 0;
`;

const FilterDivider = styled(Divider)`
  margin: 16px 0;
`;

const FilterCardSurface = styled(Surface)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  overflow-y: auto;
`;

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default withRoles(
  ReportsPlansTab,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
