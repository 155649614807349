import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { FC } from 'react';
import { IframeView } from '../common';

interface XylemeProps {
  /** Xyleme document. */
  document: Document;
}

/** Xyleme document. */
const Xyleme: FC<XylemeProps> = ({ document }) => (
  <IframeView url={document.contentApiUrl} />
);

export default Xyleme;
