import {
  mapCategoryToAliasTranslation,
  mapCategoryToColor,
  mapCategoryToIcon,
} from 'util/categoryUtils';
import { getNameFromLanguage } from 'util/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { LanguageObject } from '@/types';

interface TemplateCardProps {
  plan: ChecklistDTO;
  onCopy: () => void;
  onPreview: (id: string) => void;
}

interface ProgressCardImageWrapperStyleProps {
  $backgroundColor: string;
}

interface ProgressCardContentStyleProps {
  $isSmAndUp: boolean;
}

interface StyledPlanCardRightStyleProps {
  $isSmAndUp: boolean;
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  plan,
  onPreview,
  onCopy,
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));

  return (
    <TemplatePlanCard data-testid="TemplatePlanCard" elevation={1}>
      <ProgressCardImageWrapper
        $backgroundColor={mapCategoryToColor(
          plan?.category,
          plan?.disabled === true,
        )}
      >
        {mapCategoryToIcon(plan?.category)}
      </ProgressCardImageWrapper>
      <ProgressCardContent $isSmAndUp={isSmAndUp}>
        <StyledPlanCardLeft>
          <Typography variant="overline3">
            {t(mapCategoryToAliasTranslation(plan?.category))}
          </Typography>
          <StyledPlanName
            data-testid="PlanName"
            fontWeight="bold"
            variant="body1"
          >
            {getNameFromLanguage(plan.name as LanguageObject)}
          </StyledPlanName>
          <Typography data-testid="PlanTaskCount" variant="body2">
            {`${plan?.stepsTotal} ${t('Generic.items')}`}
          </Typography>
        </StyledPlanCardLeft>
        <StyledPlanCardRight $isSmAndUp={isSmAndUp}>
          <StyledTemplateButton
            color="secondary"
            data-testid="templatePreviewButtonTest"
            onClick={() => onPreview(plan.id as string)}
            variant="outlined"
          >
            {t('Button.preview')}
          </StyledTemplateButton>
          <StyledTemplateButton
            color="secondary"
            data-testid="templateCopyButtonTest"
            onClick={onCopy}
            variant="filled"
          >
            {t('Button.copy')}
          </StyledTemplateButton>
        </StyledPlanCardRight>
      </ProgressCardContent>
    </TemplatePlanCard>
  );
};

const TemplatePlanCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
  cursor: 'default !important';
`;

const ProgressCardImageWrapper = styled.div<ProgressCardImageWrapperStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: auto;
  background: ${({ $backgroundColor }) => $backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
`;

const ProgressCardContent = styled(CardContent)<ProgressCardContentStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
  flex-direction: ${({ $isSmAndUp }) => ($isSmAndUp ? 'row' : 'column')};
  align-items: ${({ $isSmAndUp }) => ($isSmAndUp ? 'center' : 'flex-start')};
  gap: 8px;
`;

const StyledPlanCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: 'flex-start';
  flex-grow: 1;
  overflow: hidden;
`;

const StyledPlanCardRight = styled.div<StyledPlanCardRightStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;

const StyledPlanName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledTemplateButton = styled(Button)`
  min-width: unset;
`;
