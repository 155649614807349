import Constants from 'constants/index';
import styled from 'styled-components';
import {
  Card,
  ProgressBar,
  Tooltip,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { Link } from 'react-router-dom';
import PlanCardCategoryIcon from './PlanCardContent/PlanCardCategoryIcon';

interface PlanCardProps {
  category?: string;
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  link?: string;
  progress?: number | null;
  buttons?: React.ReactNode;
  tooltip?: string;
}

interface CardContainerProps {
  $isDisabled?: boolean;
}

interface PlanCardBodyProps {
  $isSmAndDown: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  category = Constants.PLAN_CATEGORIES.DEFAULT,
  children,
  className,
  dataTestId = 'PlanCard',
  disabled,
  link,
  progress = 0,
  buttons,
  tooltip,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  /** Most plan cards require a link but compliance plans inside
   * of Reporting should not link you outside of that page so we
   * render those cards without a link.
   */
  return link && !disabled ? (
    <Link data-testid="PlanCardLink" to={link}>
      <CardContainer
        $isDisabled={disabled}
        className={className}
        data-testid={dataTestId}
        elevation={1}
      >
        {!!category && (
          <PlanCardCategoryIcon category={category} disabled={!!disabled} />
        )}
        <PlanCardBodyContainer>
          <PlanCardBodyWrapper>
            <PlanCardBody $isSmAndDown={isSmAndDown}>{children}</PlanCardBody>
            {buttons}
          </PlanCardBodyWrapper>
          {progress !== null && progress !== 100 && (
            // @ts-ignore overflow error but we only use these props
            <ProgressBar
              color="success"
              compact
              placeholder={undefined}
              value={progress}
            />
          )}
        </PlanCardBodyContainer>
      </CardContainer>
    </Link>
  ) : /** This is a specific case when in Manage Plans (and viewing a 
    disabled compliance plan).  We don't want them to link to another 
    page in a disabled state but we also don't want the tooltip attached
   */
  disabled ? (
    <CardContainer
      $isDisabled={disabled}
      className={className}
      data-testid={dataTestId}
      elevation={1}
    >
      {!!category && (
        <PlanCardCategoryIcon category={category} disabled={disabled} />
      )}
      <PlanCardBodyContainer>
        <PlanCardBodyWrapper>
          <PlanCardBody $isSmAndDown={isSmAndDown}>{children}</PlanCardBody>
          {buttons}
        </PlanCardBodyWrapper>
        {progress !== null && progress !== 100 && (
          // @ts-ignore overflow error but we only use these props
          <ProgressBar
            color="success"
            compact
            placeholder={undefined}
            value={progress}
          />
        )}
      </PlanCardBodyContainer>
    </CardContainer>
  ) : (
    <div>
      <Tooltip
        content={tooltip}
        placement="bottom"
        showOnElementDelay={{
          showDelay: (!isSmAndDown && 500) as number,
        }}
      >
        <CardContainer
          $isDisabled={disabled}
          className={className}
          data-testid={dataTestId}
          elevation={1}
        >
          {!!category && (
            <PlanCardCategoryIcon category={category} disabled={false} />
          )}
          <PlanCardBodyContainer>
            <PlanCardBodyWrapper>
              <PlanCardBody $isSmAndDown={isSmAndDown}>{children}</PlanCardBody>
              {buttons}
            </PlanCardBodyWrapper>
            {progress !== null && progress !== 100 && (
              // @ts-ignore overflow error but we only use these props
              <ProgressBar
                color="success"
                compact
                placeholder={undefined}
                value={progress}
              />
            )}
          </PlanCardBodyContainer>
        </CardContainer>
      </Tooltip>
    </div>
  );
};

const CardContainer = styled(Card)<CardContainerProps>`
  animation: fadeIn linear 0.3s;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  flex: 1 0;
  cursor: ${({ $isDisabled }) => $isDisabled && 'default !important'};
`;

const PlanCardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 16px;
  gap: 8px;
`;

const PlanCardBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const PlanCardBody = styled.div<PlanCardBodyProps>`
  display: flex;
  flex-direction: ${({ $isSmAndDown }) => ($isSmAndDown ? 'column' : 'row')};
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  width: 100%;
`;

export default PlanCard;
