import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import GenericError from '@/sharedComponents/app/GenericError';

interface GenericErrorBoundaryProps {
  children: React.ReactNode;
}

const GenericErrorBoundary = ({ children }: GenericErrorBoundaryProps) => {
  return (
    <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
  );
};

export default GenericErrorBoundary;
