import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectSubcategoryReducerState = (state: RootState) =>
  state.subcategory;

export const subcategoryId = createSelector(
  selectSubcategoryReducerState,
  subcategoryReducerState => subcategoryReducerState.id,
);
