import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  isUserLicensee,
  selectUserIsLicenseeStaff,
  selectUserLicenseeLocations,
} from 'store/user/selectors';
import {
  selectPreferredLicenseeLocation,
  selectActiveLicenseeLocation,
  selectShowLicenseeLocationPicker,
} from 'store/licenseeLocationPicker/selector';
import {
  setActiveLicenseeLocation,
  setHideLicenseeLocationPicker,
  setShowLicenseeLocationPicker,
} from 'store/licenseeLocationPicker/slice';
import {
  clearLicenseeLocationsSearchFilter,
  loadMoreLicenseeLocations,
  setLicenseeLocations,
  setLicenseeLocationsSearchFilter,
} from 'store/licenseeLocationsFilter/slice';
import {
  selectSearchFilter,
  selectPagination,
  selectSortedAndFilteredAndPaginatedLicenseeLocations,
} from 'store/licenseeLocationsFilter/selector';
import { useEffect, useRef } from 'react';
import Searchbar from 'components/Searchbar/Searchbar';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import toast from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import { useNativeLocationPickerListener } from 'hooks/useNativeListener';
import { LocationCard } from './LocationCard';

const LicenseeLocationPicker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isLicenseeUser = useSelector(isUserLicensee);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const preferredLicenseeLocation = useSelector(
    selectPreferredLicenseeLocation,
  );
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const showLocationPicker = useSelector(selectShowLicenseeLocationPicker);
  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const licenseeLocations = useSelector(
    selectSortedAndFilteredAndPaginatedLicenseeLocations,
  );
  const { showing, total } = useSelector(selectPagination);

  // listening for Native message when location changed in native
  useNativeLocationPickerListener();

  // overriding old default location numbers set to 0, should be able to remove this in a month or two.
  useEffect(() => {
    if (activeLicenseeLocation.number === 0) {
      dispatch(setActiveLicenseeLocation({ number: null }));
    }
  }, [activeLicenseeLocation.number, dispatch]);

  // sets initial Licensee Location
  useEffect(() => {
    if (
      // sets Licensee Location if one is set in Preferences
      !!userLicenseeLocations?.length &&
      !!preferredLicenseeLocation &&
      activeLicenseeLocation.number === null &&
      !!userLicenseeLocations?.find(
        loc => loc.number === preferredLicenseeLocation,
      )?.number
    ) {
      const matchedLocation = userLicenseeLocations.find(
        loc => loc.number === preferredLicenseeLocation,
      );
      dispatch(setActiveLicenseeLocation(matchedLocation));
    } else if (
      // sets Licensee Location or shows picker if one has not been set from preferences
      isLicenseeUser &&
      !!userLicenseeLocations?.length &&
      activeLicenseeLocation &&
      activeLicenseeLocation.number === null
    ) {
      if (userLicenseeLocations?.length < 2) {
        dispatch(setActiveLicenseeLocation(userLicenseeLocations[0]));
      } else {
        dispatch(setShowLicenseeLocationPicker());
      }
    }
  }, [
    activeLicenseeLocation,
    dispatch,
    isLicenseeUser,
    preferredLicenseeLocation,
    userLicenseeLocations,
  ]);

  useEffect(() => {
    dispatch(setLicenseeLocations(userLicenseeLocations));
  }, [dispatch, userLicenseeLocations]);

  useEffect(() => {
    if (activeLicenseeLocation.number && userLicenseeLocations?.length > 1) {
      toast.custom(toastObj => (
        <ToastMessageBlock
          id={toastObj.id}
          title={`${t('TrainingPlans.toastMessage.nowViewingLocation1', {
            name: activeLicenseeLocation.name,
            number: activeLicenseeLocation.number,
          })}`}
        >
          {t('TrainingPlans.toastMessage.nowViewingLocation2')}
        </ToastMessageBlock>
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLicenseeLocation]);

  const onClearSearchClick = () => {
    dispatch(clearLicenseeLocationsSearchFilter());
    searchInputRef.current?.focus();
  };

  const showCloseBtn = userIsLicenseeStaff || activeLicenseeLocation?.number;

  return (
    <StyledModal
      disableOverlayClick={!showCloseBtn}
      onClose={() => dispatch(setHideLicenseeLocationPicker())}
      scrollMode="modal-body"
      show={showLocationPicker}
      size="lg"
    >
      <ModalHeader showCloseButton={!!showCloseBtn} variant={'h2'}>
        {t('Generic.chooseLicenseeLocation')}
      </ModalHeader>
      <ModalBody>
        <StyledSearchbar
          elevation={1}
          fullWidth
          onChange={e =>
            dispatch(
              setLicenseeLocationsSearchFilter({
                searchFilter: e.target.value,
              }),
            )
          }
          onClear={onClearSearchClick}
          placeholder={t('Generic.searchLocations')}
          ref={searchInputRef}
          searchValue={searchFilter}
          showRecentSearchDropdown
        />
        {licenseeLocations?.map((location, i) => (
          <LocationCard key={`${location.number}-${i}`} location={location} />
        ))}
        <LoadMorePaginator
          onClick={() => dispatch(loadMoreLicenseeLocations())}
          showing={showing}
          showingText={t('TrainingPlans.showingXOfYLocations', {
            showing,
            total: total ?? 0,
          })}
          total={total ?? 0}
        />
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

const StyledSearchbar = styled(Searchbar)`
  margin-bottom: 2rem;
`;

export default LicenseeLocationPicker;
