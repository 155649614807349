import styled from 'styled-components';
import { IconGripVertical } from '@tabler/icons-react';

interface SixDotIconProps {
  position: string;
}

interface SixDotIconWrapperStyleProps {
  $position: string;
}

const SixDotIcon: React.FC<SixDotIconProps> = ({ position = 'center' }) => {
  return (
    <SixDotIconWrapper $position={position} data-testid="Draggable">
      <StyledSixDotIcon />
    </SixDotIconWrapper>
  );
};

const SixDotIconWrapper = styled.div<SixDotIconWrapperStyleProps>`
  align-self: ${({ $position }) =>
    $position === 'top' ? 'flex-start' : 'center'};
  cursor: pointer;
  // margin-right: 0.5em;
`;
const StyledSixDotIcon = styled(IconGripVertical)`
  margin-left: -4px;
  color: ${({ theme }) => theme.grayScale.gray3};
`;

SixDotIcon.defaultProps = {
  position: 'center',
};
export default SixDotIcon;
