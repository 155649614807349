import { Document as ApiDocument } from '@cfacorp-pathway/xp-api-typescript-client';
import { FC, useMemo } from 'react';
import { Brightcove, Game, Tridion, Xyleme } from '@/components/document';
import { useAppSelector } from '@/hooks';
import useDocumentCookieRefresh from '@/hooks/useDocumentCookieRefresh';
import {
  selectDocumentFromDashboardOrResource,
  selectPersistDocumentState,
} from '@/store/document-persisted/selectors';

/** View for all document types. */
const Document: FC = () => {
  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();
  const { icon, routedFrom } = useAppSelector(selectPersistDocumentState);
  const document = useAppSelector(selectDocumentFromDashboardOrResource);

  const documentComp: React.ReactNode = useMemo(() => {
    switch (document?.sourceSystem) {
      case ApiDocument.sourceSystem.XYLEME:
        return <Xyleme document={document} />;
      case ApiDocument.sourceSystem.TRIDION:
        return (
          <Tridion document={document} icon={icon} routedFrom={routedFrom} />
        );
      case ApiDocument.sourceSystem.BRIGHTCOVE:
        return <Brightcove document={document} />;
      case ApiDocument.sourceSystem.GAME:
        return <Game document={document} />;
      default:
        return null;
    }
  }, [document, icon, routedFrom]);

  if (!isDocumentCookieSuccess) {
    return null;
  }

  return documentComp;
};

export default Document;
