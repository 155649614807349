import styled from 'styled-components';
import {
  IconButton,
  PopoverMenu,
  PopoverMenuToggleButton,
  Surface,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { IconPlus } from '@tabler/icons-react';
import React, { useRef } from 'react';
// @ts-ignore
import { useClickOutside } from 'hooks/useClickOutside';
import theme from 'styles/theme';

interface FabProps {
  children?: React.ReactNode;
  hasMenu: boolean;
  isOpen: boolean;
  onClick: () => void;
  onClose?: () => void;
  tooltip?: string;
}

interface AnimatedIconPlusProps {
  $hasMenu: boolean;
  $isOpen: boolean;
}

export const Fab: React.FC<FabProps> = ({
  onClick,
  onClose = () => {},
  isOpen,
  children,
  hasMenu,
  tooltip,
}) => {
  const ref = useRef();
  useClickOutside(ref, () => {
    onClose();
  });

  const ContentFragment = () => {
    return (
      <>
        <Typography fontWeight="bold" variant="body2">
          {tooltip}
        </Typography>
      </>
    );
  };

  return (
    <PopoverMenu>
      <StyledAddButtonWrapper delevation={2} ref={ref}>
        {!!tooltip ? (
          <Tooltip
            content={<ContentFragment />}
            placement="left"
            showOnElementEvents={['hover']}
            style={{
              boxShadow: '0px 4px 12px 0px rgba(91, 103, 112, 0.24)',
              color: `${theme.grayScale.gray7}`,
            }}
          >
            <PopoverMenuToggleButton
              aria-label="Add Button"
              as={IconButton}
              color="tertiary"
              data-testid="AddFAB"
              onClick={onClick}
              style={{ padding: '12px' }} // this is currently required because of a cfa-react-components and styled components bug
            >
              <AnimatedIconPlus
                $hasMenu={hasMenu}
                $isOpen={isOpen}
                height={32}
                width={32}
              />
            </PopoverMenuToggleButton>
          </Tooltip>
        ) : (
          <PopoverMenuToggleButton
            aria-label="Add Button"
            as={IconButton}
            color="tertiary"
            data-testid="AddFAB"
            onClick={onClick}
            style={{ padding: '12px' }} // this is currently required because of a cfa-react-components and styled components bug
          >
            <AnimatedIconPlus
              $hasMenu={hasMenu}
              $isOpen={isOpen}
              height={32}
              width={32}
            />
          </PopoverMenuToggleButton>
        )}
      </StyledAddButtonWrapper>
      {children}
    </PopoverMenu>
  );
};

const StyledAddButtonWrapper = styled(Surface)`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 4;
  border-radius: 50% !important;
  background: ${props => props.theme.primaryPalette.navyBlue} !important;
`;

const AnimatedIconPlus = styled(IconPlus)<AnimatedIconPlusProps>`
  transition: transform 0.2s ease-in-out;
  transform: ${props =>
    props.$isOpen && props.$hasMenu ? 'rotate(45deg)' : 'rotate(0deg)'};
`;

export default Fab;
