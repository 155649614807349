import Constants from 'constants/index';
import {
  isReportsCompliancePlansPath,
  isReportsPlansPath,
  isReportsTeamMembersPath,
} from 'util/url';
// @ts-ignore until this file is converted in a separate ticket (not created yet)
import { getHighestUserPermission } from 'util/user';
import styled from 'styled-components';
import { TabItem, Tabs } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectUserPermissions,
  selectUserCountry,
  isUserLicensee,
  selectUserIsStaff,
} from 'store/user/selectors';

const ReportsTabs = {
  PLANS: 'plans',
  TEAM_MEMBERS: 'teammembers',
  COMPLIANCE_PLANS: 'complianceplans',
};

const ReportsSubheader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userPermissions = useSelector(selectUserPermissions);
  const isLicenseeUser = useSelector(isUserLicensee);
  const isStaffUser = useSelector(selectUserIsStaff);
  const userCountry = useSelector(selectUserCountry);
  const userCountryIsUS =
    userCountry.id === Constants.SUPPORTED_COUNTRIES.US.id;
  const highestUserPermission = getHighestUserPermission(userPermissions);
  const userIsAtLeastLeader =
    Constants.USER_PERMISSION_NAMES.LEADER === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.OPERATOR === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.STAKEHOLDER === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.ADMIN === highestUserPermission;
  let activeItemKey = '';

  if (isReportsPlansPath(location)) {
    activeItemKey = ReportsTabs.PLANS;
  }
  if (isReportsTeamMembersPath(location)) {
    activeItemKey = ReportsTabs.TEAM_MEMBERS;
  }
  if (isReportsCompliancePlansPath(location)) {
    activeItemKey = ReportsTabs.COMPLIANCE_PLANS;
  }

  return (
    <Tabs activeItemKey={activeItemKey} color="secondary" onChange={() => {}}>
      {userIsAtLeastLeader && (
        <>
          <HeaderTabItem itemKey={ReportsTabs.PLANS}>
            <HeaderLink
              to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
            >
              {t('Reports.tabPlans')}
            </HeaderLink>
          </HeaderTabItem>
          <HeaderTabItem itemKey={ReportsTabs.TEAM_MEMBERS}>
            <HeaderLink
              to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
            >
              {t('Reports.tabTeamMembers')}
            </HeaderLink>
          </HeaderTabItem>
          {!!userCountryIsUS && !isLicenseeUser && !isStaffUser && (
            <HeaderTabItem itemKey={ReportsTabs.COMPLIANCE_PLANS}>
              <HeaderLink
                to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
              >
                {t('Reports.tabCompliancePlans')}
              </HeaderLink>
            </HeaderTabItem>
          )}
        </>
      )}
    </Tabs>
  );
};

const HeaderTabItem = styled(TabItem)`
  & > button {
    padding: 0;
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

const HeaderLink = styled(Link)`
  padding: 16px 32px;
`;

export default ReportsSubheader;
