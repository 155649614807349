import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { FC } from 'react';
import { CopyrightFooter } from '../common';
import { CopyrightWrapper, DesktopWrapper, DocumentWrapper } from '../wrappers';
import TableOfContents from './TableOfContents';
import { TridionTitle } from './TridionTitle';
import { HeadingItem } from '@/util/document-utils';
import { Breadcrumb } from '@/types';
import { useAppSelector } from '@/hooks';

interface DesktopViewProps {
  lastModified?: string;
  breadcrumbs: Breadcrumb[];
  styles: Record<string, string>;
  tags: string[];
  icon?: string | null;
  documentContent: React.ReactNode;
  tocItems: HeadingItem[];
  handleTocItemClick: () => void;
  title: string;
}

/** Tridion document desktop view. */
const DesktopView: FC<DesktopViewProps> = ({
  lastModified,
  breadcrumbs,
  styles,
  tags,
  icon,
  documentContent,
  tocItems,
  handleTocItemClick,
  title,
}) => {
  const isPrinting = useAppSelector(
    state => state.documentTransient.isPrinting,
  );
  const breakpoints = useBreakpoints();
  const isLargeAndDown = useMediaQuery(breakpoints.down('xl'));

  return (
    <CopyrightWrapper>
      <DesktopWrapper $isPrinting={isPrinting}>
        <DocumentWrapper $isExtraLg={!isLargeAndDown} $isPrinting={isPrinting}>
          <TridionTitle icon={icon} tags={tags} title={title} />
          {(isPrinting || isLargeAndDown) && (
            <TableOfContents
              handleClick={handleTocItemClick}
              isExtraLg={!isLargeAndDown}
              isPrinting={isPrinting}
              tocItems={tocItems}
            />
          )}
          <div
            className={`${styles.tridionDocument} ${
              isLargeAndDown ? styles.desktopDoc : styles.extraLargeDoc
            }`}
          >
            {documentContent}
          </div>
        </DocumentWrapper>
        {!isPrinting && !isLargeAndDown && (
          <TableOfContents
            handleClick={handleTocItemClick}
            isExtraLg={!isLargeAndDown}
            tocItems={tocItems}
          />
        )}
      </DesktopWrapper>
      <CopyrightFooter
        breadcrumbs={breadcrumbs}
        isPrinting={isPrinting}
        lastPublishedDate={lastModified ?? ''}
      />
    </CopyrightWrapper>
  );
};

export default DesktopView;
