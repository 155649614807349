import styled from 'styled-components';
import { Divider, Link, Tag } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';

interface HeaderInnerObject {
  translationString: string;
  value: boolean;
}

interface HeaderOuterObject {
  [key: string]: HeaderInnerObject;
}

interface ClearFiltersHeaderProps {
  filters: string[];
  aliases: HeaderOuterObject;
  clearFilter: (filter: string) => void;
  clearAllFilters: () => void;
}

const ClearFiltersHeader: React.FC<ClearFiltersHeaderProps> = ({
  filters,
  aliases,
  clearFilter,
  clearAllFilters,
}) => {
  const isVisible = !!filters?.length;
  const { t } = useTranslation();

  return (
    <>
      {!!isVisible && (
        <>
          <Divider variant="fullLength" />
          <FilterBadgeContainer>
            {filters?.map(filter => (
              <Tag
                key={filter}
                label={aliases[filter]?.translationString || filter}
                onClose={() => clearFilter(filter)}
              />
            ))}
            {!!filters?.length && (
              <ClearButton
                onClick={clearAllFilters}
                size="body2"
                variant="standalone"
              >
                {t('Button.clearAll')}
              </ClearButton>
            )}
          </FilterBadgeContainer>
        </>
      )}
    </>
  );
};

const FilterBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
`;

const ClearButton = styled(Link)`
  text-decoration: underline !important;
  text-wrap: nowrap;
`;

export default ClearFiltersHeader;
