import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

const HEADLESS_ATTR = 'data-headless';
const TOC_HEADING_TAG = 'h2';
const STICK_CLASS = 'heading-sticky';

// get top bar height based on if docuent is headless or not
const getTopBarHeight = () => {
  if (document.documentElement.hasAttribute(HEADLESS_ATTR)) {
    return 0;
  }
  return 60;
};

/** This makes css fixes for tridion documents in headless mode. */
export const useAdjustCssForHeadless = () => {
  useEffect(() => {
    const documentElement = document.documentElement;
    documentElement.setAttribute(HEADLESS_ATTR, 'true');

    const style = document.createElement('style');

    style.textContent = `
      .heading-sticky {
        top: 0px !important;
      }
    `;

    document.head.appendChild(style);
  }, []);
};

export const getHeadingElements = () => {
  return document.querySelectorAll(
    `[class^='document_tridionDocument'] ${TOC_HEADING_TAG}`,
  );
};

export const getTocItemsCount = () => {
  const headingElements = getHeadingElements();
  return headingElements?.length;
};

export type HeadingItem = {
  id: string;
  text: string | null;
};

export const buildTableOfContentsItems = (
  headingElements: NodeListOf<Element>,
) => {
  const headings: HeadingItem[] = [];
  headingElements.forEach(headingEl => {
    const headingObject = {
      id: headingEl.id,
      text: headingEl.textContent,
    };
    headings.push(headingObject);
  });

  return headings;
};

export const removeTopElementStickyClass = () => {
  const headingElements = getHeadingElements();
  const topElement = [...headingElements].findLast(
    el => el.getBoundingClientRect().top <= getTopBarHeight(),
  );

  if (topElement && topElement.classList.contains(STICK_CLASS)) {
    topElement.classList.remove(STICK_CLASS);
  }
};

export const useTableOfContents = (isTocItemClicked: boolean) => {
  const [tocItems, setTableOfContentsArray] = useState<HeadingItem[]>([]);

  // call in each render to get rendered html heading tags count
  const tocItemCount = getTocItemsCount();

  const updateTableOfContentsArray = useCallback(() => {
    const tocElements = getHeadingElements();
    const tocArray = buildTableOfContentsItems(tocElements);
    if (tocArray?.length) {
      setTableOfContentsArray(tocArray);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateTableOfContentsArray();
    if (!tocItemCount) {
      // pull table of content after html rendering complete
      setTimeout(() => {
        updateTableOfContentsArray();
      }, 300);
    }
  }, [updateTableOfContentsArray, tocItemCount]);

  const onScrollListener = useMemo(
    () =>
      debounce(() => {
        const headingElements = getHeadingElements();
        const topElement = [...headingElements].findLast(el => {
          const top = el.getBoundingClientRect().top;
          return top <= getTopBarHeight();
        });

        if (topElement && topElement.classList.contains(STICK_CLASS)) {
          if (isTocItemClicked) {
            topElement.classList.remove(STICK_CLASS);
          }
          return;
        }

        headingElements.forEach(headingEl => {
          if (headingEl.classList.contains(STICK_CLASS)) {
            headingEl.classList.remove(STICK_CLASS);
          }
        });
        if (topElement && !isTocItemClicked) {
          topElement.classList.add(STICK_CLASS);
        }
      }, 5),
    [isTocItemClicked],
  );

  useEffect(() => {
    window.addEventListener('scroll', onScrollListener);

    return () => {
      window.removeEventListener('scroll', onScrollListener);
    };
  }, [onScrollListener]);

  return { tocItems };
};
