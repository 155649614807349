import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectPersistDocumentState = (state: RootState) => state.document;
export const selectDocumentRoutedFrom = (state: RootState) =>
  state.document.routedFrom;
export const selectSearchDocumentState = (state: RootState) =>
  state.document.document;

export const selectIsTridion = (state: RootState) => state.document.isTridion;

// transient document selectors (add separte selectors if need to, or just keep all document selectors here)
export const selectLinkDocumentState = (state: RootState) =>
  state.documentTransient.chosenLinkDocument;

/** Global document. The document state is either persisted or not based on if it comes from a direct link. */
export const selectDocumentFromDashboardOrResource = createSelector(
  [
    selectSearchDocumentState,
    selectLinkDocumentState,
    selectDocumentRoutedFrom,
  ],
  (searchDocument, linkDocument, routedFrom): Document | null => {
    if (routedFrom === 'link') {
      return linkDocument;
    } else {
      return searchDocument;
    }
  },
);

export const selectDocumentName = createSelector(
  [selectDocumentFromDashboardOrResource],
  document => document?.name,
);
