import { generateProgressText } from 'util/generateProgressText';
import { getProgressBarText } from 'util/generateProgressBarText';
import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { getTimeSpentOnPlan } from 'util/time';
import Constants from 'constants/index';
// @ts-ignore until this file is converted in a separate ticket (not created yet)
import { getDueDateColor, getDueDateString } from 'util/dueDate';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import theme from 'styles/theme';
import styled from 'styled-components';
import PlanCardIcon from 'components/PlanCard/PlanCardComponents/PlanCardIcon';
import PlanCardTag from 'components/PlanCard/PlanCardComponents/PlanCardTag';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { ComplianceCourse } from '@/types';

interface ReportsTeamMemberCompliancePlanCardProps {
  course: ComplianceCourse;
}

const ReportsTeamMemberCompliancePlanCard: React.FC<
  ReportsTeamMemberCompliancePlanCardProps
> = ({ course }) => {
  const { t } = useTranslation();
  const timeSpentOnPlan = getTimeSpentOnPlan(course, true);
  const isComplete =
    // We are only showing the completed date for Food Safety courses with a `passed` status
    getNameFromLanguage(course?.courseName)?.includes(
      Constants.FOOD_SAFETY_COURSE_NAME,
    )
      ? course?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED
      : course?.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
        course?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED;
  const isFailed =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.FAILED;
  const finalScore = course?.finalScore;
  const isCompliance = true;
  const isFoodSafety = getNameFromLanguage(course?.courseName)?.includes(
    Constants.FOOD_SAFETY_COURSE_NAME,
  );
  const percentComplete = course?.percentComplete;

  return (
    <>
      {!!course && (
        <>
          <PlanCard
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            dataTestId={'TeamMemberCompliancePlan'}
            progress={!isComplete && !isFailed ? percentComplete : null}
            tooltip={t('Reports.compliancePlanTooltip')}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {!!isComplete && (
                <>
                  <PlanCardSubHeader
                    className="success"
                    dataTestId="PlanTaskCount"
                  >
                    {generateProgressText({
                      finalScore,
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan: timeSpentOnPlan as string,
                    })}
                  </PlanCardSubHeader>
                  <PlanCardSubHeader className="bold">
                    {course?.certificate?.expiration ? (
                      t('TrainingPlans.certificationExpires', {
                        expirationDate: new Date(
                          course?.certificate?.expiration,
                        ).toLocaleDateString(),
                      })
                    ) : (
                      <>
                        <FailedIconAlertTriangleFilled />
                        <span style={{ marginLeft: 4 }}>
                          {t('TrainingPlans.noExpirationDate')}
                        </span>
                      </>
                    )}
                  </PlanCardSubHeader>
                </>
              )}
              {!!isFailed && (
                <PlanCardSubHeader className="bold" dataTestId="PlanTaskCount">
                  {generateProgressText({
                    finalScore,
                    isCompliance,
                    isComplete,
                    isFailed,
                    isFoodSafety,
                    timeSpentOnPlan: timeSpentOnPlan as string,
                  })}
                </PlanCardSubHeader>
              )}
              {!isComplete && !isFailed && (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {getProgressBarText({
                    isCompliance,
                    isComplete,
                    percentComplete,
                    timeSpentOnPlan: timeSpentOnPlan as string,
                  })}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            {!isComplete && !!course?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(course?.dueDate)}
                text={getDueDateString(course?.dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(course?.dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(
                      course?.completedDate as string,
                    ).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;

export const FailedIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  color: ${() => theme.semanticColors.warning};
`;

export default ReportsTeamMemberCompliancePlanCard;
