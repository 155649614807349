import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import { ChecklistTemplateDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { TemplateCard } from './TemplateCard';

interface TemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCopy: (id: string) => void;
  onPreviewTemplateClick: (template: ChecklistTemplateDTO) => void;
  templates: ChecklistTemplateDTO[];
}

const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  onClose,
  onCopy,
  onPreviewTemplateClick,
  templates,
}) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      onClose={onClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ModalHeader>{t('TrainingPlans.templates.templateModal')}</ModalHeader>
      <ModalBody>
        {templates.map(template => (
          <TemplateCard
            key={template.id}
            onCopy={() => onCopy(template.id as string)}
            onPreview={() => {
              onPreviewTemplateClick(template);
            }}
            plan={template}
          />
        ))}
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

export default TemplateModal;
