import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectScrollPositionReducerState = (state: RootState) =>
  state.scrollPosition;

export const scrollPosition = createSelector(
  selectScrollPositionReducerState,
  scrollPositionReducerState => scrollPositionReducerState,
);
