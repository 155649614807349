import { createSelector } from 'reselect';
// @ts-ignore
import { isEqual } from 'lodash/lang';
import { RootState } from '..';

export const manageTranslationsState = (state: RootState) =>
  state.manageTranslations;

export const manageTranslations = createSelector(
  manageTranslationsState,
  state => state.mutable,
);

export const areTranslationsEdited = createSelector(
  manageTranslationsState,
  state => !isEqual(state.mutable, state.initial),
);
