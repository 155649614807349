// @ts-ignore
import { ErrorBoundary } from 'bugsnag';
import { FC, PropsWithChildren } from 'react';
import GenericError from '@/sharedComponents/app/GenericError';

const ErrorBoundaries: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
  );
};

export default ErrorBoundaries;
