import styled from 'styled-components';
import {
  Accordion,
  AccordionChangeCallbackType,
  AccordionItem,
  AccordionItemKeyType,
  Divider,
} from 'cfa-react-components';
import React from 'react';

interface SubCategoryAccordionProps {
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  defaultActiveItemKey?: string | unknown[];
  itemKey?: number | string;
  name: string;
  onClick?: AccordionChangeCallbackType;
}

const SubCategoryAccordion = ({
  name,
  'data-testid': DataTestId,
  className,
  children,
  defaultActiveItemKey,
  itemKey,
  onClick,
}: SubCategoryAccordionProps) => {
  return (
    <SectionAccordion
      activeItemKey={defaultActiveItemKey as AccordionItemKeyType}
      className={className}
      data-testid={DataTestId}
      defaultActiveItemKey={defaultActiveItemKey as AccordionItemKeyType}
      elevation={1}
      iconPosition="end"
      onChange={onClick}
      variant="surface"
    >
      <SectionAccordionItem header={name} itemKey={itemKey as string}>
        <AccordianDivider />
        <AccordianContent>{children}</AccordianContent>
      </SectionAccordionItem>
    </SectionAccordion>
  );
};

SubCategoryAccordion.defaultProps = {
  children: null,
  className: '',
  'data-testid': '',
  defaultActiveItemKey: '',
  itemKey: '',
  onClick: () => {},
};

const SectionAccordion = styled(Accordion)`
  margin: 16px 0;
`;

const AccordianContent = styled.div`
  margin-top: 4px;
`;
const SectionAccordionItem = styled(AccordionItem)`
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.grayScale.gray2}`} !important;
`;

const AccordianDivider = styled(Divider)`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
`;

export default SubCategoryAccordion;
