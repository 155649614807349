import { PopoverMenuContent } from 'cfa-react-components';

interface FabPopupProps {
  children: React.ReactNode;
}

const FabPopup: React.FC<FabPopupProps> = ({ children, ...props }) => {
  return <PopoverMenuContent {...props}>{children}</PopoverMenuContent>;
};

export default FabPopup;
