import styled from 'styled-components';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, TextFieldType, Typography } from 'cfa-react-components';

interface EditTaskProps {
  error: string;
  onEdit: () => void;
  setTask: any;
  task: { name: string; note: string; url: string };
}

const EditTask: React.FC<EditTaskProps> = ({
  error,
  onEdit,
  setTask,
  task,
}) => {
  const { t } = useTranslation();
  const nameRef = useRef<TextFieldType>(null);
  const notesRef = useRef<TextFieldType>(null);
  const linkRef = useRef<TextFieldType>(null);

  const maxLength = 250;

  return (
    <StyledEditTaskProcedureContainer>
      <Typography variant="overline3">{t('Generic.task')}</Typography>
      <TaskTextField
        data-testid="EditTaskName"
        fullWidth
        maxLength={maxLength}
        onChange={e => setTask({ ...task, name: e.target.value })}
        onKeyDown={e =>
          e.key === 'Enter' && notesRef.current && notesRef.current.focus()
        }
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.task')}
        ref={nameRef}
        value={task.name}
      />
      <Typography variant="overline3">{t('Generic.notes')}</Typography>
      <TaskTextField
        data-testid="EditNotes"
        fullWidth
        onChange={e => setTask({ ...task, note: e.target.value })}
        onKeyDown={e =>
          e.key === 'Enter' && linkRef.current && linkRef.current.focus()
        }
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.notes')}
        ref={notesRef}
        value={task.note ?? ''}
      />
      <Typography variant="overline3">{t('Generic.url')}</Typography>
      <TaskTextField
        data-testid="EditTaskUrl"
        // @ts-ignore (overload error, we can ignore for now)
        error={error}
        errorText={error}
        fullWidth
        onChange={e => setTask({ ...task, url: e.target.value })}
        onKeyDown={e =>
          e.key === 'Enter' && task.name.trim().length > 0 && onEdit()
        }
        placeholder={t('TrainingPlans.addTaskFormPlaceholderText.link')}
        ref={linkRef}
        value={task.url ?? ''}
      />
    </StyledEditTaskProcedureContainer>
  );
};

const StyledEditTaskProcedureContainer = styled.div`
  width: 100%;
`;
const TaskTextField = styled(TextField)`
  margin-bottom: 16px;
`;

export default EditTask;
