import { createSelector } from 'reselect';
import { RootState } from '..';

export const nativeOptionsState = (state: RootState) => state.nativeOptions;

// Note: This is set by the platform like this: window.nativeOptions = '{ "menu": false }';
export const selectShouldShowMobileNavigationMenu = createSelector(
  nativeOptionsState,
  state => !!state.menu,
);
