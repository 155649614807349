import { isMobileTest } from 'util/testUtils';
import { useEffect, useState } from 'react';
import { isMobileOnly, isIOS, isAndroid } from 'react-device-detect';
import Constants from '@/constants';

const size = {
  mobile: 839,
  desktop: 840,
};

export const isIPhoneOrIpad = isIOS;
export const isAndroidDevice = isAndroid;

export const device = {
  mobile: `(max-width: ${size.mobile}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};

// This refers to type of user device
export const userAgentDeviceIsDesktop =
  !isMobileOnly &&
  !isMobileTest(
    Constants.IS_IN_CYPRESS_TEST,
    Constants.CYPRESS_URL_PARAMS.IS_MOBILE_URL_PARAM,
  );

// This refers to browser window width
const getIsDesktopByWindowWidth = () => window.innerWidth >= size.desktop;

export function useDeviceInfo() {
  const [isDesktop, setIsDesktop] = useState(getIsDesktopByWindowWidth());
  useEffect(() => {
    const handleResize = () => setIsDesktop(getIsDesktopByWindowWidth());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  return { isDesktop };
}

export const isMacOS = window.navigator.platform.includes('Mac');
