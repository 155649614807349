// @ts-ignore adding the type ends up causing more errors than its worth
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import { isMobileOnly, isIOS } from 'react-device-detect';
import { useIsNative } from 'hooks/useIsNative';

const SmartBannerContainer = () => {
  const isNative = useIsNative();
  return (
    <>
      {!isNative && isMobileOnly && isIOS && (
        <SmartBanner
          button="Get"
          ignoreIosVersion={true}
          position="top"
          price={{
            ios: 'Download',
            // android: 'Download',
            // windows: 'Download',
            // kindle: 'Download',
          }}
          storeText={{
            ios: 'app',
            // android: 'app',
            // windows: 'app',
            // kindle: 'app',
          }}
          title={'Pathway 2.0'}
          url={{
            ios: 'https://apps.apple.com/us/app/chick-fil-a-pathway-2-0/id1628910112',
            // android:
            //   'https://install.appcenter.ms/orgs/dtt-ots/apps/pathway-1/distribution_groups/operators_and_team_members',
            // windows:
            //   'https://install.appcenter.ms/orgs/dtt-ots/apps/pathway-1/distribution_groups/operators_and_team_members',
            // kindle:
            //   'https://install.appcenter.ms/orgs/dtt-ots/apps/pathway-1/distribution_groups/operators_and_team_members',
          }}
        />
      )}
    </>
  );
};

export default SmartBannerContainer;
