import { RadioGroup, RadioButton } from 'cfa-react-components';
import styled from 'styled-components';

interface RadioButtonListProps {
  defaultValue: string;
  setValue: (value: string) => void;
  listItems: string[];
  name: string;
}

const RadioButtonList: React.FC<RadioButtonListProps> = ({
  defaultValue,
  setValue,
  listItems,
  name,
}) => {
  return (
    <StyledRadioGroup
      defaultValue={defaultValue}
      name={name}
      onChange={e => setValue(e.target.value)}
      orientation="vertical"
    >
      {listItems.map((item, id) => {
        return (
          <RadioButton key={`${item}-item-${id}`} label={item} value={item} />
        );
      })}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default RadioButtonList;
