import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { setDocument } from '../document-persisted/slice';

/** Document state not persisted in local storage. */
interface DocumentTransientState {
  isPrinting: boolean;
  isTranslating: boolean;
  chosenLinkDocument: Document | null;
}

const initialState: DocumentTransientState = {
  isPrinting: false,
  isTranslating: false,
  chosenLinkDocument: null,
};

export const slice = createSlice({
  name: 'documentTransient',
  initialState,
  reducers: {
    setIsPrinting: (state, action: PayloadAction<boolean>) => {
      state.isPrinting = action.payload;
    },
    translationButtonPress: state => {
      state.isTranslating = !state.isTranslating;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DocumentTransientState>) => {
    builder.addCase(setDocument, (state, action) => {
      const { document, routedFrom } = action.payload;
      if (routedFrom === 'link') {
        state.chosenLinkDocument = document;
      }
    });
  },
});

export const { setIsPrinting, translationButtonPress } = slice.actions;

export default slice.reducer;
