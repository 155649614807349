import Constants from 'constants/index';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateComplianceHeartbeatMutation } from 'services/pathwayApi';

/** Used to track time spent on compliance courses. */
export const useComplianceTimer = (courseId: string) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const [updateComplianceHeartbeat] = useUpdateComplianceHeartbeatMutation();
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [roundUpTimer, setRoundUpTimer] = useState(false);

  const logTimeViewed = useCallback(
    (milliSeconds: number) => {
      const seconds = milliSeconds / 1000;
      updateComplianceHeartbeat({
        courseId: courseId,
        interval: seconds,
      })
        .unwrap()
        .then()
        .catch(err => {
          notifyBugsnag(err);
        });
    },
    [courseId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    let logIntervalId: NodeJS.Timeout | null = null;
    let roundIntervalId: NodeJS.Timeout | null = null;

    if (timerIsRunning && courseId) {
      logIntervalId = setInterval(() => {
        setRoundUpTimer(prevState => !prevState);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_ROUNDUP);
      roundIntervalId = setInterval(() => {
        logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
    }
    return () => {
      if (logIntervalId) {
        clearInterval(logIntervalId);
      }
      if (roundIntervalId) {
        clearInterval(roundIntervalId);
      }
    };
  }, [courseId, logTimeViewed, timerIsRunning]);

  useEffect(() => {
    if (roundUpTimer && !timerIsRunning && courseId) {
      logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      setRoundUpTimer(false);
    }
  }, [logTimeViewed, courseId, roundUpTimer, timerIsRunning]);

  useEffect(() => {
    return () => {
      setTimerIsRunning(false);
    };
  }, []);

  const startTimer = useCallback(() => {
    setTimerIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setTimerIsRunning(false);
  }, []);

  return { startTimer, stopTimer };
};
