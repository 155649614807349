import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectPrintReportReducerState = (state: RootState) =>
  state.printReport;

export const printReport = createSelector(
  selectPrintReportReducerState,
  printReportReducerState => printReportReducerState.isOpen,
);
