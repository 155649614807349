import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import {
  useGetEditableChecklistsQuery,
  useGetOperatorsQuery,
} from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { isUserLicensee } from 'store/user/selectors';
import { setHeader } from 'store/header/slice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { withRoles } from 'sharedComponents/app/withRoles';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import {
  setBuildPlans,
  addBuildPlansCategoryFilter,
  removeBuildPlansFilter,
} from 'store/buildPlansFilter/slice';
import {
  selectFilters,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/buildPlansFilter/selector';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import toast from 'react-hot-toast';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { selectActiveLicenseeLocation } from 'store/licenseeLocationPicker/selector';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  ChecklistDTO,
  OperatorDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import FilterAndSearch from './FilterAndSearch';
import SortOptions from './SortOptions';
import PlanCards from './PlanCards';
import Modals from './Modals';
import ScrollToTop from '@/ScrollToTop';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';

interface BuildPlansLocationStateProps {
  state: {
    deletedPlan: ChecklistDTO;
    duplicatedPlan: ChecklistDTO;
  };
}

const BuildPlansTab = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLicenseeUser = useSelector(isUserLicensee);
  const selectedLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const [showAddPlanPopUp, setShowAddPlanPopUp] = useState(false);
  const [showCustomPlan, setShowCustomPlan] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCopyTemplatePopup, setShowCopyTemplatePopup] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);
  const [copiedTemplateId, setCopiedTemplatePlanId] = useState('');
  const [previewTemplate, setPreviewTemplate] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const currentFilters: string[] = useSelector(selectFilters);
  const categoryLabels = useCategoryLabels(currentFilters, true);

  const {
    data: unOrderedPlans,
    isFetching,
    error,
    refetch,
  } = useGetEditableChecklistsQuery();

  const {
    data: operators,
    isFetching: isFetchingOperators,
    error: errorGettingOperators,
  } = useGetOperatorsQuery();

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  useEffect(() => {
    if (isLicenseeUser) {
      const licenseeLocationPlans = unOrderedPlans?.filter(plan => {
        return plan.ownerId === selectedLicenseeLocation.number;
      });
      dispatch(setBuildPlans({ plans: licenseeLocationPlans ?? [] }));
    } else {
      dispatch(setBuildPlans({ plans: unOrderedPlans ?? [] }));
    }
  }, [
    dispatch,
    isLicenseeUser,
    selectedLicenseeLocation.number,
    unOrderedPlans,
  ]);

  // We trigger a refetch when an action is performed from the three-dot menu in TrainingPlan.js
  useEffect(() => {
    refetch();
  }, [refetch]);

  // We trigger the toast notification when a plan is deleted in the three-dot menu in TrainingPlan.js
  useEffect(() => {
    if (
      location.state &&
      (location as BuildPlansLocationStateProps).state.deletedPlan
    ) {
      setIsOpen(true);
      toast.custom(toastObj => (
        <ToastMessageBlock id={toastObj.id}>
          {`${(location as BuildPlansLocationStateProps).state.deletedPlan} ${t(
            'TrainingPlans.toastMessage.deleted',
          )}`}
        </ToastMessageBlock>
      ));

      // After we display the toast, we set the state back to an empty string
      history.replace({
        pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        state: { deletedPlan: '' },
      });
    }
  }, [history, location, t]);

  // We trigger the toast notification when a plan is duplicated in the three-dot menu in TrainingPlan.js
  useEffect(() => {
    if (
      location.state &&
      (location as BuildPlansLocationStateProps).state.duplicatedPlan
    ) {
      setIsOpen(true);
      toast.custom(toastObj => (
        <ToastMessageBlock id={toastObj.id}>
          {`${
            (location as BuildPlansLocationStateProps).state.duplicatedPlan
          } ${t('TrainingPlans.toastMessage.duplicated')}`}
        </ToastMessageBlock>
      ));

      // After we display the toast, we set the state back to an empty string
      history.replace({
        pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        state: { duplicatedPlan: '' },
      });
    }
  }, [history, location, t]);

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  if (isApiError(errorGettingOperators)) {
    notifyBugsnag(errorGettingOperators);
    return <GenericError />;
  }

  const onShowCopyPlan = (id: string) => {
    setCopiedTemplatePlanId(id);
    setShowPreview(false);
    setShowTemplateModal(false);
    setShowCopyTemplatePopup(true);
  };

  const hideAddPlanPopUp = () => {
    setShowAddPlanPopUp(false);
  };

  const handlePreviewPlan = (template: any) => {
    setPreviewTemplate(template);
    setShowTemplateModal(false);
    setShowPreview(true);
  };

  const handleTemplateBackButton = () => {
    setPreviewTemplate({});
    setShowTemplateModal(true);
    setShowPreview(false);
  };

  const onShowCustomPlanPopup = () => {
    setShowCustomPlan(true);
    setShowAddPlanPopUp(false);
  };

  const onShowTemplateModal = () => {
    setShowTemplateModal(true);
    setShowAddPlanPopUp(false);
  };

  return (
    <>
      {isOpen && <ScrollToTop />}
      <StyledContent>
        <FilterAndSearch
          isFetching={isFetching}
          isFetchingOperators={isFetchingOperators}
          numberOfPlans={filteredAndSortedPlans.length}
        />
        <PlanCardList>
          {!isSmAndDown && !isFetching && !isFetchingOperators && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeBuildPlansFilter({ filter: value }));
                  } else {
                    dispatch(addBuildPlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortOptions />
            <LoadingOverlay isOpen={isFetching || isFetchingOperators} />
            <PlanCards
              hideAddPlanPopUp={hideAddPlanPopUp}
              isFetching={isFetching}
              isFetchingOperators={isFetchingOperators}
              onShowCustomPlanPopup={onShowCustomPlanPopup}
              onShowTemplateModal={onShowTemplateModal}
              plans={filteredAndSortedPlans}
              refetch={refetch}
              setIsOpen={setIsOpen}
              setShowAddPlanPopUp={setShowAddPlanPopUp}
              setShowInvalidNameError={setShowInvalidNameError}
              showAddPlanPopUp={showAddPlanPopUp}
            />
          </PlanCardsContainer>
        </PlanCardList>
        <Modals
          copiedTemplateId={copiedTemplateId}
          handlePreviewPlan={handlePreviewPlan}
          handleTemplateBackButton={handleTemplateBackButton}
          isFetchingOperators={isFetchingOperators}
          onShowCopyPlan={onShowCopyPlan}
          operators={operators as OperatorDTO[]}
          previewTemplate={previewTemplate}
          refetch={refetch}
          setShowCopyTemplatePopup={setShowCopyTemplatePopup}
          setShowCustomPlan={setShowCustomPlan}
          setShowInvalidNameError={setShowInvalidNameError}
          setShowPreview={setShowPreview}
          setShowTemplateModal={setShowTemplateModal}
          showCopyTemplatePopup={showCopyTemplatePopup}
          showCustomPlan={showCustomPlan}
          showPreview={showPreview}
          showTemplateModal={showTemplateModal}
        />
      </StyledContent>

      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
export default withRoles(
  BuildPlansTab,
  [Constants.USER_PERMISSIONS.LEADER, Constants.USER_PERMISSIONS.OPERATOR],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
