import { useDeviceInfo } from 'util/device';
import styled from 'styled-components';
import { Divider, Surface } from 'cfa-react-components';
import Constants from '@/constants';

interface StickyFilterCardProps {
  children: React.ReactNode | React.ReactNode[];
}

interface FilterCardProps {
  $isDesktop: boolean;
  $topOffset: string;
}

const StickyFilterCard: React.FC<StickyFilterCardProps> = ({ children }) => {
  const { isDesktop } = useDeviceInfo();
  const topOffset = '103px';

  return (
    <FilterCard
      $isDesktop={isDesktop}
      $top={topOffset}
      data-testid={'StickyFilterCard'}
      elevation={1}
      radii="sm"
      variant="outlined"
    >
      {/** We check to see if there are multiple children */}
      {children && (children as React.ReactNode[]).length !== undefined ? (
        (children as React.ReactNode[]).map((child, index) => {
          return (
            <div key={index}>
              {child}
              {index !== (children as React.ReactNode[]).length - 1 &&
                child && <SectionDivider variant="fullLength" />}
            </div>
          );
        })
      ) : (
        <div>{children}</div>
      )}
    </FilterCard>
  );
};

const FilterCard = styled(Surface)<FilterCardProps>`
  position: sticky;
  top: ${({ $top, $isDesktop }) =>
    $isDesktop ? $top : `calc(${$top} + ${Constants.HEIGHT.MOBILE_TOP_NAV})`};
  // this calc is to prevent the card from overflowing and also going over the topnav
  max-height: ${({ $top, $isDesktop }) =>
    $isDesktop
      ? `calc(100vh - ${$top} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`
      : `calc(100vh - ${$top} - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`};
  z-index: 4;
  width: 270px;
  min-width: 270px;
  flex-shrink: 1;
  padding: 16px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0;
  overflow-y: auto;
`;

const SectionDivider = styled(Divider)`
  margin: 16px 0;
`;

export default StickyFilterCard;
