import Constants from 'constants/index';
import styled from 'styled-components';
import { selectUserLanguage } from 'store/user/selectors';
import { PageNotFound_en, PageNotFound_es } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { closeFileNotFound, openFileNotFound } from 'store/fileNotFound/slice';
import { useEffect } from 'react';

interface FlexWrapperProps {
  $isSmAndDown: boolean;
}

const PageNotFound404 = () => {
  const userLanguage = useSelector(selectUserLanguage);
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openFileNotFound());
    return () => {
      dispatch(closeFileNotFound());
    };
  }, [dispatch]);

  return (
    <FlexWrapper $isSmAndDown={isSmAndDown}>
      {userLanguage === Constants.LANGUAGE.SPANISH_LANGUAGE_CODE ? (
        <StyledPageNotFoundES />
      ) : (
        <StyledPageNotFoundEN />
      )}
    </FlexWrapper>
  );
};

const FlexWrapper = styled.main<FlexWrapperProps>`
  height: ${({ $isSmAndDown }) =>
    $isSmAndDown
      ? `calc(
    100vh - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.TAB_HEIGHT} -
      ${Constants.HEIGHT.FOOTER} - ${Constants.HEIGHT.MOBILE_TOP_NAV})`
      : `calc(
    100vh - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.TAB_HEIGHT} -
      ${Constants.HEIGHT.FOOTER})`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledPageNotFoundEN = styled(PageNotFound_en)`
  height: 100%;
  width: 100%;
  max-width: 1030px;
`;
const StyledPageNotFoundES = styled(PageNotFound_es)`
  height: 100%;
  width: 100%;
  max-width: 1030px;
`;

export default PageNotFound404;
