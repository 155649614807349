import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';

const planCategoriesMap = {
  [Constants.PLAN_CATEGORIES.DEFAULT]: 'TrainingPlans.planCategories.default',
  [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]:
    'TrainingPlans.planCategories.backOfHouse',
  [Constants.PLAN_CATEGORIES.COMPLIANCE]:
    'TrainingPlans.planCategories.compliance',
  [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]:
    'TrainingPlans.planCategories.frontOfHouse',
  [Constants.PLAN_CATEGORIES.HOSPITALITY]:
    'TrainingPlans.planCategories.hospitality',
  [Constants.PLAN_CATEGORIES.LEADERSHIP]:
    'TrainingPlans.planCategories.leadership',
  [Constants.PLAN_CATEGORIES.ONBOARDING]:
    'TrainingPlans.planCategories.onboarding',
};

export const useCategoryLabels = (
  currentFilters: string[],
  buildPlans?: boolean,
) => {
  const { t } = useTranslation();

  const categoriesMap = buildPlans
    ? Object.fromEntries(
        Object.entries(planCategoriesMap).filter(
          ([key]) => key !== Constants.PLAN_CATEGORIES.COMPLIANCE,
        ),
      )
    : planCategoriesMap;

  return Object.entries(categoriesMap).reduce(
    (
      acc: Record<string, { translationString: string; value: boolean }>,
      [key, translationKey],
    ) => ({
      ...acc,
      [key]: {
        translationString: t(translationKey),
        value: !!currentFilters.includes(key),
      },
    }),
    {} as Record<string, { translationString: string; value: boolean }>,
  );
};
