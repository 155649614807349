import { IconCornerRightDown } from '@tabler/icons-react';
import { Typography } from 'cfa-react-components';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HeadingItem } from '@/util/document-utils';

interface TableOfContentsProps {
  isMobile?: boolean;
  isExtraLg?: boolean;
  isPrinting?: boolean;
  tocItems?: HeadingItem[];
  handleClick?: MouseEventHandler<HTMLLIElement>;
}

const TableOfContents: FC<TableOfContentsProps> = ({
  isMobile = false,
  isExtraLg = false,
  isPrinting = false,
  tocItems = [],
  handleClick,
}) => {
  const { t } = useTranslation();

  if (isPrinting && !tocItems?.length) {
    return null;
  }

  return (
    <ListWrapper
      $isExtraLg={isExtraLg}
      $isMobile={isMobile}
      $isPrinting={isPrinting}
    >
      <TableOfContentsHeader>
        {isPrinting ? 'Contents' : t('Generic.onThisPage')}
      </TableOfContentsHeader>
      {tocItems.map((item, index) => (
        <li key={index} onClick={handleClick} style={{ marginBottom: '8px' }}>
          <StyledLink href={`#${item.id}`}>
            <CornerRightIcon />
            {item.text}
          </StyledLink>
        </li>
      ))}
    </ListWrapper>
  );
};

const ListWrapper = styled.ul<{
  $isExtraLg: boolean;
  $isMobile: boolean;
  $isPrinting: boolean;
}>`
  list-style-type: none;
  margin-top: ${({ $isExtraLg }) => (!$isExtraLg ? '-8px' : 0)};
  margin-bottom: ${({ $isPrinting }) => ($isPrinting ? '40px' : 0)};
  width: ${({ $isExtraLg }) => ($isExtraLg ? '272px' : '100%')};
  margin-left: ${({ $isExtraLg }) => ($isExtraLg ? '40px' : 0)};
  padding: ${({ $isExtraLg, $isMobile }) =>
    !$isMobile && !$isExtraLg ? '0 40px' : 0};
  flex-shrink: 0;
  ${({ $isExtraLg, $isPrinting }) =>
    $isExtraLg &&
    !$isPrinting &&
    `
      position: fixed;
      top: calc(15% + 56px);
      left: calc(50% + 255px);
      max-height: 75%;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

const TableOfContentsHeader = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  font-weight: 700;
  margin-bottom: 8px;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primaryPalette.navyBlue} !important;
  text-decoration: none !important;
  display: flex;
`;

const CornerRightIcon = styled(IconCornerRightDown)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  flex-shrink: 0;
  transform: translateY(3px);
`;

export default TableOfContents;
