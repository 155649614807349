import { createSelector } from 'reselect';
import { RootState } from '..';

export const selectAppReducerState = (state: RootState) => state.app;

export const selectIsInitialized = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.initialized,
);

export const selectBugsnagInitialized = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.bugsnagInitialized,
);
