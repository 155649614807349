// @ts-ignore we can convert this after P-821 and P-1010 are merged
import LicenseeLocationPicker from './LicenseeLocationPicker/LicenseeLocationPicker';

interface LayoutWrapperProps {
  children: React.ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  return (
    <>
      {children}
      <LicenseeLocationPicker />
    </>
  );
};

export default LayoutWrapper;
