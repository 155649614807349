import { createSelector } from 'reselect';
import { RootState } from '..';

export const webAlertBannerReducerState = (state: RootState) =>
  state.webAlertBanner;

export const selectWebAlertBannerLastDismissed = createSelector(
  webAlertBannerReducerState,
  state => state.lastDismissed,
);
