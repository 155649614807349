import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';

type SortOption = {
  id: string;
  translationString: string;
  value: string;
};

interface SortOptions {
  managePlanView?: boolean;
  myPlans?: boolean;
  reportsPlansView?: boolean;
  reportsTeamMembersTab?: boolean;
  reportsTeamMembersView?: boolean;
}

const sortOptionMap: Record<string, SortOption[]> = {
  myPlans: [
    {
      id: '1',
      translationString: 'TrainingPlans.filtering.dueDate',
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
    {
      id: '2',
      translationString: 'TrainingPlans.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '3',
      translationString: 'TrainingPlans.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ],
  managePlanView: [
    {
      id: '1',
      translationString: 'TrainingPlans.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: 'TrainingPlans.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: 'TrainingPlans.filtering.dueDate',
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
  ],
  reportsPlansView: [
    {
      id: '1',
      translationString: 'TrainingPlans.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: 'TrainingPlans.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: 'TrainingPlans.filtering.dueDate',
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
  ],
  reportsTeamMembersTab: [
    {
      id: '1',
      translationString: 'Reports.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: 'Reports.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: 'Reports.filtering.startDate',
      value: Constants.PLANS_SORT_OPTIONS.START_DATE,
    },
  ],
  reportsTeamMembersView: [
    {
      id: '1',
      translationString: 'TrainingPlans.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: 'TrainingPlans.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: 'TrainingPlans.filtering.newest',
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '4',
      translationString: 'TrainingPlans.filtering.oldest',
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '5',
      translationString: 'TrainingPlans.filtering.dueDate',
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
  ],
  default: [
    {
      id: '1',
      translationString: 'TrainingPlans.filtering.newest',
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: 'TrainingPlans.filtering.oldest',
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: 'TrainingPlans.filtering.aToZ',
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: 'TrainingPlans.filtering.zToA',
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ],
};

export const useSortOptions = ({
  managePlanView = false,
  myPlans = false,
  reportsPlansView = false,
  reportsTeamMembersTab = false,
  reportsTeamMembersView = false,
}: SortOptions = {}) => {
  const { t } = useTranslation();

  const key = Object.keys(sortOptionMap).find(
    optionKey =>
      (optionKey === 'managePlanView' && managePlanView) ||
      (optionKey === 'myPlans' && myPlans) ||
      (optionKey === 'reportsPlansView' && reportsPlansView) ||
      (optionKey === 'reportsTeamMembersTab' && reportsTeamMembersTab) ||
      (optionKey === 'reportsTeamMembersView' && reportsTeamMembersView),
  ) as keyof typeof sortOptionMap;

  return (sortOptionMap[key] || (sortOptionMap.default as SortOption[])).map(
    option => ({
      ...option,
      translationString: t(option.translationString),
    }),
  );
};
