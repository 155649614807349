import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCertificate,
  IconCircleCheck,
  IconNotes,
  IconPlus,
} from '@tabler/icons-react';

interface SectionButtonsProps {
  addProcedure: () => void;
  addQuiz: () => void;
  setShowAddTask: (task: boolean) => void;
  showAddTask: boolean;
}

const SectionButtons: React.FC<SectionButtonsProps> = ({
  setShowAddTask,
  addProcedure,
  showAddTask,
  addQuiz,
}) => {
  const { t } = useTranslation();

  const hideAddTaskIfAddingTask = () => {
    if (showAddTask) {
      setShowAddTask(false);
    }
  };

  return (
    <StyledSectionButtonsWrapper>
      <PopoverMenuButton
        size="sm"
        text={
          <AddButton data-testid="sectionAddButton">
            <IconPlus />
            <>{t('Button.addEllipsis')}</>
          </AddButton>
        }
      >
        <PopoverMenuButtonItem
          dataTestId="sectionAddProcedureButton"
          icon={<IconNotes />}
          onClick={() => {
            hideAddTaskIfAddingTask();
            addProcedure();
          }}
          text={t('Generic.resources')}
        />
        <PopoverMenuButtonItem
          dataTestId="sectionAddTaskButton"
          icon={<IconCircleCheck />}
          onClick={() => {
            setShowAddTask(true);
          }}
          text={t('Generic.task')}
        />
        <PopoverMenuButtonItem
          dataTestId="sectionAddQuizButton"
          icon={<IconCertificate />}
          onClick={() => {
            hideAddTaskIfAddingTask();
            addQuiz();
          }}
          text={t('Generic.quiz')}
        />
      </PopoverMenuButton>
    </StyledSectionButtonsWrapper>
  );
};

const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledSectionButtonsWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;
export default SectionButtons;
