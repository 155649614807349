import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import styled from 'styled-components';
import Constants from '@/constants';
import useDocumentCookieRefresh from '@/hooks/useDocumentCookieRefresh';

interface DocumentPreviewProps {
  fileId?: string;
  versionId?: string;
  contentApiUrl?: string;
}

const DocumentPreview: FC<DocumentPreviewProps> = ({
  fileId,
  versionId,
  contentApiUrl,
}) => {
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const contentUrl =
    xpApiFeatureFlag && contentApiUrl
      ? contentApiUrl
      : `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/index.html`;

  const { isSuccess: isDocumentCookieSuccess } = useDocumentCookieRefresh();

  return (
    <>
      {isDocumentCookieSuccess && (
        <StyledIFrame
          allow="fullscreen"
          className="content-iframe"
          src={contentUrl}
        />
      )}
    </>
  );
};

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default DocumentPreview;
