import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import {
  generateTotalTime,
  generateTotalTimeFromSteps,
  getTimeSpentOnPlan,
} from 'util/time';
import { removeUnderscoreAndCapitalizeString } from 'util/removeUnderscoreAndCapitalizeString';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { messageReactNative } from 'util/messageReactNative';
import { generateReport, printToNative } from 'util/generateReport';
import { convertDateForReports } from 'util/date';
import { getCompletionDate } from 'util/reports';
import { useEffect, useMemo, useState } from 'react';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import {
  enterTrainingMode,
  hideTrainingMode,
  setAssignedChecklistStatus,
  setTrainingModeData,
} from 'store/trainingMode/slice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconUserPlus } from '@tabler/icons-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import {
  useAssignUserToChecklistMutation,
  useAssignUserToComplianceCourseMutation,
  useGetAssignedChecklistByIdQuery,
  useGetAssignedCoursesQuery,
  useGetAssignedStatusQuery,
  useGetCourseReportQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
  useGetTrainingPlanQuery,
  useUnassignUserToChecklistMutation,
  useUpdateDueDateMutation,
} from 'services/pathwayApi';
import DueDatePopUp from 'sharedComponents/app/popups/DueDatePopUp';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import GenericError from 'sharedComponents/app/GenericError';
import uniqBy from 'lodash/uniqBy';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { selectTrainingModeModalIsOpen } from 'store/trainingMode/selectors';
import { withRoles } from 'sharedComponents/app/withRoles';
import { Fab } from 'sharedComponents/app/Fab/Fab';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import {
  RadioButton,
  RadioGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import {
  selectAllTeamMembers,
  selectFilters,
  selectPagination,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from 'store/teamMembersFilter/selector';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import {
  addTeamMembersStatusFilter,
  addTeamMembersLocationFilter,
  removeTeamMembersFilter,
  setTeamMembers,
  setTeamMembersSort,
  clearTeamMembersCheckboxFilters,
  loadMoreTeamMembers,
  clearTeamMembersSearchFilter,
} from 'store/teamMembersFilter/slice';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { printReport } from 'store/printReport/selectors';
import { hidePrintReport } from 'store/printReport/slice';
import AddTeamMembersPopUp from 'sharedComponents/app/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PrintReportModal from 'sharedComponents/app/popups/PrintReportModal';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import TeamMemberCard from './TeamMemberCard/TeamMemberCard';
import FabPopupItem from '@/sharedComponents/app/Fab/FabPopupItem';
import FabPopup from '@/sharedComponents/app/Fab/FabPopup';
import { useSortOptions } from '@/hooks/useSortOptions';

export const NoMessage = props => {
  const { message } = props;
  return <StyledNoMessageText variant="body2">{message}</StyledNoMessageText>;
};

NoMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export const AssignedTeamMembersCount = props => {
  const { inNavbar } = props;
  const { t } = useTranslation();
  return (
    <StyledAssignedTeamMembers inNavbar={inNavbar}>
      {inNavbar ? t('Generic.chooseTeamMembers') : t('TrainingPlans.assigned')}
    </StyledAssignedTeamMembers>
  );
};

AssignedTeamMembersCount.propTypes = {
  inNavbar: PropTypes.bool.isRequired,
};

const ManagePlanView = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { search, state } = useLocation();
  const sortOptions = useSortOptions({ managePlanView: true });
  const { id: planId } = useParams();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const isCompliance = search.includes('compliancePlan');
  const isFoodSafety =
    decodeURI(search).includes(Constants.FOOD_SAFETY_COURSE_NAME) &&
    search.includes('compliancePlan');
  const [showAddTeamMembersMenu, setShowAddTeamMembersMenu] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);
  const [showSelectLanguagePopup, setShowSelectLanguagePopup] = useState(false);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [selectedReportLocations, setSelectedReportLocations] = useState([]);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [filteredReportTeamMembers, setFilteredReportTeamMembers] = useState(
    [],
  );
  const [showTrainingModePopup, setShowTrainingModePopup] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [temporaryTeamMembers, setTemporaryTeamMembers] = useState([]);
  const [trainingModeTeamMembers, setTrainingModeTeamMembers] = useState([]);
  const [showUnassignTeamMemberPopup, setShowUnassignTeamMemberPopup] =
    useState(false);
  const [activeTeamMember, setActiveTeamMember] = useState({
    id: '',
    name: '',
  });
  const [stepCount, setStepCount] = useState(0);
  const [languageSelected, setLanguageSelected] = useState('English');
  const [isDueDateUpdate, setIsDueDateUpdate] = useState(false);
  const [triggerDueDateReset, setTriggerDueDateReset] = useState(false);
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    foodSafetyImage: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });

  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const { showing, total } = useSelector(selectPagination);

  const statusLabels = !isCompliance
    ? {
        [Constants.TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!currentFilters.includes(Constants.TRAINING_PLANS.COMPLETED),
        },
        [Constants.TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!currentFilters.includes(
            Constants.TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      }
    : {
        [Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!currentFilters.includes(
            Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED,
          ),
        },
        [Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!currentFilters.includes(
            Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      };
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const [assignUserToComplianceCourse] =
    useAssignUserToComplianceCourseMutation();
  const [unassignUserToChecklist] = useUnassignUserToChecklistMutation();
  const [updateDueDate] = useUpdateDueDateMutation();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const trainingModeModalIsOpen = useSelector(selectTrainingModeModalIsOpen);
  const allTeamMembers = useSelector(selectAllTeamMembers);
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const isPrintReportOpen = useSelector(printReport);

  const { data, isFetching, error } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: assignedTeamMembersList,
    isLoading: isFetchingAssignedTeamMembers,
    error: assignedTeamMemberError,
    isSuccess,
    refetch: refetchAssignedTeamMembers,
  } = useGetAssignedStatusQuery(
    {
      checklist: planId,
      location: locationsWithAtLeastTrainer,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planId || isCompliance,
    },
  );

  const {
    data: plan,
    isLoading: isFetchingPlan,
    error: errorGettingPlan,
  } = useGetTrainingPlanQuery(
    {
      id: planId,
    },
    {
      skip: !planId || isCompliance,
    },
  );

  const {
    data: complianceStatus,
    isSuccess: isAssignedCoursesSuccess,
    refetch: refetchAssignedComplianceTeamMembers,
  } = useGetAssignedCoursesQuery(
    {
      courseId: planId,
      location: locationsWithAtLeastTrainer,
    },
    { skip: !planId || !isCompliance },
  );

  const { data: operators } = useGetOperatorsQuery();
  const operatorLocations = operators?.find(
    operator => operator.id === plan?.ownerId,
  )?.locations;

  const filterLocations = useMemo(() => {
    if (isCompliance) {
      return locationsWithAtLeastTrainer;
    } else {
      return (
        arrayIntersect(locationsWithAtLeastTrainer, operatorLocations ?? []) ??
        []
      );
    }
  }, [isCompliance, locationsWithAtLeastTrainer, operatorLocations]);

  const {
    data: statusReportData,
    isSuccess: isStatusReportSuccess,
    refetch: refetchCourseReportData,
  } = useGetCourseReportQuery(
    {
      courseId: planId,
      location: selectedReportLocations,
    },
    {
      skip: !isCompliance || !selectedReportLocations.length,
    },
  );

  const { data: assignedChecklist, refetch: refetchAssignedChecklist } =
    useGetAssignedChecklistByIdQuery(planId, {
      refetchOnMountOrArgChange: true,
      skip: !planId || isCompliance,
    });

  const handleCurrentFilteredLocations = () => {
    if (!currentFilters.length) {
      setSelectedReportLocations(filterLocations);
    } else {
      const currentFilteredLocations = currentFilters.filter(filter => {
        const isNumber = /^\d+$/.test(filter);
        return isNumber;
      });
      if (!currentFilteredLocations.length) {
        setSelectedReportLocations(filterLocations);
      } else {
        setSelectedReportLocations(currentFilteredLocations);
      }
    }
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  };

  useEffect(() => {
    handleCurrentFilteredLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, filterLocations]);

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  //filter team members for reports
  useEffect(() => {
    if (selectedReportLocations?.length) {
      const filteredTeamMembers = allTeamMembers?.filter(teamMember => {
        const { locations } = teamMember;
        const hasLocation = locations.some(location =>
          selectedReportLocations.includes(location),
        );
        return hasLocation;
      });
      setFilteredReportTeamMembers(filteredTeamMembers);
    } else {
      setFilteredReportTeamMembers(allTeamMembers);
    }
  }, [allTeamMembers, selectedReportLocations]);

  useEffect(() => {
    if (assignedChecklist) {
      const assignedChecklistStatus = !assignedChecklist?.status
        ?.filter(it => it.status !== Constants.TRAINING_PLANS.UNASSIGNED)
        .every(user => user.status === Constants.TRAINING_PLANS.COMPLETED);
      dispatch(setAssignedChecklistStatus(assignedChecklistStatus));
    }
  }, [assignedChecklist, dispatch]);

  useEffect(() => {
    if (plan?.name) {
      dispatch(setHeader(getNameFromLanguage(plan?.name)));
    }
    if (plan?.category) {
      dispatch(
        setHeaderLabel(t(mapCategoryToAliasTranslation(plan?.category))),
      );
    }
    if (isCompliance) {
      dispatch(setHeader(getNameFromLanguage(complianceStatus?.courseName)));
      dispatch(
        setHeaderLabel(
          t(
            mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
          ),
        ),
      );
    }
    if (plan?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          t('TrainingPlans.trainingMode.estimatedCompletionTime', {
            estimatedTime: generateTotalTime(
              plan?.estimatedMinutes,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          }),
        ),
      );
    }
    return () => {
      dispatch(setHeaderLabel(''));
      dispatch(setHeader(''));
      dispatch(setHeaderSubtext(''));
    };
  }, [complianceStatus, dispatch, isCompliance, plan, t]);

  useEffect(() => {
    if (isSuccess && !!assignedTeamMembersList && !!data) {
      setStepCount(assignedTeamMembersList.checklist.stepsTotal);
      dispatch(
        setTeamMembers({
          teamMembers: assignedTeamMembersList?.status
            ?.map(teamMember => {
              return {
                ...teamMember,
                completionDate: teamMember.completionDate ?? null,
                name: data?.find(({ adId }) => teamMember.userId === adId)
                  ?.name,
                locations: data?.find(({ adId }) => teamMember.userId === adId)
                  ?.locations,
              };
            })
            .filter(
              teamMember =>
                teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
            ),
        }),
      );
    }
  }, [assignedTeamMembersList, data, dispatch, isSuccess]);

  // Compliance plans
  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      dispatch(
        setTeamMembers({
          teamMembers: data
            ?.map(teamMember => {
              return {
                userId:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.userId ??
                  teamMember.adId,
                completedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.completedDate ?? null,
                courseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.courseId ??
                  '',
                dueDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.dueDate ??
                  null,
                duration:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.duration ??
                  null,
                enrolledDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrolledDate ?? '',
                enrollmentId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrollmentId ?? '',
                expirationDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.certificate
                    ?.expiration ?? '',
                finalScore:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.finalScore ?? 0,
                hidden:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.hidden ??
                  false,
                locations:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.locations ?? teamMember.locations,
                mostRecentCompletedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.mostRecentCompletedDate ?? null,
                name: teamMember.name,
                pathwayCourseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.pathwayCourseId ?? '',
                percentComplete:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.percentComplete ?? 0,
                startedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.startedDate ?? '',
                status:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.status ??
                  '',
              };
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  user => user.userId === value.userId && !user.hidden,
                ),
            ),
        }),
      );
    }
  }, [complianceStatus, data, dispatch, isAssignedCoursesSuccess]);

  useEffect(() => {
    // We can't call `refetchAssignedTeamMembers` in compliance plans because we don't call `useGetAssignedStatusQuery` on component mount
    if (isCompliance) {
      return;
    }
    refetchAssignedTeamMembers();
    // After we refetch the team members, we set the state back to false
    history.replace({
      state: { refetch: false },
      search: history.location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refetch, isCompliance]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  // Print Report
  useEffect(() => {
    if (isPrintReportOpen) {
      onPrintReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintReportOpen]);

  const getComplianceReportToPrint = () => {
    return [...statusReport?.userData]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE
            .NAME]: `${teamMember.firstName} ${teamMember.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: teamMember.birthDate
            ? teamMember.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            getCompletionDate(teamMember),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            teamMember?.certificate?.expiration &&
            teamMember?.courseStatus !==
              Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(teamMember.certificate.expiration)
              : t('Generic.na'),
        };
      });
  };

  const getStoreReportToPrint = () => {
    return [...filteredReportTeamMembers]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA.name.localeCompare(teamMemberB.name);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.userId,
            )?.stepsComplete || 0
          }/${stepCount}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              Constants.TRAINING_PLANS_STATUSES[teamMember.status]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
        };
      });
  };

  const tableHead = isCompliance
    ? [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETION_DATE,
          Constants.REPORT_TABLE.EXPIRATION_DATE,
        ],
      ]
    : [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETED,
          Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
          Constants.REPORT_TABLE.STATUS,
          Constants.REPORT_TABLE.COMPLETION_DATE,
        ],
      ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  const reportToGenerate = {
    category: isCompliance
      ? t(mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE))
      : t(mapCategoryToAliasTranslation(plan?.category ?? '')),
    foodSafetyImage: statusReport?.foodSafetyLetters?.[0]?.content,
    location: `${t('Generic.at')} ${
      selectedReportLocations?.length === 0
        ? filterLocations
        : !selectedReportLocations.length
        ? selectedReportLocations.toString()
        : arrayToCommaString(selectedReportLocations, t('Generic.and'))
    }`,
    name: isCompliance
      ? removeUnderscoreAndCapitalizeString(statusReport?.courseName ?? '')
      : getNameFromLanguage(plan?.name),
    reportToPrint: isCompliance
      ? getComplianceReportToPrint().map(teamMember =>
          tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
        )
      : getStoreReportToPrint().map(teamMember =>
          tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
        ),
    tableHead,
  };

  const messageToSend = {
    category: reportToGenerate.category,
    location: reportToGenerate.location,
    name: reportToGenerate.name,
    reportToPrint: isCompliance
      ? getComplianceReportToPrint()
      : getStoreReportToPrint(),
    tableHead,
  };

  // GET Team Members By Location Fetch error
  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  if (isApiError(errorGettingPlan)) {
    notifyBugsnag(errorGettingPlan);
    return <GenericError />;
  }

  // GET Assigned Team Members Fetch error
  if (isApiError(assignedTeamMemberError)) {
    notifyBugsnag(assignedTeamMemberError);
    return <GenericError />;
  }

  const onAddTeamMember = () => {
    setShowAddTeamMembersMenu(false);
    setShowAddTeamMembersPopup(true);
  };

  const onAssignTeamMembers = (members, dueDate) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };
    const numberOfTeamMembersToAssign = payload.userIds.length;
    assignUserToChecklist({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedTeamMembers();
        refetchAssignedChecklist();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
        setTriggerDueDateReset(prev => !prev);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowAddTeamMembersPopup(false);
  };

  const onAssignTeamMembersCompliance = (members, unassign) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), assignCourse: unassign ? false : true }
      : { userIds: [members], assignCourse: unassign ? false : true };

    const numberOfTeamMembersToAssign = payload.userIds.length;
    assignUserToComplianceCourse({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedComplianceTeamMembers();
        refetchCourseReportData();
        unassign
          ? toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {t('TrainingPlans.toastMessage.unassigned', {
                  teamMember: activeTeamMember.name,
                })}
              </ToastMessageBlock>
            ))
          : toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {numberOfTeamMembersToAssign === 1
                  ? t('TrainingPlans.toastMessage.assignedTeamMember')
                  : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                      count: numberOfTeamMembersToAssign,
                    })}
              </ToastMessageBlock>
            ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowUnassignTeamMemberPopup(false);
  };

  const onUnassign = (id, name) => {
    setActiveTeamMember({ id, name });
    setShowUnassignTeamMemberPopup(true);
  };

  const onCancelUnassignTeamMember = () => {
    setShowUnassignTeamMemberPopup(false);
    setActiveTeamMember({ id: '', name: '' });
  };

  const onUnassignTeamMember = () => {
    const payload = {
      userIds: [activeTeamMember.id],
    };

    isCompliance
      ? onAssignTeamMembersCompliance(activeTeamMember.id, true)
      : unassignUserToChecklist({ body: payload, id: planId })
          .unwrap()
          .then(() => {
            refetchAssignedTeamMembers();
            refetchAssignedChecklist();
            toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {t('TrainingPlans.toastMessage.unassigned', {
                  teamMember: activeTeamMember.name,
                })}
              </ToastMessageBlock>
            ));
          })
          .catch(err => {
            notifyBugsnag(err);
          });
    setShowUnassignTeamMemberPopup(false);
  };

  const onClose = () => {
    setSelectedId('');
    setShowAddTeamMembersMenu(false);
    setShowAddTeamMembersPopup(false);
    setShowDueDatePopup(false);
    setShowTrainingModePopup(false);
    setIsDueDateUpdate(false);
  };

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const handleNextClick = (isTrainingMode, selectedTeamMembers, id) => {
    /** We temporarily set these so they do not render until we send
     * the user to Training Mode below.
     */
    setTemporaryTeamMembers(selectedTeamMembers);
    if (isTrainingMode) {
      dispatch(hideTrainingMode());
      setShowSelectLanguagePopup(true);
    } else if (isCompliance) {
      setShowAddTeamMembersPopup(false);
      onAssignTeamMembersCompliance(id.includes(',') ? id.split(',') : id);
    } else {
      setShowAddTeamMembersPopup(false);
      setShowDueDatePopup(true);
      setSelectedId(id);
    }
  };

  const onNextClick = () => {
    setShowSelectLanguagePopup(false);
    dispatch(enterTrainingMode());
    dispatch(
      setTrainingModeData({
        estimatedMinutes: plan?.estimatedMinutes,
        languageSelected,
        planId,
        planLabel: t(mapCategoryToAliasTranslation(plan?.category)),
        planName: getNameFromLanguage(plan?.name),
        teamMembers: trainingModeTeamMembers,
        selectedTeamMembers: temporaryTeamMembers,
        assignedTeamMembersStatus: assignedTeamMembersList?.status?.filter(
          teamMember =>
            teamMember.status !== Constants.TRAINING_PLANS.COMPLETED,
        ),
      }),
    );
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`,
    });
  };

  const onBack = () => {
    setTrainingModeTeamMembers([]);
    setShowSelectLanguagePopup(false);
    setSelectedId('');
  };

  const onUpdateTeamMemberDueDate = (members, dueDate) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };

    updateDueDate({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedTeamMembers();
        refetchAssignedChecklist();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('TrainingPlans.toastMessage.updateDueDate', {
              teamMember: activeTeamMember.name,
            })}
          </ToastMessageBlock>
        ));
        onClose();
        setTriggerDueDateReset(prev => !prev);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowAddTeamMembersPopup(false);
  };

  const onSave = (dueDate, isUpdate) => {
    setShowDueDatePopup(false);
    if (isUpdate) {
      onUpdateTeamMemberDueDate(activeTeamMember.id, dueDate);
    } else {
      onAssignTeamMembers(
        selectedId.includes(',') ? selectedId.split(',') : selectedId,
        dueDate,
      );
      dispatch(setTeamMembers({ teamMembers: temporaryTeamMembers }));
    }
  };

  const onUpdateDueDate = (id, name) => {
    setActiveTeamMember({ id, name });
    setIsDueDateUpdate(true);
    setShowDueDatePopup(true);
  };

  const onEnterTrainingMode = () => {
    setShowTrainingModePopup(true);
    dispatch(hideTrainingMode());
  };

  const onPrintReport = () => {
    dispatch(hidePrintReport());
    filterLocations?.length > 1 || isFoodSafety
      ? setShowChooseReportLocationsPopup(true)
      : handlePrintReport();
  };

  const handlePrintReport = () => {
    if (!selectedReportLocations.length && !!filterLocations.length) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      handleCurrentFilteredLocations();
    }
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  return (
    <>
      <StyledContent>
        <LoadingOverlay
          isOpen={isFetching || isFetchingPlan || isFetchingAssignedTeamMembers}
        />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setTeamMembersSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              teamMembers?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
            top={Constants.HEIGHT.MOBILE_TOP_NAV}
          >
            {!!filterLocations && filterLocations?.length > 1 && (
              <CheckboxFilterSection
                labels={filterLocations.reduce(
                  (acc, loc) => ({
                    ...acc,
                    [loc]: {
                      translationString: loc,
                      value: !!currentFilters.includes(loc),
                    },
                  }),
                  {},
                )}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeTeamMembersFilter({ filter: value }));
                  } else {
                    dispatch(addTeamMembersLocationFilter({ filter: value }));
                  }
                }}
                title={t('Generic.locations')}
              />
            )}
            <CheckboxFilterSection
              labels={statusLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeTeamMembersFilter({ filter: value }));
                } else {
                  dispatch(addTeamMembersStatusFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlanCardList>
          {!isSmAndDown && (
            <StickyFilterCard>
              {!!filterLocations && filterLocations?.length > 1 && (
                <CheckboxFilterSection
                  labels={filterLocations.reduce(
                    (acc, loc) => ({
                      ...acc,
                      [loc]: {
                        translationString: loc,
                        value: !!currentFilters.includes(loc),
                      },
                    }),
                    {},
                  )}
                  onChange={value => {
                    if (!!currentFilters.includes(value)) {
                      dispatch(removeTeamMembersFilter({ filter: value }));
                    } else {
                      dispatch(addTeamMembersLocationFilter({ filter: value }));
                    }
                  }}
                  title={t('Generic.locations')}
                />
              )}
              <CheckboxFilterSection
                labels={statusLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeTeamMembersFilter({ filter: value }));
                  } else {
                    dispatch(addTeamMembersStatusFilter({ filter: value }));
                  }
                }}
                title={t('Generic.status')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setTeamMembersSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearTeamMembersCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              showCompletedPlansOption={false}
              showMyCompletedPlansOnlySwitchValue={false}
              text={`${total ?? 0} ${t('Generic.teamMembers')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />

            <ClearFiltersHeader
              aliases={statusLabels}
              clearAllFilters={() => {
                dispatch(clearTeamMembersCheckboxFilters());
              }}
              clearFilter={value => {
                dispatch(removeTeamMembersFilter({ filter: value }));
              }}
              filters={currentFilters}
            />
            <PlanCardsList>
              {!!teamMembers?.length && (
                <>
                  {teamMembers &&
                    teamMembers.map((member, index) => {
                      return (
                        <TeamMemberCard
                          completedStepCount={
                            assignedTeamMembersList?.status?.find(
                              userStatus => userStatus.userId === member.userId,
                            )?.stepsComplete || 0
                          }
                          completionDate={
                            isCompliance
                              ? member.completedDate
                              : member.completionDate
                          }
                          expirationDate={member.expirationDate}
                          finalScore={member?.finalScore}
                          id={member.userId ?? ''}
                          isComplete={
                            member?.status ===
                              Constants.TRAINING_PLANS.COMPLETED ||
                            member?.status ===
                              Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
                            member?.status ===
                              Constants.LEARN_UPON_TRAINING_PLANS.PASSED
                          }
                          isCompliance={isCompliance}
                          isFailed={
                            member?.status ===
                            Constants.LEARN_UPON_TRAINING_PLANS.FAILED
                          }
                          isFoodSafety={isFoodSafety}
                          key={index}
                          name={member.name ?? ''}
                          onRefetch={() => {
                            refetchAssignedTeamMembers();
                            refetchAssignedChecklist();
                          }}
                          onUnassign={(id, name) => onUnassign(id, name)}
                          onUpdateDueDate={(id, name) =>
                            onUpdateDueDate(id, name)
                          }
                          percentComplete={member?.percentComplete}
                          planId={planId}
                          planName={getNameFromLanguage(plan?.name) ?? ''}
                          selectedDueDate={new Date(member.dueDate).getTime()}
                          stepCount={stepCount}
                          timeSpentOnPlan={getTimeSpentOnPlan(
                            member,
                            isCompliance,
                          )}
                        />
                      );
                    })}
                  <LoadMorePaginator
                    isTeamMembers={true}
                    onClick={() => dispatch(loadMoreTeamMembers())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYTeamMembers', {
                      showing: showing ?? 0,
                      total: total ?? 0,
                    })}
                    total={total ?? 0}
                  />
                  <ConfirmationModal
                    bodyText={t(
                      'TrainingPlans.teamMembers.unassignConfirmation',
                      { name: activeTeamMember.name },
                    )}
                    headerText={t('Generic.unassign')}
                    isOpen={showUnassignTeamMemberPopup}
                    onClose={onCancelUnassignTeamMember}
                    primaryButtonColor="primary"
                    primaryButtonHandler={onUnassignTeamMember}
                    primaryButtonText={t('Button.unassign')}
                    secondaryButtonHandler={onCancelUnassignTeamMember}
                    secondaryButtonText={t('Button.cancel')}
                  />
                </>
              )}
              {!teamMembers?.length && !currentFilters?.length && (
                <NoMessage
                  message={
                    <Trans
                      i18nKey={'TrainingPlans.noTeamMembersAssignedManagePlans'}
                    />
                  }
                />
              )}
              {!!currentFilters?.length && !total && (
                <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
              )}
            </PlanCardsList>
          </PlanCardsContainer>
        </PlanCardList>
        <Fab
          hasMenu={true}
          isOpen={showAddTeamMembersMenu}
          onClick={() => setShowAddTeamMembersMenu(!showAddTeamMembersMenu)}
          onClose={() => setShowAddTeamMembersMenu(false)}
        >
          <FabPopup>
            <FabPopupItem
              data-testid="AddTeamMembersMenu"
              icon={<IconUserPlus />}
              onClick={onAddTeamMember}
              title={t('Generic.teamMembers')}
            />
          </FabPopup>
        </Fab>
        {plan && (
          <AddTeamMembersPopUp
            handleNextClick={({ isTrainingMode, selectedTeamMembers, id }) =>
              handleNextClick(isTrainingMode, selectedTeamMembers, id)
            }
            isOpen={showAddTeamMembersPopup}
            locations={filterLocations}
            onClose={onClose}
            planDetails={plan}
          />
        )}

        {isCompliance && (
          <AddTeamMembersPopUp
            handleNextClick={({ isTrainingMode, selectedTeamMembers, id }) =>
              handleNextClick(isTrainingMode, selectedTeamMembers, id)
            }
            isCompliance={isCompliance}
            isOpen={showAddTeamMembersPopup}
            locations={filterLocations}
            onClose={onClose}
            planDetails={allTeamMembers}
          />
        )}
        <DueDatePopUp
          isOpen={showDueDatePopup}
          onClose={onClose}
          onSave={date => onSave(date, isDueDateUpdate)}
          triggerDueDateReset={triggerDueDateReset}
        />

        <ConfirmationModal
          bodyText={t('TrainingPlans.trainingMode.enterTrainingModeModal')}
          headerText={t('Generic.trainingMode')}
          isOpen={trainingModeModalIsOpen}
          onClose={() => dispatch(hideTrainingMode())}
          primaryButtonColor={'secondary'}
          primaryButtonHandler={onEnterTrainingMode}
          primaryButtonText={t('Button.iUnderstand')}
          secondaryButtonHandler={() => dispatch(hideTrainingMode())}
          secondaryButtonText={t('Button.cancel')}
        />

        {plan && (
          <AddTeamMembersPopUp
            assignedTeamMembers={assignedTeamMembersList?.status}
            handleNextClick={({ isTrainingMode, selectedTeamMembers }) =>
              handleNextClick(isTrainingMode, selectedTeamMembers)
            }
            isOpen={showTrainingModePopup}
            isTrainingMode={true}
            locations={filterLocations}
            onClose={onClose}
            planDetails={plan}
          />
        )}
        <ConfirmationModal
          bodyText={t('TrainingPlans.trainingMode.selectLanguage')}
          children={
            <StyledLanguageWrapper>
              <RadioGroup
                defaultValue={t('Language.english')}
                orientation="vertical"
              >
                <RadioButton
                  label={t('Language.english')}
                  onClick={() => setLanguageSelected(t('Language.english'))}
                  value={t('Language.english')}
                />
                <RadioButton
                  label={t('Language.spanish')}
                  onClick={() => setLanguageSelected(t('Language.spanish'))}
                  value={t('Language.spanish')}
                />
              </RadioGroup>
            </StyledLanguageWrapper>
          }
          headerText={t('TrainingPlans.trainingMode.chooseLanguage')}
          isOpen={showSelectLanguagePopup}
          onClose={() => setShowSelectLanguagePopup(false)}
          primaryButtonColor={'secondary'}
          primaryButtonHandler={onNextClick}
          primaryButtonText={t('Button.next')}
          secondaryButtonHandler={onBack}
          secondaryButtonText={t('Button.back')}
        />
      </StyledContent>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          filterLocations.length > 1
            ? filterLocations.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={isFoodSafety}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;
const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;
const StyledAssignedTeamMembers = styled.div`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-weight: 500;
  margin-bottom: ${props => (props.inNavbar.inNavbar ? '0' : '10px')};
`;
const StyledNoMessageText = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-size: 16px;
`;
const StyledLanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default withRoles(
  ManagePlanView,
  [
    Constants.USER_PERMISSIONS.LEADER,
    Constants.USER_PERMISSIONS.OPERATOR,
    Constants.USER_PERMISSIONS.TRAINER,
  ],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
