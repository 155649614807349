import { Surface } from 'cfa-react-components';
import styled from 'styled-components';

export const BackToTopCircle = styled(Surface)`
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.primaryPalette.white}`};
  border-radius: 50% !important;
  box-shadow: ${({ theme }) => `${theme.boxShadow.elevation4} !important`};
  background: ${({ theme }) => theme.secondaryPalette.button.background};
  bottom: 16px;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 44px;
  z-index: 4;
`;
