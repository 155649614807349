import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { FC } from 'react';
import { IframeView } from '../common';

interface GameProps {
  document: Document;
}

/** Game document. */
const Game: FC<GameProps> = ({ document }) => (
  <IframeView url={document.contentUrl} />
);

export default Game;
