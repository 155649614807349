import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { createSlice } from '@reduxjs/toolkit';
// @ts-ignore
import orderBy from 'lodash/orderBy';
import { LanguageObject, PaginatedPlan } from '@/types';

interface ReportsPlan extends PaginatedPlan {
  dueDate: Date;
  pathwayCourseId: string;
  startedDate: Date;
  status: any;
}

const completedStatuses = [
  Constants.TRAINING_PLANS.COMPLETED,
  Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED,
  Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
];

const notCompletedStatuses = [
  Constants.TRAINING_PLANS.IN_PROGRESS,
  Constants.TRAINING_PLANS.NOT_COMPLETED,
  Constants.TRAINING_PLANS.NOT_STARTED,
  Constants.LEARN_UPON_TRAINING_PLANS.IN_PROGRESS,
  Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED,
  Constants.LEARN_UPON_TRAINING_PLANS.NOT_STARTED,
  Constants.LEARN_UPON_TRAINING_PLANS.FAILED,
  Constants.LEARN_UPON_TRAINING_PLANS.NULL,
];

const applyFilters = ({
  plans,
  categoryFilters,
  statusFilters,
  searchFilter,
}: {
  plans: ReportsPlan[];
  categoryFilters: string[];
  statusFilters: string[];
  searchFilter: string;
}) => {
  let filteredPlans = plans;
  if (statusFilters?.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const isCompliance = plan?.courseName;
      if (isCompliance) {
        return statusFilters.includes(plan?.status);
      } else {
        return statusFilters.includes(plan?.status?.[0]?.status);
      }
    });
  }

  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan.pathwayCourseId ? plan : plan.checklist;
      const hasCategory =
        // Compliance plans do not have a category so we check for undefined
        (category === undefined &&
          categoryFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE)) ||
        categoryFilters.includes(category);
      return hasCategory;
    });
  }

  if (searchFilter) {
    filteredPlans = filteredPlans?.filter(plan => {
      return getNameFromLanguage(
        plan.checklist ? plan.checklist.name : plan.courseName,
      )
        .toLowerCase()
        .includes(searchFilter.toLowerCase());
    });
  }
  return filteredPlans;
};

const applyPagination = (
  plans: ReportsPlan[],
  page: number,
  pageSize: number,
) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

const applySort = (plans: ReportsPlan[], sort: string) => {
  let sortedPlans = plans;
  const liftedPlans = plans.map(plan => {
    // Non Compliance Plans
    if (plan.checklist) {
      return {
        ...plan,
        created: plan.checklist.created,
        dueDate: new Date(plan.status[0].dueDate).getTime() ?? 0,
        name: getNameFromLanguage(plan.checklist.name),
      };
      // Compliance Plans
    } else {
      return {
        ...plan,
        created: plan.startedDate,
        // There is currently no due date in Compliance Plans
        dueDate: null,
        name: getNameFromLanguage(plan.courseName as LanguageObject),
      };
    }
  });

  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = (liftedPlans as any).toSorted((a: any, b: any) => {
        if (typeof a.name === 'object') {
          return getNameFromLanguage(a.name)
            .toLowerCase()
            .localeCompare(getNameFromLanguage(b.name)?.toLowerCase());
        } else {
          return a.name.toLowerCase().localeCompare(b.name?.toLowerCase());
        }
      });
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = (liftedPlans as any).toSorted((a: any, b: any) => {
        if (typeof b.name === 'object') {
          return getNameFromLanguage(b.name)
            .toLowerCase()
            .localeCompare(getNameFromLanguage(a.name)?.toLowerCase());
        } else {
          return b.name.toLowerCase().localeCompare(a.name?.toLowerCase());
        }
      });
      break;
    case Constants.PLANS_SORT_OPTIONS.NEWEST:
      sortedPlans = (liftedPlans as any).toSorted(
        (a: any, b: any) =>
          new Date(b.created).getTime() - new Date(a.created).getTime(),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.OLDEST:
      sortedPlans = (liftedPlans as any).toSorted(
        (a: any, b: any) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.DUE_DATE:
      const currentDate = new Date().getTime();
      // @ts-ignore
      sortedPlans = orderBy(
        liftedPlans,
        [
          (plan: ReportsPlan) =>
            plan.dueDate && new Date(plan.dueDate).getTime() < currentDate
              ? -1
              : 1,
          (plan: ReportsPlan) =>
            plan.dueDate && new Date(plan.dueDate).getTime() > currentDate
              ? 1
              : 0,
        ],
        ['asc', 'desc'],
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans;
};

export const slice = createSlice({
  name: 'plansFilter',
  initialState: {
    plans: [],
    categoryFilters: [],
    statusFilters: [],
    sort: Constants.PLANS_SORT_OPTIONS.A2Z,
    filteredPlans: [],
    searchFilter: '',
    sortedPlans: [],
    paginatedPlans: [],
    page: 1,
    pageSize: 12,
    showing: 0,
    total: 0,
  },
  reducers: {
    clearPlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearPlansCategoryFilter: (state, action) => {
      const { filter } = action.payload;
      if ((state.categoryFilters as string[]).includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearPlansStatusFilter: (state, action) => {
      let newFilters: string[] = [];
      if (action.payload.filter === Constants.TRAINING_PLANS.COMPLETED) {
        newFilters = completedStatuses;
      }
      if (action.payload.filter === Constants.TRAINING_PLANS.NOT_COMPLETED) {
        newFilters = notCompletedStatuses as string[];
      }
      state.statusFilters = state.statusFilters.filter(
        status => !newFilters.includes(status),
      );

      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setPlans: (state, action) => {
      state.plans = action.payload.plans ?? [];
      (state.sortedPlans as ReportsPlan[]) = applySort(state.plans, state.sort);
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setPlansCategoryFilter: (state, action) => {
      (state.categoryFilters as string[]) = [
        ...state.categoryFilters,
        action.payload.filter,
      ];
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setPlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setPlansSort: (state, action) => {
      state.sort = action.payload.sort;
      (state.sortedPlans as ReportsPlan[]) = applySort(state.plans, state.sort);
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setPlansStatusFilter: (state, action) => {
      let newFilters: string[] = [];
      if (action.payload.filter === Constants.TRAINING_PLANS.COMPLETED) {
        newFilters = completedStatuses;
      }
      if (action.payload.filter === Constants.TRAINING_PLANS.NOT_COMPLETED) {
        newFilters = notCompletedStatuses as string[];
      }
      (state.statusFilters as string[]) = [
        ...state.statusFilters,
        ...newFilters,
      ];
      (state.filteredPlans as ReportsPlan[]) = applyFilters({
        plans: state.sortedPlans,
        categoryFilters: state.categoryFilters,
        statusFilters: state.statusFilters,
        searchFilter: state.searchFilter,
      });
      ({
        loadedPlans: state.paginatedPlans as ReportsPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearPlansCategoryFilter,
  clearPlansSearchFilter,
  clearPlansStatusFilter,
  loadMorePlans,
  setPlans,
  setPlansCategoryFilter,
  setPlansSearchFilter,
  setPlansSort,
  setPlansStatusFilter,
} = slice.actions;

export default slice.reducer;
