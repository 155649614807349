import { createSelector } from 'reselect';
import { RootState } from '..';

export const licenseeLocationsFilterState = (state: RootState) =>
  state.licenseeLocationsFilter;

export const selectSort = createSelector(
  licenseeLocationsFilterState,
  state => state.sort,
);

export const selectSortedAndFilteredAndPaginatedLicenseeLocations =
  createSelector(
    licenseeLocationsFilterState,
    state => state.paginatedLicenseeLocations,
  );

export const selectPagination = createSelector(
  licenseeLocationsFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectAllLicenseeLocations = createSelector(
  licenseeLocationsFilterState,
  state => state.licenseeLocations,
);

export const selectSearchFilter = createSelector(
  licenseeLocationsFilterState,
  state => state.searchFilters,
);
