import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField, Typography } from 'cfa-react-components';
import { OperatorDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { StepWizardChildProps } from 'react-step-wizard';
import StepWizardPopUp from '@/sharedComponents/app/popups/StepWizardPopUp';
import RadioButtonList from '@/sharedComponents/app/RadioButtonList';

interface AddPlanWithOperatorsProps {
  isLicenseeUser: boolean;
  open: boolean;
  operators: OperatorDTO[];
  planName?: string;
  setOpen: (isOpen: boolean) => void;
  submitHandler: ({
    assignedOperator,
    name,
  }: {
    assignedOperator: OperatorDTO;
    name: string;
  }) => void;
}

const AddPlanWithOperators: React.FC<AddPlanWithOperatorsProps> = ({
  isLicenseeUser,
  open,
  operators,
  planName,
  setOpen,
  submitHandler,
}) => {
  const isMultiOperator = operators.length > 1 && !isLicenseeUser;
  const initialStep = isMultiOperator ? 1 : 2;
  const [operator, setOperator] = useState<OperatorDTO>();
  const [operatorSelected, setOperatorSelected] = useState(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] =
    useState<StepWizardChildProps<{ state: Record<string, any> }>>();
  const [input, setInput] = useState('');
  const operatorsNameArray = operators.flat().map(({ name }) => name);

  useEffect(() => {
    if (operators.length) {
      if (operators.length === 1) {
        setOperator(operators[0]);
      }
    }
  }, [operators]);

  useEffect(() => {
    if (isMultiOperator) {
      setOperator({});
    }
    setStepIndex(initialStep);
    setOperatorSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStep, open]);

  useEffect(() => {
    // we update the stepIndex anytime the initialStep is changed
    setStepIndex(initialStep);
  }, [initialStep]);

  const closePopup = () => {
    if (isMultiOperator) {
      setOperator({});
    }
    setStepIndex(isMultiOperator ? 1 : 2);
    setOperatorSelected(false);
    setInput(planName as string);
    setOpen(false);
  };

  const selectOperator = (operatorName: string) => {
    const selectedOperator = operators.find(
      ({ name }) => name === operatorName,
    );
    setOperator(selectedOperator);
    setOperatorSelected(true);
  };

  const Step1 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.addPlanMenu.chooseOperator')}
      </DialogHeader>
      <RadioButtonList
        defaultValue={''}
        listItems={operatorsNameArray as string[]}
        name={'operatorList'}
        setValue={selectOperator}
      />
    </>
  );
  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('Generic.giveName', { object: t('Generic.plan') })}
      </DialogHeader>
      <TextField
        autoFocus
        fullWidth
        name={'input'}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' &&
          submitHandler({
            assignedOperator: operator as OperatorDTO,
            name: input as string,
          })
        }
        placeholder={t('TrainingPlans.planNamePlaceholder')}
        value={input}
      />
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
      },
      onClickNext: () => {
        stepWizard && stepWizard.nextStep();
      },
      isDisabledButtonNext: !operatorSelected,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: isMultiOperator ? t('Button.back') : t('Button.cancel'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        setInput(planName as string);
        isMultiOperator && stepWizard ? stepWizard.firstStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({
          assignedOperator: operator as OperatorDTO,
          name: input as string,
        });
      },
      isDisabledButtonNext: !input,
      ContentData: Step2,
    },
  };

  // @ts-ignore
  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={open}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;

export default AddPlanWithOperators;
