import { createSelector } from 'reselect';
import { RootState } from '..';

export const quizzesState = (state: RootState) => state.quizzes;

export const selectPaginatedQuizzes = createSelector(
  quizzesState,
  state => state.paginatedQuizzes,
);

export const selectPagination = createSelector(quizzesState, state => ({
  showing: state.showing,
  total: state.total,
}));
