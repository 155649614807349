import { device } from 'util/device';
import StepWizard from 'react-step-wizard';
import styled from 'styled-components';
import {
  Modal,
  ModalFooter,
  Button,
  ModalBody,
  ModalHeader,
} from 'cfa-react-components';
import React from 'react';

interface StepWizardPopUpProps {
  setStepIndex: (step: number) => void;
  initialStep: number;
  open: boolean;
  closePopup: () => void;
  step: {
    isDisabledButtonNext: boolean;
    labelButtonDeny: string;
    labelButtonNext: string;
    onClickBack: () => void;
    onClickNext: () => void;
  };
  setStepWizard: React.Dispatch<React.SetStateAction<any>>;
  steps: any;
  dataTestId?: string;
  className?: string;
}

const StepWizardPopUp: React.FC<StepWizardPopUpProps> = ({
  setStepIndex,
  initialStep = 1,
  open,
  closePopup,
  step,
  setStepWizard,
  steps,
  dataTestId = 'StepWizardPopUp',
  className,
}) => {
  return (
    <Modal
      className={className}
      data-testid={dataTestId}
      onClose={closePopup}
      show={open}
      size="md"
    >
      {/** @ts-ignore */}
      <ModalHeader></ModalHeader>
      <StepWizard
        initialStep={initialStep}
        instance={setStepWizard}
        onStepChange={({ activeStep }) => setStepIndex(activeStep)}
        transitions={{
          enterRight: '',
          enterLeft: '',
          exitRight: '',
          exitLeft: '',
        }}
      >
        {Object.keys(steps).map((key, idx) => (
          <React.Fragment key={idx}>
            <ModalBody>{steps[key].ContentData()}</ModalBody>
          </React.Fragment>
        ))}
      </StepWizard>
      <ModalFooter>
        <DialogButton
          color="secondary"
          data-testid="PrimaryConfirmButton"
          onClick={step.onClickBack}
          variant="outlined"
        >
          {step.labelButtonDeny}
        </DialogButton>
        <DialogButton
          color="secondary"
          data-testid="SecondaryConfirmButton"
          disabled={step.isDisabledButtonNext}
          onClick={step.onClickNext}
          variant="filled"
        >
          {step.labelButtonNext}
        </DialogButton>
      </ModalFooter>
    </Modal>
  );
};

export default StepWizardPopUp;

const DialogButton = styled(Button)`
  @media ${device.desktop} {
    flex: 1;
  }
`;
