import {
  IconChevronLeft,
  IconLanguage,
  IconPrinter,
  IconShare2,
} from '@tabler/icons-react';
import { Tooltip, Typography } from 'cfa-react-components';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';
import { selectSearchTerms } from '@/store/search/selectors';
import {
  setIsPrinting,
  translationButtonPress,
} from '@/store/document-transient/slice';
import ToastMessageBlock from '@/sharedComponents/app/Toasts/SuccessToast';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectDocumentName,
  selectPersistDocumentState,
} from '@/store/document-persisted/selectors';

const TridionDocumentSubheader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const searchTerm = useAppSelector(selectSearchTerms)?.[0];
  const {
    hasTranslatedValue: showTranslateIcon,
    routedFrom,
    categoryName,
    backUrl,
  } = useAppSelector(selectPersistDocumentState);
  const tridionDocName = useAppSelector(selectDocumentName);

  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Generic.linkCopied')}
      </ToastMessageBlock>
    ));
  };

  const isSearch = routedFrom === 'search';

  const onBack = () => {
    if (routedFrom === 'link' && !isSearch) {
      history.push('/'); // explore
    } else if (!categoryName && searchTerm) {
      history.push(`/search?query=${searchTerm}`); // search
    } else if (backUrl) {
      history.push(backUrl); // url when browse from category
    } else {
      history.goBack();
    }
  };

  return (
    <SubheaderContainer>
      <BackContainer onClick={onBack}>
        <IconChevronLeft style={{ marginLeft: 16, marginRight: 8 }} />
        <ActionText>
          {routedFrom === 'link' && !isSearch
            ? t('Generic.explore')
            : categoryName || t('Generic.search')}
        </ActionText>
      </BackContainer>
      <DocumentName>{tridionDocName}</DocumentName>
      <ActionContainer>
        {showTranslateIcon ? (
          <TranslateIcon onClick={() => dispatch(translationButtonPress())} />
        ) : (
          <Tooltip
            content={t('Generic.noTranslationsAvailable')}
            placement="top"
            showOnElementEvents={['hover']}
          >
            <TranslateIcon
              style={{ color: theme.grayScale.gray3, cursor: 'default' }}
            />
          </Tooltip>
        )}
        <IconPrinter
          onClick={() => dispatch(setIsPrinting(true))}
          style={{ marginRight: 12 }}
        />
        <IconShare2 onClick={onCopyLink} style={{ marginRight: 24 }} />
      </ActionContainer>
    </SubheaderContainer>
  );
};

const SubheaderContainer = styled.div`
  box-shadow: ${() => theme.boxShadow.elevation6};
  color: ${() => theme.primaryPalette.navyBlue};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
`;

const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const DocumentName = styled(Typography)`
  color: ${() => theme.primaryPalette.navyBlue};
  font-weight: 700;
`;

const ActionText = styled(Typography)`
  color: ${() => theme.primaryPalette.navyBlue};
`;

const ActionContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const TranslateIcon = styled(IconLanguage)`
  margin-right: 12px;
`;

export default TridionDocumentSubheader;
