import { createSelector } from 'reselect';
import { RootState } from '..';

export const fileNotFoundReducerState = (state: RootState) =>
  state.fileNotFound;

export const selectIsFileNotFound = createSelector(
  fileNotFoundReducerState,
  reducerState => reducerState.isFileNotFound,
);
