import { RootState } from '..';

export const selectTransitentDocumentState = (state: RootState) =>
  state.documentTransient;

export const selectIsPrinting = (state: RootState) =>
  state.documentTransient.isPrinting;

export const selectIsTranslating = (state: RootState) =>
  state.documentTransient.isTranslating;
