import { createSelector } from 'reselect';
import { RootState } from '..';

export const adminState = (state: RootState) => state.admin;

export const managingCountry = createSelector(
  adminState,
  state => state.managingCountry,
);

export const supportedCountries = createSelector(
  adminState,
  state => state.supportedCountries,
);

export const selectSelectedAdminCategoryId = createSelector(
  adminState,
  state => state.selectedCategoryId,
);

export const selectSelectedAdminCategoryIndex = createSelector(
  adminState,
  state => state.selectedCategoryIndex,
);

export const selectSelectedAdminCategory = createSelector(
  adminState,
  state => state.selectedCategory,
);

export const selectIsLoadingAfterEdit = createSelector(
  adminState,
  state => state.isLoadingAfterEdit,
);

export const selectIsRecommendCategory = createSelector(
  adminState,
  state => state.isRecommendCategory,
);

export const selectTemporaryCategory = createSelector(
  adminState,
  state => state.temporaryCategory,
);

export const selectShowAddAdminAddProcedureToSubtitle = createSelector(
  adminState,
  state => state.showAddAdminAddProcedureToSubtitle,
);

export const selectSelectedAdminSubcategoryIndex = createSelector(
  adminState,
  state => state.selectedSubcategoryIndex,
);

export const selectSelectedAdminSubtitleIndex = createSelector(
  adminState,
  state => state.selectedSubtitleIndex,
);

export const selectDocumentForVisibilityToggle = createSelector(
  adminState,
  state => state.documentForToggleVisibility,
);

export const selectDocumentVisibilityFlagForConfirmationPopup = createSelector(
  adminState,
  state => state.documentVisibilityForConfirmationPopup,
);

export const selectDocumentForDeletion = createSelector(
  adminState,
  state => state.documentForDeletion,
);

export const selectTriggerCategoryRefetch = createSelector(
  adminState,
  state => state.triggerCategoryRefetch,
);
