import { useGetDocumentCookieQuery } from '@/services/pathwayApi';
import { useSetAuthCookieQuery } from '@/services/xpApi';
import Constants from '@/constants';

const useDocumentCookieRefresh = (skip = false) => {
  const {
    isSuccess: contentApiSuccess,
    isFetching: contentApiFetching,
    error: contentApiError,
  } = useGetDocumentCookieQuery(undefined, {
    pollingInterval: Constants.DOCUMENT_COOKIE_REFRESH.POLL_TIME_DEFAULT,
    skip: skip,
  });
  const {
    isSuccess: xpApiSuccess,
    isFetching: xpApiFetching,
    error: xpApiError,
  } = useSetAuthCookieQuery(undefined, {
    pollingInterval: Constants.DOCUMENT_COOKIE_REFRESH.POLL_TIME_DEFAULT,
    skip: skip,
  });

  // return isFetching and error from hook
  return {
    isFetching: contentApiFetching || xpApiFetching,
    isSuccess: contentApiSuccess && xpApiSuccess,
    error: contentApiError || xpApiError,
  };
};

export default useDocumentCookieRefresh;
