/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Surface, TextField, TextFieldType } from 'cfa-react-components';
import { IconX, IconSearch } from '@tabler/icons-react';
import React, { forwardRef, useRef, useState } from 'react';
// @ts-ignore
import { useClickOutside } from 'hooks/useClickOutside';
import { removeSearchTerm, saveSearchTerm } from 'store/search/slice';
import { useTranslation } from 'react-i18next';
import SearchbarSuggestionsDropdown from './SearchbarSuggestionsDropdown';
import { selectSearchTerms } from '@/store/search/selectors';

type SearchbarProps = {
  searchValue?: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<TextFieldType>) => void;
  onSubmit?: (...args: any[]) => any;
  onClear: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  showRecentSearchDropdown?: boolean;
  elevation?: number;
  fullWidth?: boolean;
  'data-testid'?: string;
  className?: string;
  ref?: any;
};

const Searchbar: React.FC<SearchbarProps> = forwardRef(
  (
    {
      searchValue,
      placeholder,
      onChange,
      onSubmit,
      onClear,
      onFocus,
      onBlur,
      showRecentSearchDropdown,
      elevation,
      fullWidth,
      'data-testid': DataTestId,
      className,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pastSearchTerms = useSelector(selectSearchTerms);
    const searchInputDropDownRef = useRef<HTMLElement>(null);
    const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);
    const onFocusSearchInput = () => {
      if (showRecentSearchDropdown) {
        setSuggestionsIsOpen(true);
      }
      onFocus?.();
    };
    const onBlurSearchInput = () => {
      if (showRecentSearchDropdown) {
        setSuggestionsIsOpen(false);
      }
      onBlur?.();
    };
    const onSubmitSearch: React.FormEventHandler<HTMLFormElement> = e => {
      e.preventDefault();
      if (searchValue?.trim() === '') {
        return;
      }
      if (showRecentSearchDropdown) {
        dispatch(saveSearchTerm(searchValue));
        setSuggestionsIsOpen(false);
      }
      onSubmit?.();
    };
    const onClickSuggestion = (suggestion: string) => {
      if (showRecentSearchDropdown) {
        dispatch(saveSearchTerm(suggestion));
        setSuggestionsIsOpen(false);
      }
      onSubmit?.(suggestion);
    };
    const onClickRemoveSuggestion = (suggestion: string) => {
      if (showRecentSearchDropdown) {
        dispatch(removeSearchTerm(suggestion));
      }
    };
    useClickOutside(searchInputDropDownRef, onBlurSearchInput);
    return (
      <SearchbarWrapper
        className={className}
        data-testid={DataTestId}
        elevation={elevation}
        ref={searchInputDropDownRef}
      >
        <SearchForm onSubmit={onSubmitSearch}>
          <SearchTextInput
            data-testid="Search"
            fullWidth={fullWidth}
            onChange={onChange}
            onClick={onFocusSearchInput}
            placeholder={placeholder || t('Search.searchForResources')}
            ref={ref}
            textAlign="start"
            value={searchValue}
          />
          <FormButton type="submit">
            <SearchIcon
              $isInFocus={!!searchValue}
              data-testid="SearchMagnifyButton"
            />
          </FormButton>
          {searchValue && (
            <ClearIcon data-testid="ClearSearchButton" onClick={onClear} />
          )}
        </SearchForm>
        {suggestionsIsOpen && (
          <SearchbarSuggestionsDropdown
            onRemoveSuggestion={onClickRemoveSuggestion}
            onSetSuggestion={onClickSuggestion}
            suggestions={pastSearchTerms}
          />
        )}
      </SearchbarWrapper>
    );
  },
);
Searchbar.defaultProps = {
  searchValue: '',
  placeholder: '',
  onSubmit: () => {},
  onFocus: () => {},
  onBlur: () => {},
  showRecentSearchDropdown: false,
  elevation: 0,
  fullWidth: false,
  'data-testid': 'Searchbar',
  className: '',
};
const SearchbarWrapper = styled(Surface)`
  position: relative;
  overflow: visible;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;
const SearchForm = styled.form`
  position: relative;
`;
const SearchTextInput = styled(TextField)<{ ref: any }>`
  & input {
    padding-right: 80px;
  }
`;
const SearchIcon = styled(IconSearch)<{ $isInFocus: boolean }>`
  height: 18px;
  width: 18px;
  color: ${({ theme, $isInFocus }) =>
    $isInFocus ? theme.grayScale.gray6 : theme.grayScale.gray3};
`;
const ClearIcon = styled(IconX)`
  height: 18px;
  width: 18px;
  position: absolute;
  right: 48px;
  top: 14px;
  cursor: pointer;
`;
const FormButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 0;
  line-height: 0;
`;
export default Searchbar;
