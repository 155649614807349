import Constants from 'constants/index';
import {
  isTrainingBuildPath,
  isTrainingMyPlansPath,
  isTrainingQuizzesPath,
  isTrainingTeamPath,
} from 'util/url';
// @ts-ignore until this file is converted in a separate ticket (not created yet)
import { getHighestUserPermission } from 'util/user';
import styled from 'styled-components';
import { TabItem, Tabs } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from 'store/user/selectors';

const TrainingTabs = {
  MYPLANS: 'myplans',
  BUILDPLANS: 'buildplans',
  MANAGEPLANS: 'manageplans',
  BUILDQUIZZES: 'buildquizzes',
};

const TrainingSubheader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userPermissions = useSelector(selectUserPermissions);
  const highestUserPermission = getHighestUserPermission(userPermissions);
  const userIsAtLeastLeader =
    Constants.USER_PERMISSION_NAMES.LEADER === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.OPERATOR === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.STAKEHOLDER === highestUserPermission ||
    Constants.USER_PERMISSION_NAMES.ADMIN === highestUserPermission;
  let activeItemKey = '';

  if (isTrainingBuildPath(location)) {
    activeItemKey = TrainingTabs.BUILDPLANS;
  }
  if (isTrainingMyPlansPath(location)) {
    activeItemKey = TrainingTabs.MYPLANS;
  }
  if (isTrainingTeamPath(location)) {
    activeItemKey = TrainingTabs.MANAGEPLANS;
  }
  if (isTrainingQuizzesPath(location)) {
    activeItemKey = TrainingTabs.BUILDQUIZZES;
  }

  return (
    <Tabs activeItemKey={activeItemKey} color="secondary" onChange={() => {}}>
      {userIsAtLeastLeader && (
        <HeaderTabItem itemKey={TrainingTabs.BUILDPLANS}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/build`}
          >
            {t('TrainingPlans.tabBuild')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      <HeaderTabItem itemKey={TrainingTabs.MYPLANS}>
        <HeaderLink to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}>
          {t('TrainingPlans.tabMy')}
        </HeaderLink>
      </HeaderTabItem>
      <HeaderTabItem itemKey={TrainingTabs.MANAGEPLANS}>
        <HeaderLink
          to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          {t('TrainingPlans.tabManagePlans')}
        </HeaderLink>
      </HeaderTabItem>
      {userIsAtLeastLeader && (
        <HeaderTabItem itemKey={TrainingTabs.BUILDQUIZZES}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
          >
            {t('TrainingPlans.tabBuildQuizzes')}
          </HeaderLink>
        </HeaderTabItem>
      )}
    </Tabs>
  );
};

const HeaderTabItem = styled(TabItem)`
  & > button {
    padding: 0;
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

const HeaderLink = styled(Link)`
  padding: 16px 32px;
`;

export default TrainingSubheader;
