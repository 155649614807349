import Constants from 'constants';
import { isApiError } from 'util/request';
import { Button } from 'cfa-react-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useUpdateChecklistStepStatusMutation } from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import { useTranslation } from 'react-i18next';
import useBugsnagNotify from 'hooks/useBugsnagNotify';

export interface LocationProps {
  state: {
    planId: string;
    step: {
      id: string;
    };
    // TODO: identify proper type
    userIds: any;
  };
}

const DocumentCompleteButton = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [updateChecklistStepStatus] = useUpdateChecklistStepStatusMutation();

  const onCompleteButtonClick = () => {
    updateChecklistStepStatus({
      checklistId: (location as LocationProps).state.planId,
      stepId: (location as LocationProps).state.step.id,
      // @ts-ignore TODO: Figure out how to add types to Constants
      status: Constants.TRAINING_PLANS.COMPLETE,
      userIds: (location as LocationProps).state.userIds,
    })
      .unwrap()
      .then(() => {
        !(location as LocationProps).state.userIds
          ? history.push({
              // @ts-ignore TODO: Figure out how to add types to Constants
              pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${location.state.planId}`,
            })
          : history.goBack();
      })
      .catch(error => {
        if (isApiError(error)) {
          notifyBugsnag(error);
          return <GenericError />;
        }
        notifyBugsnag(error);
      });
  };

  return (
    <Button color="secondary" onClick={onCompleteButtonClick}>
      {t('Button.complete')}
    </Button>
  );
};

export default DocumentCompleteButton;
