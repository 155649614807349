import { useCallback, useEffect, useMemo, useState } from 'react';
import Constants from '@/constants';
import { useAppSelector } from '@/hooks';
import { useUpdateChecklistStepHeartbeatMutation } from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { selectUserId } from '@/store/user/selectors';

export const useTrainingPlanTimer = (
  planId: string,
  stepId: string,
  userIds: string[],
  stepStatus: string,
) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const [updateChecklistStepHeartbeat] =
    useUpdateChecklistStepHeartbeatMutation();
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [roundUpTimer, setRoundUpTimer] = useState(false);

  const userId = useAppSelector(selectUserId);

  const userIdsForHeartbeat = useMemo(() => {
    return userIds.length === 1 && userIds[0] === userId ? undefined : userIds;
  }, [userId, userIds]);

  const logTimeViewed = useCallback(
    (milliSeconds: number) => {
      const seconds = milliSeconds / 1000;
      updateChecklistStepHeartbeat({
        checklistId: planId,
        stepId: stepId,
        interval: seconds,
        userIds: userIdsForHeartbeat
          ? { userIds: userIdsForHeartbeat }
          : undefined,
      })
        .unwrap()
        .then()
        .catch(err => {
          notifyBugsnag(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planId, stepId, stepStatus],
  );

  useEffect(() => {
    let logIntervalId: NodeJS.Timeout | null = null;
    let roundIntervalId: NodeJS.Timeout | null = null;

    if (timerIsRunning && stepStatus) {
      logIntervalId = setInterval(() => {
        setRoundUpTimer(prevState => !prevState);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_ROUNDUP);
      roundIntervalId = setInterval(() => {
        logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      }, Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
    }
    return () => {
      if (logIntervalId) {
        clearInterval(logIntervalId);
      }
      if (roundIntervalId) {
        clearInterval(roundIntervalId);
      }
    };
  }, [logTimeViewed, stepStatus, timerIsRunning]);

  useEffect(() => {
    if (
      roundUpTimer &&
      !timerIsRunning &&
      stepId &&
      planId &&
      userIds &&
      stepStatus
    ) {
      logTimeViewed(Constants.VIEW_TIME_DEFAULTS.POLL_TIME_DEFAULT);
      setRoundUpTimer(false);
    }
  }, [
    logTimeViewed,
    planId,
    roundUpTimer,
    stepId,
    stepStatus,
    timerIsRunning,
    userIds,
  ]);

  useEffect(() => {
    return () => {
      setTimerIsRunning(false);
    };
  }, []);

  const startTimer = useCallback(() => {
    setTimerIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setTimerIsRunning(false);
  }, []);

  return { startTimer, stopTimer };
};
