import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'cfa-react-components';

interface EditButtonsProps {
  onCancel: () => void;
  onEdit: () => void;
  primaryButtonText?: string;
  validated?: boolean;
}

const EditButtons: React.FC<EditButtonsProps> = ({
  onCancel,
  onEdit,
  validated,
  primaryButtonText,
}) => {
  const { t } = useTranslation();
  return (
    <InlineEditButtons>
      <NoMinWidthButton color="secondary" onClick={onCancel} variant="outlined">
        {t('Button.cancel')}
      </NoMinWidthButton>
      <NoMinWidthButton
        color="secondary"
        data-testid="EditButtonPrimary"
        disabled={!validated}
        onClick={onEdit}
        variant="filled"
      >
        {primaryButtonText ? primaryButtonText : t('Button.update')}
      </NoMinWidthButton>
    </InlineEditButtons>
  );
};

const NoMinWidthButton = styled(Button)`
  min-width: 0 !important;
`;
const InlineEditButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
`;
export default EditButtons;
