import Constants from 'constants/index';
import { useDeviceInfo } from 'util/device';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IconChartBar,
  IconNotes,
  IconSettings,
  IconReportSearch,
  IconTool,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserPermissions,
  selectUserCountry,
  selectUserFeatureFlags,
} from 'store/user/selectors';
import { useIsNative } from 'hooks/useIsNative';
import { IconAscend } from 'icons';
import styled from 'styled-components';
import { selectShouldShowMobileNavigationMenu } from 'store/native/selector';
import { selectIsTrainingMode } from 'store/trainingMode/selectors';
import { useEffect } from 'react';
import { setNativeOptions } from 'store/native/slice';
import { clearDocument, setDocument } from 'store/document-persisted/slice';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import { Features } from '@/store/user/slice';

interface NavbarProps {
  children: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({ children }) => {
  const { isDesktop } = useDeviceInfo();
  const { t } = useTranslation();
  const location = useLocation();
  const isNative = useIsNative();
  const dispatch = useDispatch();
  const isTrainingMode = useSelector(selectIsTrainingMode);
  const userFeatureFlags: Features = useSelector(selectUserFeatureFlags);
  const { ascendNav: featureFlagAscendNav, xpApi: xpApiFeatureFlag } =
    useFlags();

  /** Begin important Native Options code **/
  const androidString = window.injectedObject;
  const nativeString = window.nativeOptions;

  useEffect(() => {
    let androidNativeOptions = '';
    let appleNativeOptions = '';
    if (androidString) {
      androidNativeOptions = JSON.parse(androidString.toString()).nativeOptions;
    } else if (nativeString) {
      appleNativeOptions = JSON.parse(nativeString.toString());
    }
    if (appleNativeOptions || androidNativeOptions) {
      dispatch(setNativeOptions(appleNativeOptions || androidNativeOptions));
    } else if (isNative) {
      const defaultNativeMenuOptions = Constants.DEFAULT_NATIVE_MENU_OPTIONS;
      dispatch(setNativeOptions(defaultNativeMenuOptions));
    }
  }, [androidString, dispatch, isNative, nativeString]);
  /** End important Native Options code **/

  useEffect(() => {
    dispatch(setDocument({ routedFrom: 'link', document: null }));
    return () => {
      const docPath = `/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/`;
      if (location.pathname?.startsWith(docPath)) {
        dispatch(clearDocument());
      }
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  const userPrefCountry = useSelector(selectUserCountry);
  const userPermissions = useSelector(selectUserPermissions);
  const showMobileNavigationMenu = useSelector(
    selectShouldShowMobileNavigationMenu,
  );
  const builderPermission = userPermissions.LEADER || userPermissions.OPERATOR;

  const isExploreTab =
    location.pathname === '/' ||
    location.pathname.startsWith(
      `/${Constants.ROUTE_PATH_NAMES.CATEGORY_PATH_NAME}`,
    );

  const isPlansTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
  );

  const isSettingsTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
  );

  const isAdminTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`,
  );

  const isAscendTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`,
  );

  const isReportsTab = location.pathname.startsWith(
    `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}`,
  );

  const tabs = [
    {
      label: t('TrainingPlans.tabExplore'),
      icon: <IconReportSearch />,
      to: '/',
      isActive: isExploreTab,
      requiredPermissions: [],
      showTab: true,
    },
    {
      label: t('TrainingPlans.tabPlans'),
      icon: <IconNotes />,
      to: builderPermission
        ? `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`
        : `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`,
      isActive: isPlansTab,
      requiredPermissions: [],
      showTab: xpApiFeatureFlag
        ? userFeatureFlags?.plans?.enabled
        : userPrefCountry?.id !== Constants.SUPPORTED_COUNTRIES.GB.id,
    },
    {
      label: t('Generic.reports'),
      icon: <IconChartBar />,
      to: `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`,
      isActive: isReportsTab,
      requiredPermissions: [
        Constants.USER_PERMISSIONS.LEADER,
        Constants.USER_PERMISSIONS.OPERATOR,
      ],
      showTab: xpApiFeatureFlag
        ? userFeatureFlags?.reporting?.enabled
        : userPrefCountry?.id !== Constants.SUPPORTED_COUNTRIES.GB.id,
    },
    {
      label: t('TrainingPlans.tabAscend'),
      icon: <IconAscendSpaced />,
      to: `/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`,
      isActive: isAscendTab,
      requiredPermissions: [],
      showTab: featureFlagAscendNav,
    },
    {
      label: t('Generic.settings'),
      icon: <IconSettings />,
      to: `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
      isActive: isSettingsTab,
      requiredPermissions: [],
      showTab: true,
    },
    {
      label: t('Generic.admin'),
      icon: <IconTool />,
      to: `/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`,
      isActive: isAdminTab,
      requiredPermissions: [Constants.USER_PERMISSIONS.ADMIN],
      showTab: xpApiFeatureFlag ? userFeatureFlags?.admin?.enabled : true,
    },
  ];

  const filteredTabs = tabs.filter(tab => {
    if (!!tab.showTab) {
      if (tab.requiredPermissions.length === 0) {
        return true;
      }
      return tab.requiredPermissions.some(
        permission => userPermissions[permission],
      );
    }
    return false;
  });

  return (
    <>
      {isTrainingMode ? (
        children
      ) : isDesktop && !isNative ? (
        <NavbarDesktop tabs={filteredTabs}>{children}</NavbarDesktop>
      ) : (
        <NavbarMobile showMenu={showMobileNavigationMenu} tabs={filteredTabs}>
          {children}
        </NavbarMobile>
      )}
    </>
  );
};

const IconAscendSpaced = styled(IconAscend)`
  height: 24px;
  width: 24px;
`;

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Navbar;
