import styled from 'styled-components';
import { Link } from 'cfa-react-components';
import React from 'react';

interface StickyMenuButtonProps {
  className?: string;
  dataTestId?: string;
  error?: boolean;
  icon: React.ReactNode;
  onClick: () => void;
  text: string;
}

const StickyMenuButton: React.FC<StickyMenuButtonProps> = ({
  text,
  icon,
  error,
  onClick,
  className,
  dataTestId,
}) => {
  return (
    <MenuButton
      $error={error}
      className={className}
      data-testid={dataTestId ? dataTestId : 'StickyMenuItem'}
      onClick={onClick}
    >
      {icon}
      {text}
    </MenuButton>
  );
};

const MenuButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  font-weight: 500;
  color: ${({ theme, $error }) =>
    $error ? theme.semanticColors.error : theme.primaryPalette.navyBlue};
`;

export default StickyMenuButton;
