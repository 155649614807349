import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';
import { getNameFromLanguage } from '@/util/language';
import {
  LanguageObject,
  PaginatedPlan,
  SortArrayDate,
  SortArrayString,
} from '@/types';

const applyFilters = (
  plans: PaginatedPlan[],
  categoryFilters: string[],
  searchFilter: string,
) => {
  let filteredPlans = plans;
  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan;
      const hasCategory = categoryFilters.includes(category as string);
      return hasCategory;
    });
  }
  if (searchFilter.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { name } = plan;
      const nameLocalLang = getNameFromLanguage(name as LanguageObject);
      return nameLocalLang.toLowerCase().includes(searchFilter.toLowerCase());
    });
  }
  return filteredPlans;
};

const applySort = (plans: PaginatedPlan[], sort: string) => {
  let sortedPlans = plans;
  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = (plans as any).toSorted(
        (a: SortArrayString, b: SortArrayString) =>
          getNameFromLanguage(a.name)
            .toLowerCase()
            .localeCompare(getNameFromLanguage(b.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = (plans as any).toSorted(
        (a: SortArrayString, b: SortArrayString) =>
          getNameFromLanguage(b.name)
            .toLowerCase()
            .localeCompare(getNameFromLanguage(a.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.NEWEST:
      sortedPlans = (plans as any).toSorted(
        (a: SortArrayDate, b: SortArrayDate) =>
          new Date(b.created).getTime() - new Date(a.created).getTime(),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.OLDEST:
      sortedPlans = (plans as any).toSorted(
        (a: SortArrayDate, b: SortArrayDate) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans;
};

const applyPagination = (
  plans: PaginatedPlan[],
  page: number,
  pageSize: number,
) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

export const slice = createSlice({
  name: 'buildPlansFilter',
  initialState: {
    categoryFilters: [],
    filteredPlans: [],
    page: 1,
    pageSize: 12,
    paginatedPlans: [],
    plans: [],
    searchFilter: '',
    showing: 0,
    sort: Constants.PLANS_SORT_OPTIONS.NEWEST,
    sortedPlans: [],
    total: 0,
  },
  reducers: {
    setBuildPlans: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.plans = action.payload.plans;
      (state.sortedPlans as PaginatedPlan[]) = applySort(
        state.plans,
        state.sort,
      );
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addBuildPlansCategoryFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      (state.categoryFilters as string[]) = [
        ...state.categoryFilters,
        action.payload.filter,
      ];
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setBuildPlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setBuildPlansSort: (state, action) => {
      state.sort = action.payload.sort;
      (state.sortedPlans as PaginatedPlan[]) = applySort(
        state.plans,
        state.sort,
      );
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearBuildPlansCheckboxFilters: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [];
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearBuildPlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    removeBuildPlansFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      const { filter } = action.payload;
      if ((state.categoryFilters as string[]).includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      (state.filteredPlans as PaginatedPlan[]) = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans as PaginatedPlan[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBuildPlans,
  addBuildPlansCategoryFilter,
  setBuildPlansSearchFilter,
  setBuildPlansSort,
  clearBuildPlansCheckboxFilters,
  clearBuildPlansSearchFilter,
  loadMorePlans,
  removeBuildPlansFilter,
} = slice.actions;

export default slice.reducer;
