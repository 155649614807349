import Constants from 'constants/index';
import { LegacyRef, useState } from 'react';
import styled from 'styled-components';
import { TextField, TextFieldType } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';

interface EditSectionNameProps {
  onEnter: () => void;
  originalSectionTitle: string;
  refSectionTitle: LegacyRef<TextFieldType>;
  updateSectionTitle: (title: string) => void;
}

const EditSectionName: React.FC<EditSectionNameProps> = ({
  originalSectionTitle,
  refSectionTitle,
  updateSectionTitle,
  onEnter,
}) => {
  const [sectionTitle, setSectionTitle] = useState(originalSectionTitle);
  const maxLength = Constants.FORMS.MAX_SECTION_LENGTH;
  const { t } = useTranslation();
  const handleOnChange = (e: React.ChangeEvent<TextFieldType>) => {
    setSectionTitle(e.target.value);
    updateSectionTitle(e.target.value);
  };

  return (
    <StyledInputWrapper>
      <TextField
        fullWidth
        maxLength={maxLength}
        onChange={e => handleOnChange(e)}
        onKeyDown={e => e.key === 'Enter' && onEnter()}
        placeholder={t('TrainingPlans.sectionName')}
        ref={refSectionTitle}
        type="text"
        value={sectionTitle}
      />
      {!!sectionTitle?.length && (
        <StyledCharacterCountWrapper>
          <StyledCharacterCount>
            {`${sectionTitle.length}/${maxLength}`}
          </StyledCharacterCount>
        </StyledCharacterCountWrapper>
      )}
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 15px;
`;

const StyledCharacterCountWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: -20px;
  right: 2px;
`;

const StyledCharacterCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-style: italic;
  color: ${props => props.theme.grayScale.gray6};
  font-weight: 400;
`;
export default EditSectionName;
