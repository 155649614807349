import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';

interface LicenseeLocation {
  name: string;
  number: string;
}

interface FilterParams {
  licenseeLocations: LicenseeLocation[];
  searchFilters?: string;
}

const applyFilters: (params: FilterParams) => LicenseeLocation[] = ({
  licenseeLocations,
  searchFilters,
}) => {
  let filteredLicenseeLocations = licenseeLocations;
  if (searchFilters) {
    filteredLicenseeLocations = filteredLicenseeLocations?.filter(
      licenseeLocation => {
        const { name, number } = licenseeLocation;
        return (
          name.toLowerCase().includes(searchFilters.toLowerCase()) ||
          number.includes(searchFilters)
        );
      },
    );
  }
  return filteredLicenseeLocations;
};

const applySort = (licenseeLocations: any, sort: string) => {
  let sortedLicenseeLocations = licenseeLocations;
  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedLicenseeLocations = sortedLicenseeLocations?.toSorted(
        (a: any, b: any) =>
          a.number.toLowerCase().localeCompare(b.number.toLowerCase()),
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedLicenseeLocations;
};

const applyPagination = (
  licenseeLocations: LicenseeLocation[],
  page: number,
  pageSize: number,
) => {
  const total = licenseeLocations.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedLicenseeLocations = licenseeLocations.slice(0, showing);
  return { loadedLicenseeLocations, showing, total };
};

export const slice = createSlice({
  name: 'licenseeLocationsFilter',
  initialState: {
    licenseeLocations: [],
    sort: Constants.PLANS_SORT_OPTIONS.A2Z,
    filteredLicenseeLocations: [],
    searchFilters: '',
    sortedLicenseeLocations: [],
    paginatedLicenseeLocations: [],
    page: 1,
    pageSize: 12,
    showing: 0,
    total: 0,
  },
  reducers: {
    clearLicenseeLocationsSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilters = '';
      (state.filteredLicenseeLocations as LicenseeLocation[]) = applyFilters({
        licenseeLocations: state.sortedLicenseeLocations,
        searchFilters: state.searchFilters,
      });
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
    loadMoreLicenseeLocations: state => {
      state.page += 1;
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
    removeLicenseeLocationsFilter: state => {
      (state.filteredLicenseeLocations as LicenseeLocation[]) = applyFilters({
        licenseeLocations: state.sortedLicenseeLocations,
        searchFilters: state.searchFilters,
      });
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
    setLicenseeLocations: (state, action) => {
      state.licenseeLocations = action.payload ?? [];
      state.sortedLicenseeLocations = applySort(
        state.licenseeLocations,
        state.sort,
      );
      (state.filteredLicenseeLocations as LicenseeLocation[]) = applyFilters({
        licenseeLocations: state.sortedLicenseeLocations,
        searchFilters: state.searchFilters,
      });
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
    setLicenseeLocationsSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedLicenseeLocations = applySort(
        state.licenseeLocations,
        state.sort,
      );
      (state.filteredLicenseeLocations as LicenseeLocation[]) = applyFilters({
        licenseeLocations: state.sortedLicenseeLocations,
        searchFilters: state.searchFilters,
      });
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
    setLicenseeLocationsSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilters = action.payload.searchFilter;
      (state.filteredLicenseeLocations as LicenseeLocation[]) = applyFilters({
        licenseeLocations: state.sortedLicenseeLocations,
        searchFilters: state.searchFilters,
      });
      ({
        loadedLicenseeLocations:
          state.paginatedLicenseeLocations as LicenseeLocation[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredLicenseeLocations,
        state.page,
        state.pageSize,
      ));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearLicenseeLocationsSearchFilter,
  loadMoreLicenseeLocations,
  removeLicenseeLocationsFilter,
  setLicenseeLocations,
  setLicenseeLocationsSort,
  setLicenseeLocationsSearchFilter,
} = slice.actions;

export default slice.reducer;
