import Constants from 'constants/index';
import { pluralize } from 'util/language';
import { uniqueBy } from 'util/uniqueBy';
import {
  isReportsPlansPath,
  isReportsTeamMembersViewPath,
  isTrainingBuildPath,
  isTrainingMyPlansPath,
  isTrainingTeamPath,
  isReportsPlansViewPath,
} from 'util/url';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @ts-ignore this will be converted in a separate ticket (not created yet)
import { AssignedTeamMembersCount } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';
import { useLocation } from 'react-router-dom';
import {
  useGetAssignedChecklistByIdQuery,
  useGetAssignedStatusQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import {
  Button,
  Modal,
  ModalBody,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import ScrollModalHeader from 'components/ScrollModal/ScrollModalHeader';
import ScrollModalFooter from 'components/ScrollModal/ScrollModalFooter';
import Searchbar from 'components/Searchbar/Searchbar';
import LocationsFilter from 'components/LocationsFilter/LocationsFilter';
import {
  ChecklistDTO,
  IdentityUser,
  UserChecklistDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
// @ts-ignore this will be converted in a separate ticket (not created yet)
import TeamMemberCard from './TeamMemberCard';

interface AddTeamMembersPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  locations: string[];
  planDetails: ChecklistDTO;
  isCompliance?: boolean;
  isTrainingMode?: boolean;
  handleNextClick: (onClick: any) => void;
  teamMemberQueryOptions?: {};
}

interface TeamMemberWithAdId extends UserChecklistDTO {
  adId: string;
}

const AddTeamMembersPopUp: React.FC<AddTeamMembersPopUpProps> = ({
  isOpen,
  onClose,
  locations,
  planDetails,
  isCompliance,
  isTrainingMode,
  handleNextClick,
  teamMemberQueryOptions,
}) => {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const modalBodyRef = useRef(null);
  const locationUrl = useLocation();
  const isBuildPlansTab = isTrainingBuildPath(locationUrl);
  const isMyPlansTab = isTrainingMyPlansPath(locationUrl);
  const isReportsPlansTab = isReportsPlansPath(locationUrl);
  const isReportsPlansViewPage = isReportsPlansViewPath(locationUrl);
  const isReportsTeamMembersViewTab = isReportsTeamMembersViewPath(locationUrl);
  const isTeamTab = isTrainingTeamPath(locationUrl);
  const [searchTermDisplayed, setSearchTermDisplayed] = useState('');
  const [teamMembersPerPage, setTeamMembersPerPage] = useState(12);
  const [copyOfTeamMembers, setCopyOfTeamMembers] = useState<IdentityUser[]>();
  const [nonCompletedTeamMembers, setNonCompletedTeamMembers] =
    useState<any[]>();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<
    TeamMemberWithAdId[]
  >([]);
  const [filterBy, setFilterBy] = useState<string[]>();
  const [assignedTeamMembers, setAssignedTeamMembers] = useState<
    TeamMemberWithAdId[] | UserChecklistDTO[] | ChecklistDTO
  >();
  const [currentCount, setCurrentCount] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { data: teamMembers } = useGetTeamMembersQuery(
    {
      locations: (!isCompliance && locations) as string[],
    },
    {
      skip: locations.length <= 0 || !isOpen,
    },
  );

  const {
    data: assignedTeamMembersList,
    isSuccess: assignedStatusSuccess,
    refetch: refetchAssignedTeamMembersList,
  } = useGetAssignedStatusQuery(
    {
      checklist: planDetails?.id as string,
      location: locations,
    },
    {
      ...teamMemberQueryOptions,
      skip:
        isMyPlansTab ||
        isTeamTab ||
        isReportsTeamMembersViewTab ||
        isReportsPlansViewPage ||
        !planDetails?.id ||
        (!isOpen && (isBuildPlansTab as boolean)),
    },
  );

  const { data: assignedChecklist } = useGetAssignedChecklistByIdQuery(
    planDetails?.id as string,
    {
      skip:
        isMyPlansTab ||
        isTeamTab ||
        isReportsPlansTab ||
        isReportsTeamMembersViewTab ||
        !planDetails?.id ||
        (!isOpen && (isBuildPlansTab as boolean)),
    },
  );

  // Create copy for popup UI (checking/unchecking only affects the UI)
  useEffect(() => {
    setCopyOfTeamMembers(teamMembers);
  }, [teamMembers]);

  useEffect(() => {
    if (assignedTeamMembers) {
      setNonCompletedTeamMembers(
        (assignedTeamMembers as [])
          .filter(
            (teamMember: any) =>
              teamMember.status !== Constants.TRAINING_PLANS.COMPLETED,
          )
          .map((member: any) => member.userId),
      );
    }
  }, [assignedTeamMembers]);

  useEffect(() => {
    if (isOpen && assignedTeamMembersList) {
      refetchAssignedTeamMembersList();
    }
  }, [assignedTeamMembersList, isOpen, refetchAssignedTeamMembersList]);

  useEffect(() => {
    if (assignedStatusSuccess) {
      setAssignedTeamMembers(
        assignedTeamMembersList.status &&
          assignedTeamMembersList.status.filter(
            it => it.status !== Constants.TRAINING_PLANS.UNASSIGNED,
          ),
      );
    } else if (isCompliance) {
      setAssignedTeamMembers(planDetails);
    }
  }, [
    assignedStatusSuccess,
    assignedTeamMembersList,
    isCompliance,
    planDetails,
  ]);

  useEffect(() => {
    if (selectedTeamMembers) {
      setCurrentCount(
        isTrainingMode && !!nonCompletedTeamMembers?.[0]
          ? selectedTeamMembers.filter(teamMember =>
              nonCompletedTeamMembers?.includes(teamMember.adId),
            ).length
          : selectedTeamMembers.length,
      );
    }
  }, [isTrainingMode, nonCompletedTeamMembers, selectedTeamMembers]);

  const alreadyAssignedTeamMembers = copyOfTeamMembers
    ?.filter(member => {
      return (assignedTeamMembers as UserChecklistDTO[])?.some(
        teamMember => teamMember.userId === member.adId,
        PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      );
    })
    .map(member => member.adId);

  const onClearSearchClick = () => {
    setSearchTermDisplayed('');
    onSearchInputChange();
    searchInputRef.current && searchInputRef.current.focus();
  };

  const onSearchInputChange = (e?: React.ChangeEvent<TextFieldType>) => {
    if (setIsSelectAll) {
      setIsSelectAll(false);
    }
    const searchTerm = e?.target.value;
    searchTerm?.length
      ? setSearchTermDisplayed(searchTerm)
      : setSearchTermDisplayed('');

    if (!searchTerm) {
      setCopyOfTeamMembers(teamMembers);
    } else {
      setCopyOfTeamMembers(
        teamMembers &&
          teamMembers.filter(member => {
            const lcSearch = searchTerm.toLowerCase();
            return (
              member.name?.toLowerCase().includes(lcSearch) ||
              member.firstName?.toLowerCase().includes(lcSearch) ||
              member.lastName?.toLowerCase().includes(lcSearch) ||
              member.prefName?.toLowerCase().includes(lcSearch)
            );
          }),
      );
    }
  };

  const onLoadMore = () => {
    setTeamMembersPerPage(teamMembersPerPage + 12);
  };

  const onAssignTeamMember = (member: TeamMemberWithAdId) => {
    if (setIsSelectAll) {
      setIsSelectAll(false);
    }
    const isSelected = selectedTeamMembers.find(
      selectedTeamMember => selectedTeamMember.adId === member.adId,
    );
    isSelected
      ? setSelectedTeamMembers(
          selectedTeamMembers.filter(
            selectedTeamMember => selectedTeamMember.adId !== member.adId,
          ),
        )
      : setSelectedTeamMembers([...selectedTeamMembers, member]);
  };
  const ids = copyOfTeamMembers?.map(o => o.adId);
  const assignedUsers = assignedChecklist?.status?.map(user => user.userId);

  const assignedTeamMembersInTrainingMode = uniqueBy(
    (copyOfTeamMembers as IdentityUser[])?.filter(
      teamMember => assignedUsers?.includes(teamMember.adId) ?? [],
    ),
    'adId',
  );

  const uniqueTeamMembers = isTrainingMode
    ? assignedTeamMembersInTrainingMode
    : copyOfTeamMembers?.filter(
        ({ adId }, index) => !ids?.includes(adId, index + 1),
      );

  const filteredTeamMembers = filterBy?.length
    ? uniqueTeamMembers.filter((member: TeamMemberWithAdId) => {
        return member?.locations?.some(memberLocation =>
          filterBy.some(location => location === memberLocation),
        );
      })
    : isTrainingMode
    ? uniqueTeamMembers?.filter((member: TeamMemberWithAdId) =>
        (assignedTeamMembers as TeamMemberWithAdId[])?.some(
          teamMember => teamMember.userId === member.adId,
        ),
      )
    : uniqueTeamMembers;

  const selectableTeamMembers = isTrainingMode
    ? filteredTeamMembers?.filter((member: TeamMemberWithAdId) =>
        alreadyAssignedTeamMembers?.includes(member.adId),
      )
    : filteredTeamMembers?.filter(
        (member: TeamMemberWithAdId) =>
          !alreadyAssignedTeamMembers?.includes(member.adId),
      );

  const onAssignAllTeamMembers = () => {
    // combine and filter the existing selected team members array from the select all array
    const combinedSelectedTeamMembers = [
      ...new Set([...selectedTeamMembers, ...selectableTeamMembers]),
    ];
    setIsSelectAll(prev => !prev);
    setSelectedTeamMembers(!isSelectAll ? combinedSelectedTeamMembers : []);
  };

  const onChipClick = (location: string) => {
    if (filterBy?.includes(location)) {
      setFilterBy(filterBy.filter(it => it !== location));
    } else {
      setFilterBy(filter => [...(filter || []), location]);
    }
  };

  const resetCount = () => {
    setCurrentCount(0);
    setSelectedTeamMembers([]);
  };

  const onNextClick = () => {
    handleNextClick({
      isTrainingMode,
      selectedTeamMembers,
      id:
        !isTrainingMode &&
        selectedTeamMembers.map(members => members.adId).toString(),
    });
    if (!isTrainingMode) {
      resetCount();
    }
  };

  const loadMoreTeamMembers = filteredTeamMembers?.length > teamMembersPerPage;

  const currentMembers =
    teamMembersPerPage > filteredTeamMembers?.length
      ? filteredTeamMembers?.length
      : teamMembersPerPage;

  const totalMembers = filteredTeamMembers?.length;

  return (
    <Modal
      onClose={() => {
        setIsSelectAll(false);
        setSelectedTeamMembers([]);
        onClose();
      }}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ScrollModalHeader scrollRef={modalBodyRef}>
        <AssignedTeamMembersCount inNavbar={true} />
        <AddTeamMemberSearchbar
          elevation={1}
          fullWidth
          onChange={onSearchInputChange}
          onClear={onClearSearchClick}
          placeholder={t('TrainingPlans.teamMembers.searchbarPlaceholder')}
          ref={searchInputRef}
          searchValue={searchTermDisplayed}
        />
      </ScrollModalHeader>
      <ModalBody ref={modalBodyRef}>
        <StyledTeamMembersWrapper>
          {locations?.length > 1 && (
            <LocationsFilter
              locations={locations}
              onClick={location => onChipClick(location)}
              onClose={location => onChipClick(location)}
              selectedLocations={filterBy as string[]}
            />
          )}
          <StyledCountWrapper>
            <StyledCount>
              {t('TrainingPlans.teamMembers.count')}{' '}
              {`${currentMembers} ${t('Generic.of')} ${totalMembers}`}
            </StyledCount>
            <SelectAllButton
              color="secondary"
              disabled={
                isTrainingMode ? false : selectableTeamMembers?.length === 0
              }
              onClick={onAssignAllTeamMembers}
              size="sm"
              variant="text"
            >
              {!isSelectAll ? t('Button.selectAll') : t('Button.clearAll')}
            </SelectAllButton>
          </StyledCountWrapper>

          {!!filteredTeamMembers?.length &&
            [...filteredTeamMembers]
              .sort((member1, member2) =>
                member1.name === member2.name
                  ? 0
                  : member1.name > member2.name
                  ? 1
                  : -1,
              )
              .slice(0, teamMembersPerPage)
              .map(member => (
                <TeamMemberCard
                  id={member.adId}
                  isAssigned={
                    !!selectedTeamMembers.find(
                      selectedTeamMember =>
                        selectedTeamMember.adId === member.adId,
                    )
                  }
                  isTrainingMode={isTrainingMode}
                  key={member.adId}
                  name={`${member.name}`}
                  onAssignTeamMember={() => onAssignTeamMember(member)}
                  originallyAssigned={
                    isTrainingMode
                      ? assignedChecklist?.status
                          ?.filter(
                            teamMember =>
                              teamMember.status ===
                              Constants.TRAINING_PLANS.COMPLETED,
                          )
                          .map(it => it.userId)
                          .includes(member.adId)
                      : alreadyAssignedTeamMembers?.includes(member.adId)
                  }
                />
              ))}
          {loadMoreTeamMembers && (
            <StyledLoadMoreWrapper>
              <Button color="secondary" onClick={onLoadMore}>
                {t('Button.loadMore')}
              </Button>
            </StyledLoadMoreWrapper>
          )}

          <StyledShowingResultsFooter variant="body1">
            {t('Search.showingResultsFooter', {
              showingResults:
                teamMembersPerPage > filteredTeamMembers?.length
                  ? filteredTeamMembers?.length
                  : teamMembersPerPage,
              numberOfResults:
                filteredTeamMembers?.length <= 100
                  ? filteredTeamMembers?.length
                  : '100+',
              resultsText: pluralize(
                filteredTeamMembers?.length,
                t('Generic.teamMember'),
                t('Generic.teamMembers'),
              ),
            })}
          </StyledShowingResultsFooter>
        </StyledTeamMembersWrapper>
      </ModalBody>
      <ScrollModalFooter scrollRef={modalBodyRef}>
        <Button
          color="secondary"
          data-testid="ButtonNext"
          disabled={currentCount === 0}
          onClick={onNextClick}
        >
          {t('Button.addXObjects', {
            count: currentCount,
            object:
              currentCount === 1
                ? t('Generic.teamMember')
                : t('Generic.teamMembers'),
          })}
        </Button>
      </ScrollModalFooter>
    </Modal>
  );
};

const StyledCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
`;
const StyledCount = styled.span`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-weight: 700;
`;
const StyledTeamMembersWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
`;
const StyledLoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 16px;
`;
const StyledShowingResultsFooter = styled(Typography)`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const AddTeamMemberSearchbar = styled(Searchbar)`
  margin-top: 24px;
  margin-bottom: 8px;
  z-index: 5;
`;
const SelectAllButton = styled(Button)`
  text-decoration: underline;
  font-weight: 400;
  padding: 0 !important;
  min-width: 0 !important;
  line-height: 24px;
`;

export default AddTeamMembersPopUp;
