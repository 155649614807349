import { LoadingIndicator } from 'cfa-react-components';
import { FC } from 'react';
import styled from 'styled-components';

const FullscreenLoading: FC = () => {
  return (
    <Content>
      <LoadingIndicator size="lg" variant="page" />
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
`;

export default FullscreenLoading;
