import { useSelector } from 'react-redux';
import { memo } from 'react';
import { selectIsInitialized } from '@/store/app/selectors';
import Routes from '@/Routes';

/**
 * Anything to do with the session cannot be selected here!!
 * It will tear down the entire Routes component and cause problems
 */
const RoutesContainer: React.FC = () => {
  const isInitialized = useSelector(selectIsInitialized);
  if (!isInitialized) {
    return null; // overlay will be shown
  }
  return <Routes />;
};

export default memo(RoutesContainer);
