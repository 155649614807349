import { createSelector } from 'reselect';
import { RootState } from '..';

export const teamMembersFilterState = (state: RootState) =>
  state.teamMembersFilter;

export const selectFilters = createSelector(teamMembersFilterState, state => [
  ...state.statusFilters,
  ...state.locationFilters,
]);

export const selectSort = createSelector(
  teamMembersFilterState,
  state => state.sort,
);

export const selectSortedAndFilteredAndPaginatedTeamMembers = createSelector(
  teamMembersFilterState,
  state => state.paginatedTeamMembers,
);

export const selectPagination = createSelector(
  teamMembersFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectAllTeamMembers = createSelector(
  teamMembersFilterState,
  state => state.teamMembers,
);

export const selectSearchFilter = createSelector(
  teamMembersFilterState,
  state => state.searchFilters,
);
