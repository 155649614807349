import { createSelector } from 'reselect';
import { RootState } from '..';

export const managePlansFilterState = (state: RootState) =>
  state.managePlansFilter;

export const selectSortedAndFilteredAndPaginatedPlans = createSelector(
  managePlansFilterState,
  state => state.paginatedPlans,
);

export const selectSortedAndFilteredPlans = createSelector(
  managePlansFilterState,
  state => state.filteredPlans,
);

export const selectPagination = createSelector(
  managePlansFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectSearchFilter = createSelector(
  managePlansFilterState,
  state => state.searchFilter,
);

export const selectSort = createSelector(
  managePlansFilterState,
  state => state.sort,
);

export const selectFilters = createSelector(managePlansFilterState, state => [
  ...state.categoryFilters,
  ...state.locationFilters,
]);
