import { createSelector } from 'reselect';
import { RootState } from '..';

export const trainingModeReducerState = (state: RootState) =>
  state.trainingMode;

export const selectTrainingModeModalIsOpen = createSelector(
  trainingModeReducerState,
  state => state.isOpen,
);

export const selectIsTrainingMode = createSelector(
  trainingModeReducerState,
  state => state.isTrainingMode,
);

export const selectShowProgressModal = createSelector(
  trainingModeReducerState,
  state => state.showProgressModal,
);

export const selectAssignedChecklistStatus = createSelector(
  trainingModeReducerState,
  state => state.assignedChecklistStatus,
);

export const selectTrainingModeData = createSelector(
  trainingModeReducerState,
  state => state.trainingModeData,
);
