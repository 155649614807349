import styled from 'styled-components';
import {
  TextFieldType,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import Searchbar from 'components/Searchbar/Searchbar';

interface SearchFilterHeaderProps {
  hideTitle?: boolean;
  onChange: (e: React.ChangeEvent<TextFieldType>) => {
    payload: any;
    type:
      | 'buildPlansFilter/setBuildPlansSearchFilter'
      | 'managePlansFilter/setManagePlansSearchFilter'
      | 'teamMembersFilter/setTeamMembersSearchFilter';
  };
  onClear: () => void;
  searchPlaceholder: string;
  searchValue: string;
  title: string;
}

interface SearchFilterHeaderCSSProps {
  $hideTitle: boolean;
  $isSmAndDown: boolean;
}

const SearchFilterHeader: React.FC<SearchFilterHeaderProps> = ({
  hideTitle = false,
  onChange,
  onClear,
  searchPlaceholder,
  searchValue,
  title,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <SearchHeaderContainer
      $hideTitle={hideTitle}
      $isSmAndDown={isSmAndDown}
      data-testid={'SearchFilterHeader'}
    >
      {!hideTitle && <Typography variant="h3">{title}</Typography>}
      <FilterSearchbar
        $hideTitle={hideTitle}
        $isSmAndDown={isSmAndDown}
        fullWidth={isSmAndDown}
        onChange={onChange}
        onClear={onClear}
        placeholder={searchPlaceholder}
        searchValue={searchValue}
      />
    </SearchHeaderContainer>
  );
};

const FilterSearchbar = styled(Searchbar)<SearchFilterHeaderCSSProps>`
  width: 100%;
  margin-bottom: ${({ $isSmAndDown, $hideTitle }) =>
    $isSmAndDown && !$hideTitle ? '32px' : '0'};
  ${({ $isSmAndDown }) =>
    !$isSmAndDown &&
    `max-width: 300px;
    & div {
      max-width: 300px;
    }
    & input {
      max-width: 300px;
    }
  `}
`;

const SearchHeaderContainer = styled.div<SearchFilterHeaderCSSProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'column-reverse' : 'row'};
  justify-content: space-between;
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  margin: 16px 0;
  margin-bottom: ${({ $hideTitle }) => ($hideTitle ? '0' : '16px')};
`;

export default SearchFilterHeader;
