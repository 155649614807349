import { createSelector } from 'reselect';
import { RootState } from '..';

export const myPlansFilterState = (state: RootState) => state.myPlansFilter;

export const selectSortedPaginatedAndFilteredMyPlans = createSelector(
  myPlansFilterState,
  state => state.paginatedPlans,
);

export const selectSort = createSelector(
  myPlansFilterState,
  state => state.sort,
);

export const selectMyPlansSearchFilter = createSelector(
  myPlansFilterState,
  state => state.searchFilter,
);

export const selectFilters = createSelector(myPlansFilterState, state => [
  ...state.categoryFilters,
]);

export const selectMyPlansPagination = createSelector(
  myPlansFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectShowMyCompletedPlansOnlySwitchValue = createSelector(
  myPlansFilterState,
  state => !state.showOnlyInProgressPlans,
);
