import { useTranslation } from 'react-i18next';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from '@/components/StickyFilterCard/CheckboxFilterSection';
import {
  addBuildPlansCategoryFilter,
  clearBuildPlansSearchFilter,
  removeBuildPlansFilter,
  setBuildPlansSearchFilter,
  setBuildPlansSort,
} from '@/store/buildPlansFilter/slice';
import {
  selectFilters,
  selectSearchFilter,
  selectSort,
} from '@/store/buildPlansFilter/selector';
import { useSortOptions } from '@/hooks/useSortOptions';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';

interface FilterAndSearchProps {
  isFetching: boolean;
  isFetchingOperators: boolean;
  numberOfPlans: number;
}

const FilterAndSearch: React.FC<FilterAndSearchProps> = ({
  isFetching,
  isFetchingOperators,
  numberOfPlans,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const searchFilter = useSelector(selectSearchFilter);
  const sort = useSelector(selectSort);
  const sortOptions = useSortOptions();
  const currentFilters: string[] = useSelector(selectFilters);
  const categoryLabels = useCategoryLabels(currentFilters, true);
  return (
    <>
      <SearchFilterHeader
        onChange={e =>
          dispatch(setBuildPlansSearchFilter({ searchFilter: e.target.value }))
        }
        onClear={() => dispatch(clearBuildPlansSearchFilter())}
        searchPlaceholder={t('TrainingPlans.filtering.searchBuildPlans')}
        searchValue={searchFilter}
        title={t('TrainingPlans.tabBuild')}
      />
      {!!isSmAndDown && !isFetching && !isFetchingOperators && (
        <FilterAndSortButton
          onSortChange={option => {
            dispatch(setBuildPlansSort({ sort: option.value }));
          }}
          sortOptions={sortOptions}
          sortValue={sortOptions?.find(option => option.value === sort)}
          text={`${t('TrainingPlans.filtering.show')} ${numberOfPlans ?? 0} ${t(
            'TrainingPlans.filtering.results',
          )}`}
        >
          <CheckboxFilterSection
            labels={categoryLabels}
            onChange={value => {
              if (!!currentFilters.includes(value)) {
                dispatch(removeBuildPlansFilter({ filter: value }));
              } else {
                dispatch(addBuildPlansCategoryFilter({ filter: value }));
              }
            }}
            title={t('Browse.categories')}
          />
        </FilterAndSortButton>
      )}
    </>
  );
};

export default FilterAndSearch;
