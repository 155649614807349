import {
  ComponentProps,
  ComponentType,
  lazy,
  LazyExoticComponent,
} from 'react';

const lazyComponent = <T extends ComponentType<any>>(
  lazyImportFunction: () => Promise<{ default: T }>,
  props: ComponentProps<LazyExoticComponent<T>> = {} as ComponentProps<
    LazyExoticComponent<T>
  >,
) => {
  const Component = lazy<T>(lazyImportFunction);
  return <Component {...props} />;
};

export default lazyComponent;
