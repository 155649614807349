import { Container } from 'cfa-react-components';
import styled from 'styled-components';

interface DocumentContainerProps {
  $isPrinting: boolean;
  $isTridion: boolean;
}

export const DocumentContainer = styled(Container)<DocumentContainerProps>`
  width: ${({ $isPrinting }) => ($isPrinting ? 'calc(100% + 40px)' : '100%')};
  max-width: ${({ $isPrinting }) => ($isPrinting ? 'unset' : '840px')};
  padding: 0;
  background-color: ${({ $isPrinting }) => ($isPrinting ? 'white' : 'initial')};
  /* We need this so that the content is centered when printing a Tridion document */
  margin-left: ${({ $isPrinting, $isTridion }) =>
    $isPrinting && $isTridion && '-40px'};
`;
