import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearBuildPlansCheckboxFilters,
  removeBuildPlansFilter,
  setBuildPlansSort,
} from '@/store/buildPlansFilter/slice';
import {
  selectFilters,
  selectPagination,
  selectSort,
} from '@/store/buildPlansFilter/selector';
import { useSortOptions } from '@/hooks/useSortOptions';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from '@/components/ClearFiltersHeader/ClearFiltersHeader';

const SortOptions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { total } = useSelector(selectPagination);
  const sort = useSelector(selectSort);
  const sortOptions = useSortOptions();
  const currentFilters: string[] = useSelector(selectFilters);
  const categoryLabels = useCategoryLabels(currentFilters, true);
  return (
    <>
      <SortFilterHeader
        label={t('TrainingPlans.filtering.sortBy')}
        onChange={option => {
          dispatch(setBuildPlansSort({ sort: option && option.value }));
        }}
        onClear={() => {
          dispatch(clearBuildPlansCheckboxFilters());
        }}
        options={sortOptions}
        showClear={false}
        showCompletedPlansOption={false}
        showMyCompletedPlansOnlySwitchValue={false}
        text={`${total ?? 0} ${t('TrainingPlans.filtering.plans')}`}
        value={sortOptions?.find(option => option.value === sort)}
      />
      <ClearFiltersHeader
        aliases={categoryLabels}
        clearAllFilters={() => {
          dispatch(clearBuildPlansCheckboxFilters());
        }}
        clearFilter={value => {
          dispatch(removeBuildPlansFilter({ filter: value }));
        }}
        filters={currentFilters}
      />
    </>
  );
};

export default SortOptions;
